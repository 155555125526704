import { Component } from "react";
import { Context } from "common/types/context";
import { RunQuery, QueryForEntity } from "common/query/types";
import { sameResultSet } from "common/query/common";
import { Output } from "common/data/types";
import { isQueryValid } from "common/query-builder/validation";
import { ActionButton } from "common/ui/buttons";
import { merge2 } from "common/merge";
import { Preview } from "./preview";

interface PropTypes {
  context: Context;
  runQuery: RunQuery;
  query: QueryForEntity;
  output: Output;
  withLinks: boolean;
}

interface StateType {
  loadPreview: boolean;
}

export class OutputPreview extends Component<PropTypes, StateType> {
  state: StateType = { loadPreview: false };

  componentDidUpdate(prevProps: PropTypes) {
    if (!sameResultSet(prevProps.query, this.props.query))
      this.setState({ loadPreview: false });
  }

  showPreview = () => this.setState({ loadPreview: true });

  render() {
    const { context, runQuery, query, output, withLinks } = this.props;

    if (!isQueryValid(context.entities, query)) {
      return <span className="x-report-query-error">{_("Invalid query")}</span>;
    }

    const queryWithPaging = merge2("query", "pageSize", 20, query);

    return this.state.loadPreview ? (
      <Preview
        context={context}
        runQuery={runQuery}
        query={queryWithPaging}
        output={output}
        withLinks={withLinks}
      />
    ) : (
      <ActionButton onClick={this.showPreview}>
        {_("Show preview")}
      </ActionButton>
    );
  }
}
