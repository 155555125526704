import { mediaApi } from "common/api/media";
import { returnAndNotify } from "common/api/with-notifications";
import { setColumnIsValid } from "common/form/functions/validation";
import { FormValidationProps } from "common/form/types";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { ValueComponent } from "common/with-value-for";
import { ImageWithUpload } from ".";

interface PropTypes extends FormValidationProps {
  context: Context;
  entityName: string;
  columnName?: string;
  recordId: string;
  isDefault: boolean;
  allowClear: boolean;
  isLarge?: boolean;
  alt?: string;
}

export class ImageWithUploadController extends ValueComponent<
  string,
  PropTypes
> {
  static readonly displayName = "ImageWithUploadController";

  uploadAndGetUrlRequest: CancellablePromise<unknown>;
  uploadAndSetAsDefaultRequest: CancellablePromise<unknown>;
  setAsDefaultRequest: CancellablePromise<unknown>;

  componentWillUnmount() {
    this.uploadAndGetUrlRequest?.cancel();
    this.uploadAndSetAsDefaultRequest?.cancel();
    this.setAsDefaultRequest?.cancel();
  }

  uploadImage = (file: FileType) => {
    const {
      context,
      entityName,
      columnName,
      recordId,
      formValidation,
      onFormValidationChange,
      onChange,
    } = this.props;
    const { apiCall, site } = context;

    if (columnName) {
      onFormValidationChange?.(
        setColumnIsValid(formValidation, columnName, false),
      );
    }

    // Marks the form as dirty for the time when the file is being uploaded
    onChange(undefined);

    this.uploadAndGetUrlRequest = mediaApi(apiCall)
      .uploadAndGetUrl(site.name, entityName, recordId, file)
      .then((filePath) => {
        onChange(filePath);
        if (columnName) {
          onFormValidationChange?.(
            setColumnIsValid(formValidation, columnName, true),
          );
        }
      });

    return this.uploadAndGetUrlRequest;
  };

  uploadImageAndSetAsDefault = (file: FileType) => {
    const {
      context,
      entityName,
      columnName,
      recordId,
      formValidation,
      onFormValidationChange,
      onChange,
    } = this.props;
    const { apiCall, site } = context;

    if (columnName) {
      onFormValidationChange(
        setColumnIsValid(formValidation, columnName, false),
      );
    }

    // Marks the form as dirty for the time when the file is being uploaded
    onChange(undefined);

    this.uploadAndSetAsDefaultRequest = mediaApi(apiCall)
      .uploadAndSetAsDefault(site.name, entityName, recordId, file)
      .then((filePath) => {
        onChange(filePath);
        if (columnName) {
          onFormValidationChange(
            setColumnIsValid(formValidation, columnName, true),
          );
        }
      });

    return this.uploadAndSetAsDefaultRequest;
  };

  unSetDefault = () => {
    const { context, entityName, recordId, onChange } = this.props;

    this.setAsDefaultRequest = mediaApi(context.apiCall)
      .setAsDefault(entityName, recordId, null)
      .then(() => onChange(undefined))
      .catch(() => returnAndNotify(_("We could not unset the default image")));

    return this.setAsDefaultRequest;
  };

  render() {
    const { context, allowClear, isLarge, alt, isDefault, value } = this.props;

    return (
      <ImageWithUpload
        context={context}
        allowClear={allowClear}
        onUpload={
          isDefault ? this.uploadImageAndSetAsDefault : this.uploadImage
        }
        onRemove={
          isDefault
            ? this.unSetDefault
            : this.onChangeSetDefaultValue(undefined)
        }
        isLarge={isLarge}
        alt={alt}
        value={value}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
