import $ from "jquery";
import { createRef, ChangeEvent, RefObject } from "react";
import { asyncScriptLoader } from "common/vendor-wrappers/async-script-loader";
import {
  COORDINATES_ZOOM,
  DEFAULT,
  NON_COORDINATES_ZOOM,
  SCRIPT_ID,
  SCRIPT_URL,
} from "common/vendor-wrappers/geolocation/consts";
import {
  Coord,
  GeoCoord,
  GeolocationValue,
  PropTypes,
} from "common/vendor-wrappers/geolocation/types";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { getGoogleMaps } from "./google";

const defaultValue: GeolocationValue = {
  lat: undefined,
  lng: undefined,
  zoom: undefined,
  address: undefined,
};

interface StateType {
  googleMaps: any;
  map?: any;
  marker?: {
    position: Coord;
    map: any;
    setPosition: (x: Coord) => any;
    setMap: (map: any) => any;
  };
  isLoading: boolean;
}

type Props = PropTypes & ValueProps<GeolocationValue>;

export class GeolocationWrapper extends ValueComponent<
  GeolocationValue,
  PropTypes,
  StateType
> {
  static readonly displayName = "GeolocationWrapper";

  state: StateType = {
    googleMaps: undefined,
    map: undefined,
    isLoading: false,
  };
  searchRef: RefObject<HTMLInputElement>;
  mapRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.searchRef = createRef();
    this.mapRef = createRef();
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    asyncScriptLoader(SCRIPT_URL, SCRIPT_ID)
      .then(() => {
        const googleMaps = getGoogleMaps();
        this.setState({ googleMaps, isLoading: false }, () => {
          if (googleMaps) this.putMarker(this.props.value, this.createMap());
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.googleMaps) this.updateMap(prevProps);
  }

  componentWillUnmount() {
    const { googleMaps } = this.state;

    if (!googleMaps) return;

    const map = this.mapRef.current;
    googleMaps.event.clearInstanceListeners(window);
    googleMaps.event.clearInstanceListeners(document);
    googleMaps.event.clearInstanceListeners(map);
    $(map).remove();
  }

  updateMap = (prevProps: Props) => {
    const valueChanged = prevProps.value !== this.props.value;
    const readonlyChanged = prevProps.readonly !== this.props.readonly;

    if ((readonlyChanged && this.props.readonly === true) || valueChanged) {
      const { lat, lng } = this.getCoordinates(
        valueChanged ? this.props.value : prevProps.value,
      );
      this.centerMap({ lat, lng });
    }

    if (valueChanged && !!this.props.value) {
      this.putMarker(this.props.value, this.state.map);
    }

    if (valueChanged && !this.props.value) {
      this.setDefaultMapState();
    }
  };

  setAddress = ({ lat, lng }: Coord) => {
    const { googleMaps } = this.state;
    const geocoder = new googleMaps.Geocoder();

    geocoder.geocode({ latLng: { lat, lng } }, (results: any, status: any) => {
      if (status !== googleMaps.GeocoderStatus.OK) {
        // eslint-disable-next-line
        console.warn("No results found");
        return;
      }
      this.mergeValue({ lat, lng, address: results[0].formatted_address });
    });
  };

  getCoordinates = (value: Coord) => {
    const { lat, lng } = value || defaultValue;

    return {
      lat: lat || DEFAULT,
      lng: lng || DEFAULT,
      zoom: (lat && lng && COORDINATES_ZOOM) || NON_COORDINATES_ZOOM,
    };
  };

  putMarker = (value: GeolocationValue, map: any) => {
    const { googleMaps } = this.state;
    const { lat, lng } = this.getCoordinates(value);
    let { marker } = this.state;

    if (marker) {
      marker.setMap(map);
      marker.setPosition({ lat, lng });
    } else {
      marker =
        lat && lng && new googleMaps.Marker({ position: { lat, lng }, map });
    }
    this.setState({ map, marker });
  };

  centerMap = (value: Coord, zoom: number = COORDINATES_ZOOM) => {
    const { map } = this.state;
    const { lat, lng } = this.getCoordinates(value);

    map.setZoom(zoom);
    map.setCenter({ lat, lng });
  };

  useLocation = () => {
    const { geolocation } = navigator;

    if (!geolocation) {
      // eslint-disable-next-line
      console.warn("Geolocation is not supported by this browser");
      return;
    }
    geolocation.getCurrentPosition(({ coords }: { coords: GeoCoord }) => {
      const latLng = { lat: coords.latitude, lng: coords.longitude };
      this.setAddress(latLng);
      this.centerMap(latLng);
    });
  };

  setDefaultMapState = () => {
    const { marker } = this.state;

    if (marker) marker.setMap(null);

    this.centerMap({ lat: DEFAULT, lng: DEFAULT }, NON_COORDINATES_ZOOM);
    this.setState({ marker });
  };

  createMap = () => {
    const { googleMaps } = this.state;
    const { lat, lng, zoom } = this.getCoordinates(this.props.value);
    const inputSearch = this.searchRef.current;
    const mapElement = this.mapRef.current;

    const map = new googleMaps.Map(mapElement, {
      center: { lat, lng },
      zoom,
    });
    map.controls[googleMaps.ControlPosition.TOP_LEFT].push(inputSearch);

    const searchBox = new googleMaps.places.SearchBox(inputSearch);
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) return;

      const { location } = places[0].geometry;
      const lat = location.lat();
      const lng = location.lng();

      this.centerMap({ lat, lng });
      this.mergeValue({ lat, lng, address: places[0].formatted_address });
    });

    map.addListener("click", (e: any) => {
      if (this.props.readonly) return;
      const { latLng } = e;
      this.setAddress({ lat: latLng.lat(), lng: latLng.lng() });
    });

    const onResize = () => {
      const { map } = this.state;
      const center = map.getCenter();
      googleMaps.event.trigger(map, "resize");
      map.setCenter(center);
    };

    googleMaps.event.addDomListener(window, "resize", onResize);

    return map;
  };

  onAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.props.readonly) return;
    this.setValue({ ...defaultValue, address: e.target.value });
  };

  render() {
    const { readonly, value } = this.props;
    const { googleMaps, isLoading } = this.state;

    if (isLoading) return <LoadingIcon />;

    const address = value?.address || "";
    const addressLabel =
      address || _("Please select an address using the map below");

    return googleMaps ? (
      <div className={readonly ? "x-readonly" : ""}>
        <label className="x-field-label qa-address-label">{addressLabel}</label>
        <div
          className="x-use-location"
          title={_("Use my location")}
          onClick={this.useLocation}
        >
          <i className="fa fa-crosshairs" />
        </div>
        <input ref={this.searchRef} className="x-search-location" type="text" />
        <div ref={this.mapRef} className="x-map" />
      </div>
    ) : (
      <input
        readOnly={readonly}
        className="qa-address-input"
        value={address}
        onChange={this.onAddressInputChange}
      />
    );
  }
}
