import $ from "jquery";
import * as R from "ramda";
import {
  AbsoluteApiCallFull,
  ApiCallFull,
  ApiResponse,
  ExternalOptions,
  ExternalPayloadTypes,
  Method,
  Options,
} from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { FilesPayload, FileType } from "common/types/media";
import { CancellablePromise, Cancellation } from "common/types/promises";
import {
  getWindowLocation,
  setLocationHref,
} from "common/utils/window-location";
import { MAINTENANCE_PATH } from "warning-page/pages/helpers";

const LOGIN_PATH = "/login";

const getUploadSettings = (
  settings: JQueryAjaxSettings,
  options: Options,
  payload: File | Array<{ name: string; file: File }>,
): JQueryAjaxSettings => {
  const form = new FormData();

  if (Array.isArray(payload)) {
    payload.forEach(({ name, file }) => form.append(name, file));
  } else {
    form.append("image", payload);
  }

  return {
    ...settings,
    data: form,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
    headers: R.mergeRight(
      {
        authorization: options.auth,
        "cache-control": "no-cache",
      },
      options.headers,
    ),
    error: (xhr) => {
      if (xhr.responseJSON || !xhr.responseText) return;

      try {
        // why these errors come as string in the first place?
        xhr.responseJSON = JSON.parse(xhr.responseText);
      } catch (e) {
        /* do nothing */
      }
    },
  };
};

export const getDataCallSettings = (
  settings: JQueryAjaxSettings,
  options: Options,
  payload: unknown,
): JQueryAjaxSettings => ({
  ...settings,
  contentType: "application/json",
  beforeSend: (xhr) => {
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    if (options.auth) xhr.setRequestHeader("Authorization", options.auth);
    if (options.headers) {
      R.toPairs<string>(options.headers).map(([key, value]) => {
        const headerValue =
          key.toLowerCase() === "password" ? encodeURIComponent(value) : value;
        return xhr.setRequestHeader(key, headerValue);
      });
    }
  },
  data: payload ? JSON.stringify(payload) : undefined,
  xhrFields: { withCredentials: true },
});

const downloadCall = <T>(
  method: string,
  url: string,
  payload: any,
  options: Options,
) => {
  const ajaxCall = new XMLHttpRequest();

  const promise = new Promise<ApiResponse<T>>((resolve, reject) => {
    ajaxCall.onreadystatechange = function () {
      if (this.readyState !== 4) return;

      if (this.status === 200) {
        resolve({ data: this.response, status: this.status });
      } else {
        reject({ data: this.response, status: this.status });
      }
    };
    ajaxCall.open(method, url, true);
    ajaxCall.responseType = "blob";
    ajaxCall.withCredentials = true;
    ajaxCall.setRequestHeader("Accept", options.accept);
    ajaxCall.setRequestHeader(
      "Content-Type",
      "application/json; charset=UTF-8",
    );
    if (options.auth) ajaxCall.setRequestHeader("Authorization", options.auth);
    if (options.headers) {
      R.toPairs<string>(options.headers).map(([key, value]) =>
        ajaxCall.setRequestHeader(key, value),
      );
    }
    ajaxCall.send(payload ? JSON.stringify(payload) : undefined);
  }).catch((e) => {
    if (e.name === "AbortError") throw new Cancellation();
    throw e;
  });

  return new CancellablePromise<ApiResponse<T>>(promise, () =>
    ajaxCall.abort(),
  );
};

export const jQueryAjaxCall = <T>(settings: JQueryAjaxSettings) => {
  const ajaxCall = $.ajax(settings);

  const promise = new Promise<ApiResponse<T>>((resolve, reject) => {
    ajaxCall
      .done((data, _, xhr) => {
        resolve({ data, status: xhr.status } as ApiResponse<T>);
      })
      .catch((e) => {
        if (e.statusText === "abort") throw new Cancellation();

        // doing a catch here because if we use settings.error,
        // we overwrite getUploadSettings error. RTS
        return reject({
          status: e.status,
          data: e.responseJSON || e.responseText || { error: e.statusText },
        } as ApiErrorResponse);
      });
  });

  return new CancellablePromise<ApiResponse<T>>(promise, () =>
    ajaxCall.abort(),
  );
};

const dataCall = <T>(
  method: string,
  url: string,
  payload: File | Array<{ name: string; file: File }>,
  options: Options,
) => {
  const settings = options.upload
    ? getUploadSettings({ method, url }, options, payload)
    : getDataCallSettings({ method, url }, options, payload);

  return jQueryAjaxCall<T>(settings);
};

const onLoginPage = () =>
  getWindowLocation().pathname.indexOf(LOGIN_PATH) !== -1;
const canRedirect = (options: Options) => !options || !options.dontRedirect;
const requireAuthorization = (err: ApiErrorResponse) => err?.status === 401;
const inMaintenanceMode = (err: ApiErrorResponse) =>
  err?.status === 503 && err?.data?.tenantStatus === "maintenance";

export const apiCallFullWithLoginRedirect: ApiCallFull = <T>(
  method: Method,
  path: string,
  // ApiCall definition has FileType which is our internal type for files.
  // However form here accepts File only. We get away with it because of the unknown.
  payload?: unknown | FileType | FilesPayload,
  options: Options = {},
) =>
  (options.download ? downloadCall : dataCall)<T>(
    method,
    "/" + path.replace(/^\//, ""),
    payload,
    options,
  ).catch((err: ApiErrorResponse) => {
    if (canRedirect(options) && !onLoginPage()) {
      if (requireAuthorization(err)) {
        setLocationHref(LOGIN_PATH);
      } else if (inMaintenanceMode(err)) {
        setLocationHref(MAINTENANCE_PATH);
      }
    }
    return CancellablePromise.reject(err);
  });

export const absoluteApiCallFull: AbsoluteApiCallFull = <T>(
  method: Method,
  url: string,
  options: ExternalOptions,
  payload?: ExternalPayloadTypes,
) => {
  const settings: JQueryAjaxSettings = {
    url,
    crossDomain: options.crossDomain,
    xhrFields: { withCredentials: options.withCredentials },
    method,
    contentType: options.contentType,
    data: JSON.stringify({ token: payload.token }),
    headers: options.headers,
  };

  return jQueryAjaxCall<T>(settings);
};
