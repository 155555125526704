import { Crumb } from "x/layout/ribbon/breadcrumb";
import { RecordRibbon } from "./record-ribbon";
import { Buttons, PropTypes as ButtonPropTypes } from "./buttons";

interface PropTypes extends ButtonPropTypes {
  crumbs?: Crumb[];
}
export const EditRibbon = (props: PropTypes) => (
  <RecordRibbon
    reload={props.reload}
    site={props.context.site.name}
    entity={props.entity}
    record={props.record}
    crumbs={props.crumbs}
  >
    <Buttons {...props} />
  </RecordRibbon>
);

EditRibbon.displayName = "EditRibbon";
