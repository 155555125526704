import { defaultFor } from "common";
import { behaveAs } from "common/entities";
import { isRelatedSiteDataColumn } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { Filter } from "common/query/types";
import { Context } from "common/types/context";
import { getValueFromUniqueValue } from "common/widgets/unique/functions";
import { getSiteLabelByName } from "x/account-settings/sites/functions";

export const defaultColumn = defaultFor<EntityColumn>();

export const isSiteRelatedColumn = (column: EntityColumn) =>
  column.name === "site" || isRelatedSiteDataColumn(column);

export const getSiteValue = (
  context: Context,
  column: EntityColumn,
  value: any,
) =>
  isSiteRelatedColumn(column)
    ? getSiteLabelByName(context.sites, value)
    : getValueFromUniqueValue(value);

export const getSelfOutFilter = (
  entity: Entity,
  column: EntityColumn,
  recordId: string,
): Filter => {
  if (!entity || !column) return undefined;

  const isTreeEntityWithSelfFkColumn =
    behaveAs("Tree", entity) &&
    column.name &&
    entity.arguments.fkColumn === column.name &&
    column.dataType === "fk" &&
    column.relatedEntity === entity.name;

  return recordId && isTreeEntityWithSelfFkColumn
    ? {
        name: "id",
        op: "neq",
        value: recordId,
      }
    : undefined;
};
