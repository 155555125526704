import { Entity } from "common/entities/types";
import { collapseReferenceFks } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { RecordUpdatedEvent } from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "../row";
import { PropertiesPayload } from "./payload/properties";
import { ReasonDetails } from "./reason";

interface PropTypes {
  context: Context;
  entity: Entity;
  event: RecordUpdatedEvent;
  hideLinks?: boolean;
}

export const RecordUpdated = ({
  event,
  entity,
  context,
  hideLinks,
}: PropTypes) => (
  <>
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={true}
      title={_("Updated record fields")}
      properties={event?.details?.record?.properties}
      hideLinks={hideLinks}
    />
    <hr />
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={true}
      title={_("Previous record fields")}
      properties={collapseReferenceFks(
        event?.details?.oldProperties,
        context.entities,
      )}
      hideLinks={hideLinks}
    />
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
    {event?.details?.updatedSyncDate ? (
      <>
        <hr />
        <Row label={_("Updated Sync Date")}>
          <DateTimeLabel
            uiFormat={context.uiFormat}
            value={event.details.updatedSyncDate}
          />
        </Row>
      </>
    ) : undefined}
  </>
);

RecordUpdated.displayName = "RecordUpdated";
