import { Component } from "react";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { Entity } from "common/entities/types";
import { FormValidationProps } from "common/form/types";
import { HorizontalField } from "common/form/ui";
import { Widget } from "common/form/widget";
import { getRecordSites } from "common/record/utils";
import { Context } from "common/types/context";
import { ValueProps } from "common/with-value-for";
import { ScheduledEvent } from "common/types/scheduled-event";
import { AlertInfo } from "common/widgets/alert";

interface PropTypes extends ValueProps<ScheduledEvent>, FormValidationProps {
  context: Context;
  entity: Entity;
}

export class CustomFields extends Component<PropTypes> {
  onFieldChange = (columnName: string) => (fieldValue: unknown) => {
    const { value, onChange } = this.props;
    onChange({ ...value, [columnName]: fieldValue });
  };

  render() {
    const { value, entity, context, formValidation, onFormValidationChange } =
      this.props;
    const customColumns = entity.columns.filter((column) => !column.isSystem);
    const recordSites = getRecordSites(context, entity, value);

    return (
      <div className="x-padding-top-20">
        <legend className="x-legend">{_("Custom Fields")}</legend>
        {customColumns?.length ? (
          customColumns.map((column) => (
            <HorizontalField
              key={column.name}
              className={`qa-custom-${column.name}`}
              label={column.localizedName}
            >
              <Widget
                context={context}
                disabled={false}
                withLinks={true}
                buffer={false}
                readOnly={
                  column.readOnly ||
                  column.userReadOnly ||
                  isRestrictedForRole(column.roleIds, context.role)
                }
                required={column.required}
                entityName={entity.name}
                recordId={value.$id ?? value.id}
                column={column}
                formValidation={formValidation}
                onFormValidationChange={onFormValidationChange}
                recordSites={recordSites}
                value={value[column.name]}
                onChange={this.onFieldChange(column.name)}
              />
            </HorizontalField>
          ))
        ) : (
          <AlertInfo
            message={_("No custom fields added for {ENTITY_NAME}").replace(
              "{ENTITY_NAME}",
              entity?.localizedName ??
                entity?.name ??
                _("Scheduled Work Orders"),
            )}
          />
        )}
      </div>
    );
  }
}
