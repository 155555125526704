import { Component } from "react";
import { TwentyFourHour } from "common/date-time/types";
import {
  to12HourFormat,
  to24HourFormat,
} from "common/widgets/time-picker/functions";
import { ValueProps } from "common/with-value-for";
import { TimeSlider } from "./time-slider";

interface PropTypes extends ValueProps<TwentyFourHour> {
  twelveHourFormat: boolean;
}

export class HourSlider extends Component<PropTypes> {
  static readonly displayName = "HourSlider";

  onChangeHourSlider = (hour: number) => {
    const { value, onChange, twelveHourFormat } = this.props;
    onChange(twelveHourFormat ? to24HourFormat(hour, value) : hour);
  };

  render() {
    const { value, twelveHourFormat } = this.props;

    return (
      <TimeSlider
        maxValue={twelveHourFormat ? 11 : 23}
        value={twelveHourFormat ? to12HourFormat(value) : value}
        onChange={this.onChangeHourSlider}
      />
    );
  }
}
