import * as R from "ramda";
import { searchApi } from "common/api/search";
import { getStartOfDayForDate } from "common/date-time/calculators";
import { Entity } from "common/entities/types";
import {
  FilterRule,
  isSelectField,
  OrderField,
  QueryForEntity,
  SelectField,
} from "common/query/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey as Fk } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import {
  RUNNING_METER_TYPE_ID,
  TOTAL_METER_TYPE_ID,
} from "common/types/system-strings";
import { getFkId } from "common/functions/foreign-key";

export const getMetersQuery = (entity: Entity, assetMeters: Record[]) => ({
  entity: entity.name,
  query: {
    select: [{ name: "id" }, { name: "meterTypeId" }],
    filter: {
      and: [
        {
          name: "id",
          op: "in",
          value: assetMeters.map((r) => r?.properties?.meterId?.id).join(","),
        },
      ],
    },
  },
});

export const getAssetMeterQuery = (
  entity: Entity,
  assetId: string,
): QueryForEntity => ({
  entity: entity.name,
  query: {
    select: [
      { name: "id" },
      { name: "meterId" },
      { name: "description" },
      { name: "maxReadingPerDay" },
      { name: "currentValue" },
    ],
    joins: [{ column: "assetId" }],
    filter: {
      and: [
        {
          name: "id",
          op: "eq",
          value: assetId,
          path: "/assetId",
        },
        { name: "isDeleted", op: "isfalse" },
      ],
    },
  },
});

export const getQueryFor = (
  entity: Entity,
  recordId: string,
): QueryForEntity => ({
  entity: entity.name,
  query: {
    select: entity.columns.map((c) => ({ name: c.name })),
    joins: [{ column: "assetMeterId", joins: [{ column: "assetId" }] }],
    filter: {
      and: [
        {
          name: "id",
          value: recordId,
          op: "eq",
          path: "/assetMeterId/assetId",
        },
        { name: "isDeleted", op: "isfalse" },
      ],
    },
    order: [{ name: "number", desc: true }],
    pageSize: 8,
  },
});

export const getTotalReadings = (
  entity: Entity,
  assetId: string,
  assetMeterId: string,
  assetMeterReadingId: string,
  date: string,
  meterTypeId: number,
): QueryForEntity => {
  const idFilter: FilterRule = {
    name: "id",
    op: "neq",
    value: assetMeterReadingId,
  };

  const select: SelectField[] =
    meterTypeId === TOTAL_METER_TYPE_ID
      ? [{ name: "value", fn: "SUM", alias: "totalReadings" }]
      : [{ name: "value", alias: "totalReadings" }];

  const order: OrderField[] =
    meterTypeId === TOTAL_METER_TYPE_ID ? [] : [{ name: "number", desc: true }];

  const utcDate = getStartOfDayForDate(date);

  const and: FilterRule[] = [
    { name: "date", op: "dayeq", value: utcDate },
    { name: "assetId", op: "eq", value: assetId, path: "/assetMeterId" },
    { name: "assetMeterId", op: "eq", value: assetMeterId },
    { name: "isDeleted", op: "eq", value: false },
  ];

  return {
    entity: entity.name,
    query: {
      select,
      joins: [{ column: "assetMeterId" }],
      filter: {
        and: assetMeterReadingId ? R.append(idFilter, and) : and,
      },
      order,
    },
  };
};

export const mapRecordToFk = (entity: Entity, record: Record): Fk => {
  const { query } = entity;

  const columnNames = query.select.reduce(
    (acc, s) =>
      isSelectField(s)
        ? R.mergeRight(acc, {
            [s.alias || s.name]: record.properties[s.name],
          })
        : acc,
    {} as Fk,
  );

  return { ...columnNames, entity: entity.name, id: record.properties.id };
};

const getAssetMeter = (id: string, assetMeters: Record[] = []) =>
  R.find((am) => am.properties.id === id, assetMeters);

export const getPreviousReading = (
  assetMeterReading: Properties,
  assetMeters: Record[],
): number => {
  const assetMeterId = assetMeterReading?.assetMeterId;
  if (!assetMeterId) return 0;

  const am = getAssetMeter(getFkId(assetMeterId), assetMeters);
  return am?.properties?.currentValue || 0;
};

export const getMaxReadingPerDay = (
  assetMeterId: Fk,
  assetMeters: Record[] = [],
): number => {
  if (!assetMeterId) return undefined;

  const id = getFkId(assetMeterId);
  const assetMeter = assetMeters.find((meter) => meter.properties.id === id);

  return assetMeter?.properties?.maxReadingPerDay;
};

export const getMeterType = (
  assetMeterReading: Properties,
  assetMeters: Record[] = [],
  meters: Properties[] = [],
): SystemIntFk => {
  const assetMeterId = assetMeterReading?.assetMeterId;
  if (!assetMeterId) return undefined;

  const assetMeter = getAssetMeter(assetMeterId.id, assetMeters);
  const meterId = assetMeter?.properties?.meterId?.id;
  const meter = meterId && R.find((m) => m.id === meterId, meters);

  return meter?.meterTypeId;
};

export const getAssetMeters = (
  record: Record,
  assetMeterReadingEntity: Entity,
) => {
  const assetMeterEntityName =
    assetMeterReadingEntity?.arguments?.assetMeterEntity;
  const related = record?.related;
  return assetMeterEntityName && related ? related[assetMeterEntityName] : [];
};

export const getMeters = (
  context: Context,
  assetMeterReadingEntity: Entity,
  assetMeters: Record[] = [],
) => {
  if (!assetMeters.length) return CancellablePromise.resolve([]);

  const assetMeterEntityName =
    assetMeterReadingEntity?.arguments?.assetMeterEntity;
  const meterEntityName =
    context.entities[assetMeterEntityName]?.arguments?.meterEntity;
  const meterEntity = context.entities[meterEntityName];
  const query = getMetersQuery(meterEntity, assetMeters);

  return searchApi(context.apiCall).runQueryFkExpansion(
    query,
  ) as CancellablePromise<Properties[]>;
};

export const isValid = (
  value: Properties,
  previousReading: number,
  meterTypeId: number,
  maxReadingPerDay?: number,
): boolean => {
  const hasRequiredValue =
    !!value?.assetMeterId && !!value?.date && !!value?.value;
  const isHigherThanPrevious = value?.value > previousReading;
  const isHigherEqualThanPrevious = value?.value >= previousReading;
  const isNotRunningMeter = meterTypeId !== RUNNING_METER_TYPE_ID;

  return (
    hasRequiredValue &&
    (isNotRunningMeter ||
      ((!value?.id ? isHigherThanPrevious : isHigherEqualThanPrevious) &&
        (!maxReadingPerDay ||
          value?.value < previousReading + maxReadingPerDay)))
  );
};

export const getReadingsValueWarning = (
  total: number,
  maxReadingPerDay: number,
) =>
  _(
    "The total value of readings ({TOTAL}) exceeds maximum daily allowance ({MAX})"
      .replace("{TOTAL}", total.toString())
      .replace("{MAX}", maxReadingPerDay.toString()),
  );

export const isExplicitAuthError = (error: ApiErrorResponse) =>
  error.status === 401 || error.status === 400;
