import * as R from "ramda";
import { ValueComponent } from "common/with-value-for";
import { defaultFor } from "common";
import { Geolocation } from "common/types/geolocation";
import { GeolocationInput } from "common/widgets/geolocation";
import { DetailUiValue } from "common/record/types";
import { Tabs } from "common/widgets/tabs/tabs";
import { Tab } from "common/widgets/tabs/tab";
import { Record } from "common/types/records";
import { MAP } from "common/record/utils";
import { RecordDetailValue } from "./detail";

const defaultUi = defaultFor<DetailUiValue>();
const defaultRecord = defaultFor<Record>();

interface PropTypes {
  saving: boolean;
  isTemplate: boolean;
}

const defaultValue = defaultFor<RecordDetailValue>();

export class RecordMap extends ValueComponent<RecordDetailValue, PropTypes> {
  static readonly displayName = "RecordMap";

  onChangeGeolocation = (geolocation: Geolocation) => {
    const { isTemplate, value = defaultValue } = this.props;
    const { detail: ui, record } = value;

    const form = (ui && ui.form) || record.properties;
    const newForm = R.mergeRight(form, { geolocation });

    isTemplate
      ? this.mergeValue2("record", "properties", newForm)
      : this.mergeValue2("detail", "form", newForm);
  };

  render() {
    const { value } = this.props;
    const { detail = defaultUi, record = defaultRecord } = value;
    const { form } = detail;

    const properties = form || record.properties;

    return (
      <Tabs key="map-tabs">
        <Tab value={MAP} label={_("Map")} className="x-section-map">
          <GeolocationInput
            readonly={false}
            value={properties && properties.geolocation}
            onChange={this.onChangeGeolocation}
          />
        </Tab>
      </Tabs>
    );
  }
}
