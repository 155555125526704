import { getLocalizedName } from "common";
import { hasBehavior } from "common/api/behavior";
import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { QueryForEntity } from "common/query/types";
import { RecordActions } from "common/record/actions";
import { NewRecordMenu } from "common/record/new-record-menu";
import { Context } from "common/types/context";
import { Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { Ribbon, RibbonButtons } from "x/layout/ribbon";
import { Crumb } from "x/layout/ribbon/breadcrumb";

interface PropTypes {
  context: Context;
  entity: Entity;
  query: QueryForEntity;
  selected: Record[];
  onReload: () => void;
  reportName?: string;
  goTo?: GoFn;
  crumbs?: Crumb[];
  newPath?: string;
}

export const ListRibbon = ({
  context,
  entity,
  query,
  reportName,
  selected,
  onReload,
  goTo,
  crumbs,
  newPath,
}: PropTypes) => {
  const { site, forms } = context;
  const crumbName = reportName || getLocalizedName(entity);

  const defaultCrumbs: Crumb[] =
    entity && entity.type === "Reference"
      ? [
          { name: _("References"), url: `#/${site.name}/admin/References` },
          { name: crumbName },
        ]
      : [{ name: crumbName }];

  return (
    <Ribbon
      onRefresh={onReload}
      crumbs={crumbs || defaultCrumbs}
      className="qa-records-list-ribbon"
    >
      <RibbonButtons>
        {site.isGroup ||
        hasBehavior(entity.behaviors, "Journal") ||
        entity.type === "SubEntity" ? undefined : (
          <NewRecordMenu
            entity={entity}
            site={site.name}
            context={context}
            forms={filterFormsByEntity(forms, entity.name)}
            newPath={newPath}
          />
        )}
        <RecordActions
          displayType="label"
          goTo={goTo}
          onTheRight={true}
          context={context}
          query={query}
          includeSwitchForm={false}
          entity={entity}
          records={selected}
          reload={onReload}
        />
      </RibbonButtons>
    </Ribbon>
  );
};

ListRibbon.displayName = "ListRibbon";
