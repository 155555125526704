import * as R from "ramda";
import { v4 as uuid } from "uuid";
import { isReferenceEntity } from "common/api/entities";
import { workflowReplaceBlacklist } from "common/entities/entity-column/data-type/types";
import {
  EntityColumn,
  SYSTEM_FIELDS,
} from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import {
  getFields,
  getFullPathFromField,
} from "common/query-builder/functions";
import { UpdateTableValueAction } from "common/types/workflows";

export const isUpdateValueActionValid = (action: UpdateTableValueAction) =>
  !!(
    action?.field &&
    (!action?.isExpression || (action?.value && !action?.error))
  );

export const getUpdateTargetFieldsForEntity = (
  entity: Entity,
  entities: Entities,
) => {
  const joins = entity.joins
    // only one-to-one joins
    .filter(
      (j) =>
        j.outbound &&
        !isReferenceEntity(entities[j.entityName]) &&
        !entities[j.entityName].isSystem,
    );

  const rawFields = getFields(entities, {
    entity: entity.name,
    query: { ...entity.query, joins: joins },
  });

  const fields = R.reject(
    (f) =>
      f.column.unique ||
      R.includes(f.column.name, SYSTEM_FIELDS) ||
      R.includes(f.column.dataType, workflowReplaceBlacklist),
    rawFields,
  );

  return fields;
};

export const getFieldFromFullPath = (
  fullPath: string,
  entity: Entity,
  entities: Entities,
) => {
  if (!fullPath) return undefined;

  const sanitizedPath = fullPath.startsWith("/") ? fullPath : "/" + fullPath;
  const fields = getUpdateTargetFieldsForEntity(entity, entities);
  return fields.find((f) => getFullPathFromField(f) === sanitizedPath);
};

export const getRecordIdForAttachmentField = (column: EntityColumn) => {
  if (!column) return undefined;
  const { dataType } = column;
  return dataType === "document" || dataType === "image" || dataType === "media"
    ? uuid()
    : undefined;
};
