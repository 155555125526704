import { Component } from "react";
import { Context } from "common/types/context";
import { defaultFor, toKebabCase } from "common";
import { Email } from "common/types/records";
import { searchApi } from "common/api/search";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";
import { ActionButton } from "common/ui/buttons";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { EmailAction, isValid } from "common/widgets/email-action";
import { Modal } from "common/widgets/modal";
import { getQuery } from "x/reports/export-utils";
import { MiniReport } from "x/reports/list/types";

interface PropTypes {
  context: Context;
  report: MiniReport;
  className: string;
}

interface EmailType {
  title: string;
  onSave: () => void;
}

interface StateTypes {
  modalValue: Email;
  emailType: EmailType;
}

export class EmailReport extends Component<PropTypes, StateTypes> {
  state: StateTypes = {
    modalValue: undefined,
    emailType: undefined,
  };

  onShowModal = (emailType: EmailType) => () => {
    const defaultValue = defaultFor<Email>();
    this.setState({
      modalValue: defaultValue,
      emailType: emailType,
    });
  };

  onSaveModal = (attachmentType: string) => () => {
    const { context, report } = this.props;
    const { modalValue } = this.state;

    showInfoNotification(_("The report will be sent shortly by email"));

    searchApi(context.apiCall)
      .emailAttachment(
        modalValue,
        getQuery(report),
        report?.name,
        attachmentType,
      )
      .then(() => {
        showInfoNotification(_("The report was successfully sent by email"));
      })
      .catch(() => {
        showErrorNotification(_("We could not complete your request"));
      });

    this.setState({
      modalValue: undefined,
      emailType: undefined,
    });
  };

  onChangeModal = (value: Email) => {
    this.setState({ modalValue: value });
  };

  onCancelModal = () => {
    this.setState({
      modalValue: undefined,
      emailType: undefined,
    });
  };

  render() {
    const { context, className } = this.props;
    const { modalValue, emailType } = this.state;

    const emailList: EmailType[] = [
      {
        title: _("Email as CSV"),
        onSave: this.onSaveModal("csv"),
      },
      {
        title: _("Email as PDF"),
        onSave: this.onSaveModal("pdf"),
      },
    ];

    const emailContent = (
      <EmailAction
        context={context}
        value={modalValue}
        onChange={this.onChangeModal}
      />
    );

    return (
      <>
        <DropdownMenu
          className={className}
          button={
            <ActionButton title={_("Email")}>
              {_("Email")} <i className="fa fa-caret-down" />
            </ActionButton>
          }
          menu={emailList.map((item) => (
            <a
              key={item.title}
              className={`dropdown-item qa-${toKebabCase(item.title)}`}
              onClick={this.onShowModal(item)}
            >
              {item.title}
            </a>
          ))}
        />

        {modalValue && (
          <Modal
            content={emailContent}
            title={emailType.title}
            okLabel={_("Send")}
            onOk={isValid(modalValue) ? emailType.onSave : undefined}
            onCancel={this.onCancelModal}
          />
        )}
      </>
    );
  }
}
