import { isRelatedSiteDataColumn } from "common/entities/entity-column/functions";
import { BooleanTypes } from "common/entities/entity-column/types";
import { isRecordAccessible } from "common/functions/sites";
import { getSystemStringTranslate } from "common/functions/system-string-options";
import { SelectField } from "common/query/types";
import { CalendarLabel } from "common/widgets/calendar-selector/label";
import { CheckboxLabel } from "common/widgets/checkbox/label";
import { CurrencySelectorLabel } from "common/widgets/currency-selector/label";
import { DefaultCurrencyLabel } from "common/widgets/currency/label-with-default";
import { DateLabel } from "common/widgets/date/date-label";
import { DateOffsetLabel } from "common/widgets/date/date-offset-label";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { DocumentLabel } from "common/widgets/document/label";
import { FormattedFk } from "common/widgets/foreign-key";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { GeolocationLabel } from "common/widgets/geolocation/label";
import { ImageLarge } from "common/widgets/image";
import { getSrcParameters } from "common/widgets/image/functions";
import { Label } from "common/widgets/label";
import { LazyHtmlReader } from "common/widgets/lazy-html-reader";
import { LinkRecord } from "common/widgets/link-record";
import { MediaLabel } from "common/widgets/media/label";
import {
  FloatLabel,
  IntLabel,
  UfloatLabel,
  UintLabel,
} from "common/widgets/number/label";
import { getYesNoLabel } from "common/widgets/radio-button-list/yes-no";
import { SignatureImageWithSizer } from "common/widgets/signature/image";
import { SitesLabel } from "common/widgets/site-selector/sites-label";
import { SystemIntFkLabel } from "common/widgets/system-int-foreign-key/label";
import { ReadOnlyText } from "common/widgets/text";
import { LazyTextEditor } from "common/widgets/text-editor";
import { WebLinkLabel } from "common/widgets/web-link/label";
import { defaultColumn, getSiteValue } from "./functions";
import { LabelProps as PropTypes } from "./types";

export const LabelWidget = (props: PropTypes) => {
  const {
    context,
    column = defaultColumn,
    withLinks,
    recordId,
    entityName,
    currencyId,
    textToHighlight,
    isOnAList,
    columnEntity,
    relatedRecordId,
    widgetsMap,
  } = props;
  const { dataType, relatedSystem, relatedEntity, booleanType } = column;
  const { entities, uiFormat, currencies } = context;
  const { decimalSeparator } = uiFormat;

  const value = getSiteValue(context, column, props.value);

  const entity = entities[entityName];

  if (isRelatedSiteDataColumn(column)) {
    return <Label textToHighlight={textToHighlight} value={value} />;
  }

  const isFkAccessible =
    dataType !== "fk" ||
    isRecordAccessible(context, entities[relatedEntity], value);

  const widgetWithLinks =
    (widgetsMap?.[column.name]?.withLink ?? withLinks) && isFkAccessible;
  const isRecord =
    (recordId || relatedRecordId) &&
    entity?.type !== "SubEntity" &&
    (columnEntity === entityName || relatedRecordId) &&
    (column.name === "number" || (!column?.isForeignKey && column.unique)) &&
    value &&
    !(value as SelectField).fn;

  if (isRecord && widgetWithLinks) {
    return (
      <LinkRecord
        site={context.site.name}
        entity={entity}
        id={relatedRecordId ?? recordId}
        getUrl={widgetsMap?.[column.name]?.getUrl}
      >
        <div className={column.name === "number" ? "x-min-width-100" : ""}>
          {value}
        </div>
      </LinkRecord>
    );
  }

  if (column.name === "sites") {
    return (
      <SitesLabel
        context={context}
        value={value}
        textToHighlight={textToHighlight}
      />
    );
  }

  switch (dataType) {
    case "int":
      return <IntLabel textToHighlight={textToHighlight} value={value} />;
    case "uint":
      return <UintLabel textToHighlight={textToHighlight} value={value} />;
    case "date":
      return (
        <DateLabel
          uiFormat={uiFormat}
          textToHighlight={textToHighlight}
          timezone={context.site.timezone}
          value={value}
        />
      );
    case "datetime":
      return (
        <DateTimeLabel
          uiFormat={uiFormat}
          textToHighlight={textToHighlight}
          timezone={context.site.timezone}
          value={value}
        />
      );
    case "dateoffset":
      return (
        <DateOffsetLabel value={value} textToHighlight={textToHighlight} />
      );
    case "currency":
      return (
        <DefaultCurrencyLabel
          context={context}
          currencyId={currencyId}
          column={column}
          value={value}
          textToHighlight={textToHighlight}
        />
      );
    case "float":
      return (
        <FloatLabel
          value={value}
          decimalSeparator={decimalSeparator}
          column={column}
          textToHighlight={textToHighlight}
        />
      );
    case "ufloat":
      return (
        <UfloatLabel
          value={value}
          column={column}
          decimalSeparator={decimalSeparator}
          textToHighlight={textToHighlight}
        />
      );
    case "bool":
      return (
        <CheckboxLabel
          value={value}
          textToHighlight={textToHighlight}
          getLabel={
            booleanType === BooleanTypes.YesNo ? getYesNoLabel : undefined
          }
        />
      );
    case "image":
      return (
        <ImageLarge value={value} srcParameters={getSrcParameters(context)} />
      );
    case "document":
      return <DocumentLabel value={value} textToHighlight={textToHighlight} />;
    case "media":
      return <MediaLabel context={context} value={value} />;
    case "hyperlink":
      return <WebLinkLabel url={value} textToHighlight={textToHighlight} />;
    case "html":
      return isOnAList ? (
        <LazyHtmlReader value={value} />
      ) : (
        <LazyTextEditor value={value} readOnly={true} onChange={undefined} />
      );
    case "geolocation":
      return (
        <GeolocationLabel value={value} textToHighlight={textToHighlight} />
      );
    case "signature":
      return <SignatureImageWithSizer value={value} />;
    case "text":
      return <ReadOnlyText value={value} textToHighlight={textToHighlight} />;
    case "fk":
      return widgetWithLinks ? (
        <LinkFk
          context={context}
          column={column}
          value={value}
          textToHighlight={textToHighlight}
        />
      ) : (
        <FormattedFk
          context={context}
          value={value}
          column={column}
          textToHighlight={textToHighlight}
        />
      );
    case "systemintfk":
      return relatedSystem === "Calendars" ? (
        <CalendarLabel
          calendars={context.calendars}
          withLinks={widgetWithLinks}
          site={context.site.name}
          value={value}
          textToHighlight={textToHighlight}
        />
      ) : (
        <SystemIntFkLabel
          systemTables={context.systemTables}
          tableName={relatedSystem}
          value={value}
          textToHighlight={textToHighlight}
        />
      );
    case "systemstringfk":
      if (relatedSystem === "Currencies") {
        return <CurrencySelectorLabel currencies={currencies} value={value} />;
      }
    // intentional fallthrough
    default:
      return (
        <Label
          textToHighlight={textToHighlight}
          value={getSystemStringTranslate(entity, column.name)(value)}
        />
      );
  }
};
