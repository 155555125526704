import * as R from "ramda";
import { getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Site } from "common/types/sites";
import { isDuplicated } from "common/utils/array";
import { Chicklets } from "common/widgets/chicklets";
import { SiteSelector } from "common/widgets/site-selector/index";
import { getDisplaySiteFn } from "common/widgets/site-tree-selector-with-search/functions";
import { isGroupSite } from "x/account-settings/sites/functions";

interface RecordSitesSelectorProps {
  context: Context;
  readonly?: boolean;
  value: string[];
  onChange: (v: any) => void;
}

export const SitesSelectorWithChicklets = ({
  context,
  readonly,
  value,
  onChange,
}: RecordSitesSelectorProps) => {
  const availableSites = context?.sites;
  const sitesDictionary = getSitesDictionary(availableSites);
  const displaySite = getDisplaySiteFn(sitesDictionary);
  const selectedSites = value
    ? availableSites.filter((s) => value.includes(s.name))
    : [];

  const onChangeSite = (newSiteName: string) => {
    const sites = value || [];

    if (newSiteName && !isDuplicated(newSiteName, sites)) {
      const newSitesNames = sites.concat([newSiteName]);
      onChange(newSitesNames);
    }
  };

  const onChangeSites = (sites: Site[]) => {
    onChange(sites?.map((site) => site.name) || []);
  };

  const canDelete = readonly ? R.F : R.T;

  return (
    <>
      {!readonly && (
        <div className="form-group x-field x-flex-grow-1">
          <SiteSelector
            context={context}
            sitesToOmit={selectedSites}
            includeGroupSites={true}
            value={undefined}
            onChange={onChangeSite}
          />
        </div>
      )}
      <Chicklets<Site>
        className="x-margin-bottom-5 qa-site-chicklets"
        canDeleteChicklet={canDelete}
        display={displaySite}
        isHighlightedChicklet={isGroupSite}
        value={selectedSites}
        onChange={onChangeSites}
      />
    </>
  );
};
