import * as R from "ramda";
import { getLocalizedName } from "common";
import { hasBehavior, isChargeBehaviorName } from "common/api/behavior";
import {
  isFkToEntityColumn,
  isUnique,
} from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { merge1, merge2 } from "common/merge";
import { BehaviorName } from "common/types/behaviors";
import { Context } from "common/types/context";
import { iconList } from "./icons";
import { Arguments, Entities, Entity } from "./types";

export const icons = iconList;

export function filterObj<T1>(
  fn: (x: T1) => boolean,
  obj: { [key: string]: T1 },
): { [key: string]: T1 } {
  return R.compose<
    { [key: string]: T1 },
    Array<[string, T1]>,
    Array<[string, T1]>,
    { [key: string]: T1 }
  >(
    R.fromPairs,
    (l) => l.filter(([_, v]) => fn(v)),
    R.toPairs,
  )(obj);
}

export const filterChargeEntities = (entities: Entities) =>
  filterObj(
    (e) => R.all((b) => !isChargeBehaviorName(b.name), e.behaviors),
    entities,
  );

// TODO return Entity[] because every usage is doing R.values
export const filterByBehavior = (
  behaviorName: BehaviorName,
  entities: Entities,
): Entities =>
  filterObj((e) => hasBehavior(e.behaviors, behaviorName), entities);

export const behaveAs = (behaviorKey: BehaviorName, entity: Entity): boolean =>
  hasBehavior(entity && entity.behaviors, behaviorKey);

export const getEntityByBehavior = (
  behaviorName: BehaviorName,
  entities: Entities,
): Entity => Object.values(entities).find((e) => behaveAs(behaviorName, e));

export const removeByBehavior = (
  behaviorName: BehaviorName,
  entities: Entities,
) =>
  R.keys(entities).reduce((acc, key) => {
    return !behaveAs(behaviorName, entities[key])
      ? R.mergeRight(acc, { [key]: entities[key] })
      : acc;
  }, {});

export const getByBehaviorArgument = (
  entities: Entities,
  behaviorName: BehaviorName,
  argumentName: string,
  argumentValue: string,
) =>
  R.compose(
    R.find((e: Entity) => e.arguments[argumentName] === argumentValue),
    R.values,
  )(filterByBehavior(behaviorName, entities));

export const getByBehaviorArguments = (
  entities: Entities,
  behaviorName: BehaviorName,
  filterArguments: Arguments,
) => {
  const entityList = R.values(filterByBehavior(behaviorName, entities));

  return R.find(
    (entity) => R.whereEq(filterArguments)(entity.arguments),
    entityList,
  );
};

export const filterByBehaviorArguments = (
  entities: Entities,
  behaviorName: BehaviorName,
  filterArguments: Arguments,
) => {
  const entityList = R.values(filterByBehavior(behaviorName, entities));
  return entityList.filter((e) => R.whereEq(filterArguments, e.arguments));
};

export const filterTaskEntities = (
  workOrderEntityName: string,
  entities: Entities,
): Entities =>
  filterObj(
    (e) => e.arguments.workOrderEntity === workOrderEntityName,
    filterByBehavior("Task", entities),
  );

export const isRelatedTo =
  (target: string) =>
  (entity: Entity): boolean =>
    R.includes(
      target,
      entity.columns.map((c) => c.relatedEntity),
    );

export const eventEntitiesTargetAt = (
  entity: string,
  entities: Entities,
): Entity[] => {
  const getEventEntities = (
    behaviorName: BehaviorName,
    ent: string,
  ): string[] =>
    R.compose(
      R.map((e: Entity) => e.name),
      (entities: Entity[]) => R.filter(isRelatedTo(ent), entities),
      R.values,
      (entities: Entities) => filterByBehavior(behaviorName, entities),
    )(entities);

  const relatedCalendarEntities = getEventEntities("RelatedCalendar", entity);
  const calendarsEvents = R.compose(
    (l: string[][]) => R.flatten(l),
    (entities: string[]) => entities?.map((e) => getEventEntities("Event", e)),
  )(relatedCalendarEntities);

  const keys = R.concat(getEventEntities("Event", entity), calendarsEvents);
  return (R.values(entities) as Entity[]).filter((e) =>
    R.includes(e.name, keys),
  );
};

export const withBehavior =
  (behaviorName: BehaviorName) =>
  (entities: Entities): Entity[] =>
    R.values(filterByBehavior(behaviorName, entities));

export const getColumns = (e: Entity) => (e && e.columns) || [];

export const findColumn = (columns: EntityColumn[] = [], name: string) =>
  columns.find((c) => c.name === name);

export const getColumn = (entity: Entity, name: string) =>
  findColumn(entity && entity.columns, name);

export const isSharedMultipleSitesEntity = (entity: Entity) =>
  entity?.recordScope === "SharedMultipleSites";

export const isSingleSiteEntity = (entity: Entity) =>
  entity?.recordScope === "SingleSite";

export const isSharedMultipleSitesRelatedEntity = (entity: Entity) =>
  entity?.recordScope === "SharedMultipleSites" && entity?.type === "SubEntity";

export const getSitesColumn = (entity: Entity) =>
  isSharedMultipleSitesEntity(entity)
    ? findColumn(entity.columns, "sites")
    : undefined;

export const isSitesColumn = (entity: Entity, columnName: string): boolean =>
  isSharedMultipleSitesEntity(entity) && columnName === "sites";

export const getColumnLabel = (entity: Entity, columnName: string) =>
  getLocalizedName(getColumn(entity, columnName));

export const getUniqueColumn = (entity: Entity) => {
  return (
    entity &&
    (R.find((c) => isUnique(c), entity.columns) || getColumn(entity, "number"))
  );
};

export const getRelatedEntities = (
  entity: Entity,
  entities: Entities,
  entityFilter?: (e: Entity) => boolean,
) => {
  return entity && entity.joins
    ? entity.joins
        .filter((join) => join.owner)
        .map((join) => entities[join.entityName])
        .filter((e) => e && (entityFilter ? entityFilter(e) : true))
    : [];
};

export const hasSharedMultipleSitesRelatedEntity = (
  entity: Entity,
  entities: Entities,
) =>
  getRelatedEntities(entity, entities, isSharedMultipleSitesEntity)?.length > 0;

export const getUrl = (entity: Entity, site: string): string => {
  if (!entity) return "/#/all";
  const { name, type } = entity;
  const prefix = [
    `/${site}`,
    type === "Reference" ? "/admin/References" : "",
    entity.displayAsSystem ? "/admin" : "",
  ].join("");

  return `/#${prefix}/${name}`;
};

export const canDo = (entity: Entity, command: string) =>
  R.any((c) => c === command, entity.commands);

export const setEntity = (
  context: Context,
  entityName: string,
  newEntity: Entity,
) => {
  const newContext = merge2("entities", entityName, newEntity, context);
  return merge2("entitiesAvailableColumns", entityName, newEntity, newContext);
};

export const omitColumn = (entity: Entity, columnName: string): Entity => {
  const newColumns = R.reject((c) => c.name === columnName, entity.columns);
  return R.mergeRight(entity, { columns: newColumns });
};

export const isFkToOtherEntity = (entityName: string, entities: Entity[]) =>
  R.any((entity: Entity) => {
    const { name, columns } = entity;
    return (
      name !== entityName && R.any(isFkToEntityColumn(entityName), columns)
    );
  }, entities);

export const mapEntityColumns = (
  entity: Entity,
  mapFn: (e: EntityColumn) => EntityColumn,
) => {
  if (!entity || !mapFn) return entity;

  const newEntityColumns = entity.columns.map(mapFn);
  return merge1("columns", newEntityColumns, entity);
};

export const entityArrayToEntities = (entities: Entity[]): Entities =>
  R.reduce((acc, entity) => ({ ...acc, [entity.name]: entity }), {}, entities);

export const filterEntities = (
  entities: Entities,
  filterFn: (entity: Entity) => boolean,
): Entities => (entities && filterFn ? R.pickBy(filterFn, entities) : entities);
