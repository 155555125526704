import { Component, MouseEvent } from "react";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { someRule } from "common/query-builder/description";
import { isQueryValid } from "common/query-builder/validation";
import { Config, PropTypes as ConfigProps } from "common/query/table/config";
import { Query } from "common/query/types";
import { allConditionTypes } from "common/types/condition-type";
import { eventLabel, trackEvent } from "common/utils/mixpanel";
import {
  getValue,
  ModalValue,
  showModal,
  withModal,
} from "common/widgets/modal/with-modal";
import { ValueProps } from "common/with-value-for";
import {
  isHideDeletedRule,
  toggleIsDeleted,
} from "x/records/list/functions/is-deleted";
import {
  isStarFilter,
  toggleStarFilter,
} from "x/records/list/functions/star-filter";
import { getAnd } from "../functions/common";

export interface FiltersUiValue {
  modalValue: ModalValue<Query>;
}

export interface FilterMenuValue {
  query: Query;
  ui: FiltersUiValue;
}

interface PropTypes extends ValueProps<FilterMenuValue> {
  context: Context;
  entity: Entity;
  starred: string[];
  isReport: boolean;
}

export const ConfigModal = withModal<Query, ConfigProps>(Config);

const defaultValue = defaultFor<FilterMenuValue>();
const defaultUi = defaultFor<FiltersUiValue>();

export class FilterMenu extends Component<PropTypes> {
  static readonly displayName = "FilterMenu";

  showPopup = (e: MouseEvent) => {
    e.preventDefault();
    const { entity, value, onChange } = this.props;
    const { query } = this.props.value;

    onChange({ ...value, ui: { ...value.ui, modalValue: showModal(query) } });

    trackEvent(eventLabel.listViewConfiguration, { entity: entity.name });
  };

  setShowDeleted = (e: MouseEvent) => {
    e.preventDefault();
    const { value = defaultValue, onChange } = this.props;
    const { query } = value;

    const showDeleted = !this.showingDeleted();
    const newQuery = toggleIsDeleted(query, showDeleted);

    onChange({ ...value, query: newQuery });
  };

  showingDeleted = () => {
    const { query } = this.props.value;
    const and = getAnd(query.filter);
    const mainQueryContainsIsDeleted = someRule(
      (r) => isHideDeletedRule(r, "istrue"),
      and,
    );

    return mainQueryContainsIsDeleted;
  };

  setShowStarred = (e: MouseEvent) => {
    e.preventDefault();
    const { value = defaultValue, onChange, starred } = this.props;
    const { query } = value;
    const newQuery = toggleStarFilter(query, starred);

    onChange({ ...value, query: newQuery });
  };

  onChangeFilter = (modalValue: ModalValue<Query>) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      query: getValue(modalValue),
      ui: { ...value.ui, modalValue },
    });
  };

  render() {
    const {
      context,
      entity,
      starred,
      isReport,
      value = defaultValue,
    } = this.props;
    const { ui = defaultUi } = value;
    const { modalValue } = ui;
    const canSave = isQueryValid(context.entities, {
      entity: entity.name,
      query: modalValue?.modalValue,
    });

    return (
      <>
        <ConfigModal
          context={context}
          entity={entity}
          conditionTypes={allConditionTypes}
          title={_("View configuration")}
          size="large"
          isValid={canSave}
          isReport={isReport}
          value={ui.modalValue}
          onChange={this.onChangeFilter}
        />
        <div
          className="x-btn-filter-menu x-margin-right-10 qa-btn-filter-menu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title={_("View")}
          data-original-title={_("View")}
          data-placement="bottom"
        >
          <i className="fa fa-cog" />
        </div>
        <ul className="dropdown-menu dropdown-menu-left x-record-filter-menu">
          <li>
            <a
              href="#"
              onClick={this.showPopup}
              className="qa-btn-filter-popup dropdown-item"
            >
              <i className="fa fa-cog" />
              <span>{_("View configuration")}</span>
            </a>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a
              href="#"
              onClick={this.setShowStarred}
              className="qa-btn-filter-show-starred dropdown-item"
            >
              <i
                className={`fa fa-${
                  isStarFilter(value.query, starred) ? "star" : "star-o"
                }`}
              />
              <span>{_("Starred only")}</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={this.setShowDeleted}
              className="qa-btn-filter-show-deleted dropdown-item"
            >
              <i
                className={`fa fa-${
                  this.showingDeleted() ? "check-square-o" : "square-o"
                }`}
              />
              <span>{_("Include archived")}</span>
            </a>
          </li>
        </ul>
      </>
    );
  }
}
