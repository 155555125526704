import { getFkId } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { searchApi } from "common/api/search";
import {
  createDefaultPartLocationQuery,
  createPartLocationLookupQuery,
} from "common/record/form/content/related/part-location/functions";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { defaultFor } from "common/index";
import { merge2 } from "common/merge";
import {
  replaceLookupQueries,
  updateContextForms,
} from "common/record/form/content/related/common/functions";
import { updateEntity } from "common/record/form/content/related/purchase-order-item/functions";

type Value = {
  onHand: number;
  finalCount: number;
  cost: number;
  manualBatchSelection: boolean;
  batch: ForeignKey;
};

export const searchDefaultLocation = (
  context: Context,
  entity: Entity,
  value: RelatedValue,
  partId: string,
) =>
  searchApi(context.apiCall)
    .runQuery(createDefaultPartLocationQuery(context, entity, value, partId))
    .then((rs: Properties[] = []) => (rs.length ? rs[0] : undefined));

export const updatePartsToBeAddedContext = (
  context: Context,
  value: RelatedValue,
  entity: Entity = defaultFor<Entity>(),
) => {
  const { entities } = context;
  const updatedContext = merge2(
    "entities",
    entity.name,
    updateEntity(entities[entity.name]),
    context,
  );

  const partLocationLookupQuery = createPartLocationLookupQuery(
    updatedContext,
    entity,
    value,
  );

  const updateSettings = replaceLookupQueries(
    "partLocationId",
    partLocationLookupQuery,
  );

  return updateContextForms(updatedContext, entity.name, (form) => ({
    ...form,
    settings: updateSettings(form.settings),
  }));
};

export const isValueValid = (value: Value) => {
  const { manualBatchSelection, finalCount, onHand, batch, cost } = value ?? {};

  const hasBatch = !manualBatchSelection || getFkId(batch) !== undefined;
  const needsCost =
    finalCount > onHand && (!manualBatchSelection || getFkId(batch) === "new");

  return !!finalCount && hasBatch && (!needsCost || cost !== undefined);
};
