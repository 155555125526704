import * as R from "ramda";
import { Props } from "react-select";
import {
  GroupedOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";

export const isGroupedOption = <T>(option: any): option is GroupedOption<T> =>
  R.has("options", option);

export const matchLabel = (label: string | number, searchTerm: string) =>
  !searchTerm ||
  (!!label &&
    label
      .toString()
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase()));

export const filterOptions = <T>(
  options: ReadonlyArray<LabelledOptionOrGroup<T>>,
  searchValue: string,
): Props["options"] =>
  options && searchValue
    ? options.reduce((acc, option) => {
        if (!isGroupedOption(option)) {
          return matchLabel(option.label, searchValue)
            ? acc.concat(option)
            : acc;
        }

        const groupedOption = option.options.filter((subOption) =>
          matchLabel(subOption.label, searchValue),
        );

        return groupedOption.length > 0
          ? acc.concat({ ...option, options: groupedOption })
          : acc;
      }, [])
    : options;

export const sliceOptions = <T>(
  options: ReadonlyArray<LabelledOptionOrGroup<T>>,
  limit: number,
): Props["options"] =>
  options && limit
    ? options
        .slice(0, limit)
        .map((o) =>
          isGroupedOption(o) ? { ...o, options: o.options.slice(0, limit) } : o,
        )
    : options;

export const getNoOptionsMessage = ({ inputValue }: { inputValue: string }) =>
  !inputValue ? _("Type to search") : _("No options");
