import * as R from "ramda";

interface InterfaceWithName {
  name: string;
}

export const commaStringToArray = (value: string): string[] =>
  value ? value.split(",").filter((s) => s !== "") : [];

export const arrayToCommaString = (values: string[]): string =>
  values && values.length ? values.join(",") : "";

export const arrayToString = (
  values: string[],
  separator: string = " ",
): string =>
  values && values.length ? values.filter(R.identity).join(separator) : "";

export const classNames = (classes: string[]) => arrayToString(classes, " ");

export const isDuplicated = (value: string, values: string[]) =>
  !!(value && values && R.any((val) => val === value.toLowerCase(), values));

export const findByName = <T extends InterfaceWithName>(
  list: T[],
  name: string,
): T => R.find((i: T) => i?.name === name, list || []);

export const findSameName = <T extends InterfaceWithName>(
  list: T[],
  item: T,
): T => findByName(list, item?.name);

export const similarArray = <T>(a: T[], b: T[]): boolean =>
  (!a && !b) ||
  (!!a &&
    !!b &&
    a.length === b.length &&
    R.intersection(a, b).length === a.length);

/**
 * Partition given list into sub-lists with items matching and not matching filter in given function
 * @param array
 * @param filterFn
 */
export const partitionArray = <T>(
  array: T[],
  filterFn: (a: T) => boolean,
): [T[], T[]] =>
  (array || []).reduce(
    (acc, cur) => {
      const index = filterFn && filterFn(cur) ? 0 : 1;
      acc[index].push(cur);
      return acc;
    },
    [[], []],
  );

export const sumArray = (arr: number[]) =>
  (arr || []).reduce((a, b) => a + b, 0);

export const uniqueArray = <T>(items: T[]): T[] => [...new Set(items)];
