import * as R from "ramda";
import { Mention, MentionsInput } from "react-mentions";
import { ValueProps } from "common/with-value-for";

export interface Suggestion {
  id: string;
  display: string;
}

interface PropTypes extends ValueProps<string> {
  suggestions: Suggestion[];
  className?: string;
  placeholder?: string;
  multipleLines?: boolean;
}

export const displayTransform = (id: string, display: string) =>
  !display || (R.head(display) === "{" && R.last(display) === "}")
    ? display
    : `{${id}}`;

export const Suggestions = ({
  suggestions,
  className = "",
  placeholder,
  multipleLines = false,
  value,
  onChange,
}: PropTypes) => {
  const onMentionsChange = (e: { target: { value: string } }) => {
    onChange(e.target.value);
  };

  return (
    <div className={className}>
      <MentionsInput
        className="react-mentions"
        markup="{__id__}"
        singleLine={!multipleLines}
        onChange={onMentionsChange}
        value={value}
        placeholder={placeholder}
        displayTransform={displayTransform}
      >
        <Mention trigger="{" data={suggestions} />
      </MentionsInput>
    </div>
  );
};
