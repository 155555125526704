import { PropsWithChildren } from "react";
import { Breadcrumb, Crumb } from "./breadcrumb";

const breadcrumbFor = (title: string, crumbs: Crumb[]) => {
  if (title) return <Breadcrumb crumbs={[{ name: title }]} />;
  if (crumbs) return <Breadcrumb crumbs={crumbs} />;
  return null;
};

interface RibbonPropTypes {
  onRefresh: () => any | void;
  title?: string;
  className?: string;
  crumbs?: Crumb[];
  children?: any;
}

export const Ribbon = ({
  onRefresh,
  title,
  className,
  crumbs,
  children,
}: RibbonPropTypes) => (
  <div className={`x-ribbon ${className || ""}`}>
    {onRefresh ? (
      <div className="btn btn-ribbon" onClick={onRefresh}>
        <i className="fa fa-refresh" />
      </div>
    ) : undefined}
    {breadcrumbFor(title, crumbs)}
    {children}
  </div>
);

export const RibbonButtons = ({ children }: PropsWithChildren<unknown>) => (
  <div className="x-btns-right">{children}</div>
);

Ribbon.displayName = "Ribbon";
RibbonButtons.displayName = "RibbonButtons";
