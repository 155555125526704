import * as R from "ramda";
import { merge1 } from "common/merge";
import { getFormattedQuery } from "common/query/common";
import { getExpandedQuery } from "common/query/context";
import { setFkExpansion } from "common/query/expansion";
import { flattenFkValues } from "common/query/filter";
import { mapSelectToRelatedSummary } from "common/query/select";
import {
  PaginationValue,
  Query,
  QueryContext,
  QueryForEntity,
  RunBulkQueries,
  RunQuery,
  RunQueryWithContext,
} from "common/query/types";
import { queryWithPage, queryWithPageSize } from "common/record/utils";
import { ApiCall, ApiCallFull, ApiResponse } from "common/types/api";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Email } from "common/types/records";
import { withDownload } from ".";

interface ApiSearchType {
  runQuery: RunQuery;
  runBulkQueries: RunBulkQueries;
  runExactQuery: RunQuery;
  runBulkExactQueries: RunBulkQueries;
  runQueryForSite: (
    q: QueryForEntity,
    site: string,
  ) => CancellablePromise<unknown>;
  runQueryFkExpansion: RunQuery;
  runQueryForLookup: (
    q: QueryForEntity,
    lookupSites?: string[],
  ) => CancellablePromise<unknown>;
  runQueryWithContext: RunQueryWithContext;
  downloadCsv: (
    fileName: string,
    query: QueryForEntity,
    site?: string,
  ) => CancellablePromise<any>;
  downloadPdf: (
    fileName: string,
    query: QueryForEntity,
    title?: string,
  ) => CancellablePromise<any>;
  sendResults: (
    email: Email,
    queryWithEntity: QueryForEntity,
  ) => CancellablePromise<any>;
  emailAttachment: (
    email: Email,
    queryWithEntity: QueryForEntity,
    fileName: string,
    attachmentType?: string, // csv by default
  ) => CancellablePromise<void>;
  getRateTypes: (
    contactsEntity: string,
    contactId: string,
  ) => CancellablePromise<unknown>;
}

type RunQueryWithPagination = (
  query: QueryForEntity,
  context: Context,
  page: number,
  pageSize: number,
) => CancellablePromise<ApiResponse<PaginationValue<unknown>>>;

interface ApiSearchFullType {
  runQueryWithPagination: RunQueryWithPagination;
  runLookupQueryWithPagination: (
    query: QueryForEntity,
    context: Context,
    page: number,
    pageSize: number,
    lookupSites?: string[],
  ) => CancellablePromise<ApiResponse<PaginationValue<unknown>>>;
}

const omitPageSize: (query: Query) => Query = R.omit(["pageSize"]);

export const searchApi = (apiCall: ApiCall): ApiSearchType => {
  const runExactQuery =
    (apiCall: ApiCall, site?: string) => (query: QueryForEntity) =>
      apiCall(
        "post",
        `api/entities/${site ? site : ":site"}/${query.entity}/search`,
        query.query,
      );
  const runQueryFor =
    (apiCall: ApiCall, site?: string) =>
    (query: QueryForEntity, title?: string) =>
      apiCall(
        "post",
        `api/entities/${site ? site : ":site"}/${query.entity}/search`,
        mapSelectToRelatedSummary(
          merge1("title", title, flattenFkValues(query).query),
        ),
      );

  const runLookupQueryFor = (
    apiCall: ApiCall,
    sites: string[],
    query: QueryForEntity,
  ) =>
    apiCall("post", `api/entities/:site/${query.entity}/lookup-search`, {
      query: mapSelectToRelatedSummary(flattenFkValues(query).query),
      sites,
    });

  return {
    runQuery: runQueryFor(apiCall),
    runBulkQueries: (queries: QueryForEntity[]) =>
      CancellablePromise.all(queries.map((q) => runQueryFor(apiCall)(q))).then(
        (response) => (Array.isArray(response) ? R.unnest(response) : response),
      ),
    runExactQuery: runExactQuery(apiCall),
    runBulkExactQueries: (queries: QueryForEntity[]) =>
      CancellablePromise.all(
        queries.map((q) => runExactQuery(apiCall)(q)),
      ).then((response) =>
        Array.isArray(response) ? R.unnest(response) : response,
      ),
    runQueryForSite: (query: QueryForEntity, site: string) =>
      runQueryFor(apiCall, site)(query),
    runQueryFkExpansion: (query: QueryForEntity, title?: string) =>
      runQueryFor(apiCall)(setFkExpansion(query), title),
    runQueryForLookup: (query: QueryForEntity, lookupSites?: string[]) =>
      runLookupQueryFor(apiCall, lookupSites, setFkExpansion(query)),
    runQueryWithContext: (query: QueryForEntity, queryContext: QueryContext) =>
      runQueryFor(apiCall)(
        getExpandedQuery(setFkExpansion(query), queryContext),
      ),

    downloadCsv: (fileName, query, site) =>
      runQueryFor(
        withDownload(apiCall, "text/csv", `${fileName}.csv`),
        site,
      )(query),

    downloadPdf: (fileName, query, title) =>
      runQueryFor(withDownload(apiCall, "application/pdf", `${fileName}.pdf`))(
        query,
        title,
      ),
    sendResults: (email, queryWithEntity) =>
      apiCall(
        "post",
        `api/entities/:site/${queryWithEntity.entity}/stored-queries`,
        {
          query: omitPageSize(queryWithEntity.query),
          email,
        },
      ),
    emailAttachment: (email, queryWithEntity, fileName, attachmentType) =>
      apiCall(
        "post",
        `api/entities/:site/${queryWithEntity.entity}/email-search`,
        {
          query: mapSelectToRelatedSummary({
            ...queryWithEntity.query,
            title: fileName,
          }),
          email,
          attachmentType,
        },
      ),
    getRateTypes: (contactsEntity: string, contactId: string) =>
      apiCall(
        "get",
        `api/entities/:site/${contactsEntity}/${contactId}/rate-types`,
      ),
  };
};

export const apiSearchFull = (apiCallFull: ApiCallFull): ApiSearchFullType => {
  const runQueryWithPagination =
    (apiCallFull: ApiCallFull, site?: string) =>
    (
      query: QueryForEntity,
      title?: string,
    ): ReturnType<RunQueryWithPagination> =>
      apiCallFull(
        "post",
        `api/entities/${site ? site : ":site"}/${query.entity}/search-paged`,
        getFormattedQuery(query, title),
      );

  const runLookupQueryWithPagination =
    (apiCallFull: ApiCallFull) =>
    (
      query: QueryForEntity,
      sites?: string[],
    ): ReturnType<RunQueryWithPagination> =>
      apiCallFull(
        "post",
        `api/entities/:site/${query.entity}/lookup-search-paged`,
        { query: getFormattedQuery(query), sites },
      );

  return {
    runQueryWithPagination: (query, context, page, pageSize) => {
      const pageQuery = queryWithPageSize(queryWithPage(query, page), pageSize);
      return runQueryWithPagination(apiCallFull)(
        getExpandedQuery(setFkExpansion(pageQuery), context),
      );
    },
    runLookupQueryWithPagination: (
      query,
      context,
      page,
      pageSize,
      lookupSites,
    ) => {
      const pageQuery = queryWithPageSize(queryWithPage(query, page), pageSize);
      return runLookupQueryWithPagination(apiCallFull)(
        getExpandedQuery(setFkExpansion(pageQuery), context),
        lookupSites,
      );
    },
  };
};
