import * as React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

let defaultContext: any;

/**
 * This is singleton used to initialize only once dnd in our app.
 * If you initialized dnd and then try to initialize another dnd
 * context the app will break.
 * Here is more info: https://github.com/gaearon/react-dnd/issues/186
 *
 * The solution is to call Dnd context from this singleton this way
 * all dnd contexts in the app are the same.
 */
export function makeDraggable<T extends React.ComponentClass<U>, U>(
  comp: T,
): T {
  const ctx = defaultContext
    ? defaultContext
    : (defaultContext = DragDropContext(HTML5Backend));
  return ctx(comp);
}
