import * as R from "ramda";
import { preferencesApi } from "common/api/preferences";
import { ApiCall } from "common/types/api";
import {
  Preferences,
  PreferenceService,
  Subscription,
} from "common/types/preferences";

const subscriptions: Subscription[] = [];
const notify = () => subscriptions.forEach((fn) => fn());
// TODO: RTS!
// Remove this and use the get metod from the API when ready.
let godPref: Preferences;

export const preferencesService = (
  apiCall: ApiCall,
  defPrefs: Preferences,
): PreferenceService => {
  const api = preferencesApi(apiCall);

  godPref = godPref || defPrefs;

  const mutate = (p: Preferences) => {
    godPref = R.mergeRight(godPref, p); // TODO: fix this!
    notify();
  };

  return {
    subscribe: (fn) => {
      subscriptions.push(fn);
      return () => subscriptions.splice(subscriptions.indexOf(fn), 1);
    },
    notify,
    get: () => godPref,
    set: (value) => {
      const newValue = {
        ...value,
        menu: value.menu.map((m) => ({ ...m, labels: undefined })),
      };
      return api.set(newValue).then(() => mutate(value));
    },
  };
};
