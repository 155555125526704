import { defaultFor } from "common";
import { BooleanOptions } from "common/entities/entity-column/types";
import { FancyCheckboxSmall } from "common/widgets/fancy-checkbox";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  options?: BooleanOptions;
}

export class BooleanQuickInput extends ValueComponent<boolean, PropTypes> {
  static readonly displayName = "BooleanQuickInput";
  render() {
    const { options = defaultFor<BooleanOptions>(), value } = this.props;
    const {
      trueTitle = _("True"),
      falseTitle = _("False"),
      twoCheckboxes,
    } = options;

    if (twoCheckboxes) {
      return (
        <div>
          <FancyCheckboxSmall
            className="x-margin-bottom-2"
            label={trueTitle}
            value={value}
            onChange={this.onChangeSetDefaultValue(true)}
          />
          <FancyCheckboxSmall
            className="x-margin-left-10"
            label={falseTitle}
            value={!value}
            onChange={this.onChangeSetDefaultValue(false)}
          />
        </div>
      );
    }
    return (
      <FancyCheckboxSmall
        label={value ? trueTitle : falseTitle}
        value={value}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
