import * as R from "ramda";
import { ReactNode } from "react";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Group } from "common/form/types";
import { getSitesColumn, isSharedMultipleSitesEntity } from "common/entities";
import { getLocalizedName } from "common/index";
import { SitesLabel } from "common/widgets/site-selector/sites-label";
import { GroupColumn } from "./column";

interface PropTypes {
  context: Context;
  entityName: string;
  record: Properties;
  withLinks: boolean;
  groups: Group[];
  relatedEntitiesForOverviewTop?: ReactNode;
  relatedEntitiesForOverviewBottom?: ReactNode;
  disablePositions?: boolean;
  className?: string;
}

const isLeft = (g: Group) => g.position === "left";

export const ViewForm = ({
  context,
  entityName,
  record,
  withLinks,
  groups = [],
  relatedEntitiesForOverviewTop,
  relatedEntitiesForOverviewBottom,
  disablePositions,
  className = "",
}: PropTypes) => {
  const [leftGroups, rightGroups] = R.partition(
    disablePositions ? R.always(true) : isLeft,
    groups,
  );
  const style =
    leftGroups.length && rightGroups.length ? "col-sm-6" : "col-sm-12";

  const leftGroupColumn = leftGroups.length ? (
    <GroupColumn
      groups={leftGroups}
      style={style}
      withLinks={withLinks}
      entityName={entityName}
      record={record}
      context={context}
    />
  ) : undefined;

  const rightGroupColumn = rightGroups.length ? (
    <GroupColumn
      groups={rightGroups}
      style={style}
      withLinks={withLinks}
      entityName={entityName}
      record={record}
      context={context}
    />
  ) : undefined;

  const entity = context.entities[entityName];
  const sitesLabel = isSharedMultipleSitesEntity(entity) ? (
    <div className="x-sites-label-container">
      <div className="x-sites-column-label">
        {getLocalizedName(getSitesColumn(entity))}
      </div>
      <div className="x-sites-column-value">
        <SitesLabel
          context={context}
          value={record?.sites}
          textToHighlight={undefined}
        />
      </div>
    </div>
  ) : undefined;

  return (
    <div className={`x-read-only-groups row ${className}`}>
      {sitesLabel}
      {relatedEntitiesForOverviewTop ? (
        <div className={`x-groups ${style}`}>
          {relatedEntitiesForOverviewTop}
        </div>
      ) : undefined}
      {leftGroupColumn}
      {rightGroupColumn}
      {relatedEntitiesForOverviewBottom ? (
        <div className={`x-groups ${style}`}>
          {relatedEntitiesForOverviewBottom}
        </div>
      ) : undefined}
    </div>
  );
};
ViewForm.displayName = "ViewForm";
