import * as R from "ramda";
import { isDateValid } from "common/date-time/validators";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import {
  isDynamicValue,
  isValidDynamicValue,
} from "common/form/dynamic-values";
import { DataType } from "./entities/entity-column/data-type/types";
import { isSharedMultipleSitesRelatedEntity } from "./entities";

interface Unique {
  value: any;
  duplicated: boolean;
  pending: boolean;
}

interface FnObject {
  [index: string]: (x: any) => boolean;
}

interface Value<T> {
  value: T;
}

const regex = {
  email: /^[^\s@]+@[^\s@]+\.\w+$/i,
  emailDomain: /^[^\s@]+\.\w+$/i,
  currency: /^(?:|0|[1-9]+\d*)(?:\.\d*)?$/,
  float: /^-?(?:|0|[1-9]+\d*)(?:\.\d*)?$/,
  ufloat: /^(?:|0|[1-9]+\d*)(?:\.\d*)?$/,
  int: /^(?:-?|-?[1-9]\d*|0)$/,
  uint: /^(?:[1-9]\d*|0)$/,
  datetimezone: /[+-]\d\d:\d\d$/,
  utczone: /Z$/,
  site: /^[a-z0-9]*$/,
  letters: /^[a-z]+$/i,
  guid: /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/i,
  dateoffset: /^R/,
};

const decimalRegex = (scale?: number) =>
  scale
    ? new RegExp(`^-?(?:|0|[1-9]+\\d*)(?:\\.\\d{0,${scale}})?$`)
    : regex.float;

export const email = (val: string) => !val || regex.email.test(val);
export const emailDomain = (val: string) => !val || regex.emailDomain.test(val);
export const currency = (val: string) => !val || regex.currency.test(val);
export const float = (val: string | number) =>
  !val || regex.float.test(val.toString());
export const int = (val: string | number) =>
  !val || regex.int.test(val.toString());
export const ufloat = (val: string | number) =>
  !val || regex.ufloat.test(val.toString());
export const uint = (val: string | number) =>
  !val || regex.uint.test(val.toString());
export const siteName = (val: string) => !val || regex.site.test(val);
export const letters = (val: string) => !val || regex.letters.test(val);
export const guid = (val: string) => !val || regex.guid.test(val);
const datetime = (date: string) => !date || isDateValid(date);
const date = datetime;
export const dateoffset = (date: string) =>
  !date || regex.dateoffset.test(date);

export const required = (val: any, dataType?: DataType) =>
  dataType === "array"
    ? !!val?.length
    : val !== null && val !== undefined && val !== "";

const maxValue = (max: number, val: number) => R.isNil(val) || val <= max;
const minValue = (min: number, val: number) => R.isNil(val) || val >= min;

const maxLength = (l: number, val: string) =>
  !val || !R.is(Number, val.length) || val.length <= l;
const minLength = (l: number, val: string) =>
  !val || !R.is(Number, val.length) || val.length >= l;

export const isDecimal = (scale: number, val: string | number) =>
  R.isNil(val) || decimalRegex(scale).test(val.toString());

const unique = (val: Unique) =>
  !val || (!val.pending && (!val.value || !val.duplicated));

const validationFns: FnObject = {
  email,
  currency,
  float,
  int,
  ufloat,
  uint,
  date,
  datetime,
  dateoffset,
};

const getValue = <T>(v: Value<T> | T): T => (v as any)?.value || v;

const isRequiredMissing = (col: EntityColumn, value: any) =>
  col.required && !required(value, col.dataType);

const isMaxValueExceeded = (col: EntityColumn, value: any) =>
  col.maxValue &&
  R.includes(col.dataType, ["int", "uint", "float", "ufloat"]) &&
  !maxValue(col.maxValue, value);

const isMinValueNotReached = (col: EntityColumn, value: any) =>
  col.minValue &&
  R.includes(col.dataType, ["int", "uint", "float", "ufloat"]) &&
  !minValue(col.minValue, value);

const isMaxLengthExceeded = (col: EntityColumn, value: any) =>
  col.maxLength && !maxLength(col.maxLength, value);

const isMinLengthNotReached = (col: EntityColumn, value: any) =>
  col.minLength && !minLength(col.minLength, value);

const isDecimalInvalid = (col: EntityColumn, value: any) =>
  col.decimalScale &&
  R.includes(col.dataType, ["currency", "float", "ufloat"]) &&
  !isDecimal(col.decimalScale, value);

const isValueInvalid = (col: EntityColumn, value: any) =>
  validationFns[col.dataType] && !validationFns[col.dataType](value);

const isValueNotUnique = (col: EntityColumn, value: any) =>
  col.unique && !unique(value);

export const column = (col: EntityColumn, value: any) => {
  const unwrappedValue = getValue(value);

  return [
    isRequiredMissing(col, unwrappedValue) ? "required" : undefined,
    isMaxValueExceeded(col, unwrappedValue) ? "maxValue" : undefined,
    isMinValueNotReached(col, unwrappedValue) ? "minValue" : undefined,
    isMaxLengthExceeded(col, unwrappedValue) ? "maxLength" : undefined,
    isMinLengthNotReached(col, unwrappedValue) ? "minLength" : undefined,
    isDecimalInvalid(col, unwrappedValue) ? "invalidDecimal" : undefined,
    isValueInvalid(col, unwrappedValue) ? "invalid" : undefined,
    isValueNotUnique(col, value) ? "unique" : undefined,
  ].filter((m) => !!m);
};

const skipColumnValidation = (column: EntityColumn, entity: Entity) =>
  column.readOnly ||
  (isSharedMultipleSitesRelatedEntity(entity) && column.name === "sites");

export const columnWithValue = (
  entity: Entity,
  col: EntityColumn,
  value: any,
) => {
  if (skipColumnValidation(col, entity)) return [];
  return column(col, value);
};

export const columnWithDynamicValue = (
  entities: Entities,
  entity: Entity,
  col: EntityColumn,
  value: any,
) => {
  if (skipColumnValidation(col, entity)) return [];

  const unwrappedValue = getValue(value);
  const relatedEntity = col.relatedEntity
    ? entities[col.relatedEntity]
    : undefined;

  return isDynamicValue(unwrappedValue)
    ? isValidDynamicValue(col.dataType, relatedEntity, unwrappedValue)
      ? []
      : ["invalid"]
    : column(col, value);
};

export const isRecordSelfReference = (
  entityName: string,
  recordId: string,
  column: EntityColumn,
  value: any,
) =>
  column.dataType === "fk" &&
  column.relatedEntity === entityName &&
  !!recordId &&
  recordId === value?.id;
