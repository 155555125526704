import * as R from "ramda";
import { withAuth0 } from "@auth0/auth0-react";
import { defaultFor } from "common";
import { userAuthApi } from "common/api/authentication/user";
import { avatarUrl } from "common/api/avatar";
import { parseQueryString } from "common/app/query-string";
import { withApiCall } from "common/app/with-api-call";
import { withContext } from "common/app/with-context";
import { withDirty } from "common/app/with-dirty";
import { withMixPanel } from "common/app/with-mixpanel";
import { withWalkMe } from "common/app/with-walkme";
import { deepEqual } from "common/component";
import { behaveAs } from "common/entities";
import { dataDog } from "common/monitoring/datadog";
import { rememberValue } from "common/remember-value";
import { isRequestorUserType, isVendorUserType } from "common/types/users";
import { goTo } from "common/utils/go-to";
import { configureMixpanelFromContext } from "common/utils/mixpanel";
import { load as loadPushNotifications } from "common/utils/push-notifications";
import { redirectToVendorPortal } from "common/utils/vendor-redirect";
import { setLocationHref } from "common/utils/window-location";
import { getLogoutParams } from "common/vendor-wrappers/auth0/config";
import { GeneralPermissionsError } from "common/widgets/error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueComponent } from "common/with-value-for";
import { AllPropTypes, Props } from "x/types";
import {
  getPathWithoutSite,
  hasEnteredAdmin,
  splitLocation,
} from "./functions";
import { Layout, LayoutValue } from "./layout";
import { RoutesValue, UiRoutes } from "./routes";

export class UiControllerLayout extends ValueComponent<
  LayoutValue,
  AllPropTypes
> {
  static readonly displayName = "UiControllerLayout";
  routesValue = defaultFor<RoutesValue>();

  componentDidMount() {
    const { tenant, uiFormat, site, id, name, userName, version } =
      this.props.context;

    dataDog.initialize({
      scope: "x",
      tenant: tenant.name,
      parentTenant: tenant.parentTenantName,
      language: uiFormat.culture,
      site: site.name,
      version,
      user: { id, name, email: userName },
    });

    configureMixpanelFromContext(this.props.context);
    loadPushNotifications();
  }

  componentDidUpdate(prevProps: Props) {
    if (getPathWithoutSite(prevProps) !== getPathWithoutSite(this.props)) {
      this.mergeValue({
        siteOpen: false,
        lastNonAdminPath: this.getLastNonAdminPath(prevProps, this.props),
      });
      this.props.setDirty(false);
    }
  }

  getLastNonAdminPath = (prevProps: Props, props: Props) =>
    hasEnteredAdmin(getPathWithoutSite(prevProps), getPathWithoutSite(props))
      ? getPathWithoutSite(prevProps)
      : prevProps.value.lastNonAdminPath;

  onChangeRoutes = (routes: RoutesValue) => {
    const { query, avatar, isDirty } = routes;
    this.mergeValue({ query, avatar });
    if (this.props.isDirty !== isDirty) {
      this.props.setDirty(isDirty);
    }
  };

  onLogout = () => {
    const { apiCall, context, auth0 } = this.props;
    const { tenant } = context;
    const { sso, auth0ClientId } = tenant;

    if (sso && !context.isSystem && !context.isImpersonated) {
      userAuthApi(apiCall).logOut();
      const logoutParams = getLogoutParams(auth0ClientId);
      auth0?.logout(logoutParams);
    } else
      userAuthApi(apiCall)
        .logOut()
        .then(() => setLocationHref("/login"));
  };

  getRoutesValue = () => {
    const { isDirty, value } = this.props;
    const routesValue: RoutesValue = {
      query: value.query,
      avatar: value.avatar,
      isDirty,
    };
    // to avoid re-renders in child components
    this.routesValue = deepEqual(routesValue, this.routesValue)
      ? this.routesValue
      : routesValue;
    return this.routesValue;
  };

  render() {
    const { location, context, reloadUi, value, history } = this.props;
    const { page, id, extra } = splitLocation(location.pathname);

    if (isVendorUserType(context?.userTypes)) {
      const entity = context.entities[page];
      if (!behaveAs("WorkOrder", entity)) return <GeneralPermissionsError />;

      redirectToVendorPortal(context, location);
      return <LoadingIcon />;
    }

    if (isRequestorUserType(context?.userTypes)) {
      return <GeneralPermissionsError />;
    }

    const queryString = parseQueryString(location.search);
    const goToFn = goTo(history);

    return (
      <Layout
        context={context}
        page={page}
        id={id}
        extra={extra}
        queryString={queryString}
        goTo={goToFn}
        path={location.pathname}
        reloadUi={reloadUi}
        onLogout={this.onLogout}
        value={value}
        onChange={this.onChangeSetValue}
      >
        <UiRoutes
          context={context}
          page={page}
          id={id}
          extra={extra}
          queryString={queryString}
          goTo={goToFn}
          reloadUi={reloadUi}
          value={this.getRoutesValue()}
          onChange={this.onChangeRoutes}
        />
      </Layout>
    );
  }
}

/// ------------------------------------------------

const defaultLayoutValue = R.mergeRight(defaultFor<LayoutValue>(), {
  avatar: avatarUrl,
  menuOpen: true,
  siteOpen: false,
  historyOpen: false,
  lastNonAdminPath: undefined,
});

const LayoutWithState = rememberValue<LayoutValue, AllPropTypes>(
  UiControllerLayout,
  defaultLayoutValue,
);

const LayoutWithAuth0 = withAuth0(LayoutWithState);

const LayoutWithWalkMe = withWalkMe(LayoutWithAuth0);

const LayoutWithMixPanel = withMixPanel(LayoutWithWalkMe);

const LayoutWithTheApiCall = withApiCall(LayoutWithMixPanel);

const LayoutWithStateAndContext = withContext(LayoutWithTheApiCall);

export const LayoutWithDirty = withDirty(LayoutWithStateAndContext);
