import * as R from "ramda";
import { ApiCall } from "common/types/api";
import { merge1, mergeChain } from "common/merge";
import { ValueComponent } from "common/with-value-for";
import { SYSTEM_FIELDS } from "common/entities/entity-column/types";
import { defaultRecord, Record, RelatedRecords } from "common/types/records";
import { recordsApi } from "common/api/records";
import { PropTypes as DefaultPropTypes } from "./table-with-form/types";
import { StandardRelated } from "./standard-related";
import { RelatedValue } from "./types";

interface PropTypes extends DefaultPropTypes {
  valueKey: string;
}

interface TaskType {
  entity: string;
  id: any;
}

type FlattenRelated = [string, Record[]];

// TODO: omit entities that are not requirements
const getRequirements = (apiCall: ApiCall, task: TaskType) =>
  recordsApi(apiCall)
    .get(task.entity, task.id, true)
    .then((r = defaultRecord) => r.related);

const appendControlFields =
  (value: RelatedValue) =>
  ([name, reqRecords]: [string, Record[]]): FlattenRelated => {
    const currentValue = value.record.related[name];
    // Append control fields to the requirement records

    const recordsWithoutSystem = reqRecords.map((r) =>
      merge1("properties", R.omit(SYSTEM_FIELDS, r.properties), r),
    );
    const newRecords = currentValue.concat(recordsWithoutSystem);

    return [name, newRecords];
  };

export class Task extends ValueComponent<RelatedValue, PropTypes> {
  onAccepted = (recordProperties: any) => {
    const { context, valueKey, value } = this.props;
    // TODO: RTS
    getRequirements(context.apiCall, recordProperties.taskId).then(
      (taskRequirements) => {
        const requirements = R.compose<RelatedRecords, RelatedRecords>(
          R.omit([valueKey]),
        )(taskRequirements);

        const newRequirements = R.compose<
          RelatedRecords,
          FlattenRelated[],
          FlattenRelated[],
          FlattenRelated[],
          FlattenRelated[],
          RelatedRecords
        >(
          R.fromPairs,
          R.map(appendControlFields(value)),
          R.map(
            ([k, v]: FlattenRelated): FlattenRelated => [
              k.replace("task", ""),
              v,
            ],
          ),
          R.filter(([_, v]: FlattenRelated) => !!v.length),
          R.toPairs,
        )(requirements);

        // Merge the new (and updated) requirements with the existing value
        this.setValue(
          mergeChain(value)
            .prop("record")
            .prop("related")
            .setMany(newRequirements)
            .output(),
        );
      },
    );
  };

  render() {
    return (
      <StandardRelated
        {...this.props}
        actions={[{ name: "Accept", fn: this.onAccepted }]}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
