import { Component } from "common/component";
import { makeDraggable } from "common/vendor-wrappers/react-dnd";
// import { shallowEqual } from '../../with-value-for';
// eslint-disable-next-line import/no-cycle
import { Header, Props as PropTypes } from "./header";

// const regularProps = R.omit(['value', 'columnDefinitions']);

const DraggableContainer = makeDraggable(Header);

export class HeaderDraggable extends Component<PropTypes> {
  /*
  DO NOT UNCOMMENT THIS UNLESS YOU KNOW HOW TO FIX THE FOLLOWING:
  1) In the first render with give the DraggableContainer a value.query Q0
  2) Then the query updates to Q1 (that has the same shape and data as Q0 but is
     a different object): R.equals(Q0, Q1) === true && Q0 != Q1
  3) Then the user interacts with DraggableContainer and provokes an onChange
     on value.selected, the expected value should have { query: Q1 } but the
     commented code will produce { query: Q0 } and that is hidden state!

  shouldComponentUpdate(newProps:PropTypes) {
    const oldProps = this.props;
    const areEqual =
      R.equals(oldProps.value, newProps.value)
      && R.equals(oldProps.columnDefinitions, newProps.columnDefinitions)
      && shallowEqual(
        regularProps(oldProps),
        regularProps(newProps));
    return !areEqual;
  }
  */
  render() {
    return <DraggableContainer {...this.props} />;
  }
}
