import * as R from "ramda";
import { Properties, Record } from "common/types/records";
import { Entities } from "common/entities/types";
import { TableConfig } from "common/query/table/types";
import { QueryForEntity, SelectField } from "common/query/types";
import { Preferences, PreferenceService } from "common/types/preferences";
import { PropTypes } from "./types";

const pageSize = 10;

export const getQuery = ({
  recordId,
  query,
  entity,
  withOwnerId,
  orderBy,
}: PropTypes): QueryForEntity => {
  // take query:
  // 1 - remove the ownerId and createdOn from the select
  // 2 - add a filter stating the ownerId == recordId

  const ownerId = entity.ownerFkColumn;

  const isValidColumn = withOwnerId
    ? () => true
    : (s: SelectField) => (s.path && s.path !== "/") || s.name !== ownerId;

  return {
    entity: entity.name,
    query: R.mergeRight(query, {
      pageSize,
      joins: query.joins,
      select: R.compose(
        (sels: SelectField[]) => R.prepend({ name: "id" }, sels),
        (sels: SelectField[]) => R.filter<SelectField>(isValidColumn, sels),
      )(query.select as SelectField[]),
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          { name: ownerId, op: "eq", value: recordId },
        ],
      },
      order: orderBy ? [orderBy] : [],
    }),
  };
};

export const getConfig = (
  { ignore = [], actions, actionsWithContent }: PropTypes,
  query: QueryForEntity,
  entities: Entities,
): TableConfig => {
  const entityIgnore =
    entities[query.entity].type !== "SubEntity" ? [] : ["number"];

  return {
    ignore: [...ignore, "id", ...entityIgnore],
    actions,
    actionsWithContent,
    allowDrag: false,
    allowOrder: true,
  };
};

export const setWidthsToPreferences = (
  parentEntityName: string,
  site: string,
  entityName: string,
  widths: number[],
  preferenceService: PreferenceService,
) => {
  const currentPrefs = preferenceService.get();

  const relatedPrefs = R.find(
    (setting) =>
      setting.entityName === parentEntityName && setting.site === site,
    currentPrefs.related,
  );

  const newPrefs: Preferences = {
    ...currentPrefs,
    // removes current entity+site pref if exists and appends new merged
    related: R.without([relatedPrefs], currentPrefs.related).concat([
      {
        entityName: parentEntityName,
        site,
        widths: R.mergeRight(relatedPrefs?.widths, {
          [entityName]: widths,
        }),
      },
    ]),
  };

  preferenceService.set(newPrefs);
};

export const updateRecordsWithProperties = (
  records: Record[],
  properties: Properties[],
) => {
  const newPropsById: { [id: string]: Properties } = properties.reduce(
    (acc, properties) => ({
      ...acc,
      [properties.id || properties.tempId]: properties,
    }),
    {},
  );

  return records.map((r) => ({
    ...r,
    properties:
      newPropsById[r.properties.id || r.properties.tempId] || r.properties,
  }));
};

export const getIgnoreColumns = (ignore: string[] = []) => [
  "isDeleted",
  "tempId",
  ...ignore,
];
