import { isAssignmentEvent } from "common/functions/scheduler";
import type { SchedulerEvent } from "common/types/scheduler";
import { arrayToString } from "common/utils/array";
import type { EventRendererDetail } from "x/scheduler2/types";

const getConflictIcon = (event: SchedulerEvent) =>
  event?.hasConflicts
    ? `<i class="fa fa-warning" title="${_("Assignment conflict")}"></i>`
    : undefined;

const getMultipleAssignmentIcon = (event: SchedulerEvent) =>
  isAssignmentEvent(event) && event.isMultiple
    ? `<i class="fa fa-users" title="${_("Multiple assignments")}"></i>`
    : undefined;

export const eventRenderer = ({ eventRecord }: EventRendererDetail) => {
  const { eventData } = eventRecord;

  if (!eventData) return eventRecord.name ?? "";

  const conflictIcon = getConflictIcon(eventData);
  const multipleAssignmentIcon = getMultipleAssignmentIcon(eventData);

  return arrayToString([
    conflictIcon,
    multipleAssignmentIcon,
    eventRecord.name,
  ]);
};
