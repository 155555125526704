import { returnAndNotify } from "common/api/with-notifications";
import { Context } from "common/types/context";
import {
  PartReorderPaginationValue,
  PurchaseOrderPreview,
} from "common/types/part-reorder";
import { CancellablePromise } from "common/types/promises";
import {
  PreviewPayload,
  PurchaseOrderPayload,
} from "x/parts-reorder/purchase-order/types";

export const partReorderApi = (context: Context) => ({
  list: (
    page: number,
    pageSize: number,
  ): CancellablePromise<PartReorderPaginationValue> =>
    context.apiCall(
      "get",
      `api/:site/part-reorder-list?page=${page}&pageSize=${pageSize}`,
    ),

  preview: (purchaseOrders: PreviewPayload[] = []) =>
    context.apiCall<PurchaseOrderPreview[]>(
      "post",
      `api/:site/part-reorder-list/preview`,
      purchaseOrders,
    ),

  createPurchaseOrders: (
    purchaseOrders: PurchaseOrderPayload[] = [],
  ): CancellablePromise<string[]> =>
    context
      .apiCall("put", `api/:site/part-reorder-list/create-pos`, purchaseOrders)
      .then(
        returnAndNotify(
          purchaseOrders.length === 1
            ? _("The purchase order was successfully created")
            : _("The purchase orders were successfully created"),
        ),
      ),
});
