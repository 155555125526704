import { noOp } from "common";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Ribbon } from "x/layout/ribbon";

export const ContentLoading = () => (
  <div className="x-container-with-ribbon">
    <Ribbon onRefresh={noOp} crumbs={[{ name: `${_("Loading")}...` }]} />
    <div className="x-content-with-ribbon">
      <div className="x-records-list widget-body x-padding-bottom-0">
        <LoadingIcon />
      </div>
    </div>
  </div>
);

ContentLoading.displayName = "ContentLoading";
