import * as R from "ramda";
import { defaultFor } from "common";
import { findSite, getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Site } from "common/types/sites";
import { getSubTree } from "common/utils/tree";
import { siteName } from "common/validate";
import { getDisplaySiteFn } from "common/widgets/site-tree-selector-with-search/functions";

export const MAX_SITE_NAME = 10;
export const MIN_SITE_NAME = 3;

export const getSitesOrDefaultToAll = (
  formSites: string[],
  contextSites: Site[],
) => {
  const defaultToAll = [
    R.mergeRight(defaultFor<Site>(), {
      label: _("Available for all sites"),
      isGroup: true,
    }),
  ];
  const sitesDictionary = getSitesDictionary(contextSites);
  return formSites?.length
    ? formSites.reduce((acc, s) => {
        const site = sitesDictionary[s];
        return site ? [...acc, site] : acc;
      }, [])
    : defaultToAll;
};

export const isSiteDuplicated = (siteName: string, sites: Site[]) =>
  !!(
    siteName &&
    sites &&
    R.any((s) => s.name === siteName.toLowerCase(), sites)
  );

export const hasAmbiguousLabel = (sites: Site[], site: Site) => {
  const sitesWithNew = [...sites, site];
  const displaySite = getDisplaySiteFn(getSitesDictionary(sitesWithNew));
  return R.any(
    (s) => s.name !== site.name && displaySite(s) === displaySite(site),
    sitesWithNew,
  );
};

export const validateSiteName = (name: string): boolean =>
  !!(
    name &&
    siteName(name) &&
    name.length >= MIN_SITE_NAME &&
    name.length <= MAX_SITE_NAME
  );

export const siteHasCurrency = (value: Site): boolean =>
  !!(value?.currency || value?.isGroup);

export const siteHasCulture = (value: Site): boolean =>
  !!(value?.culture || value?.isGroup);

export const isValid = (value: Site, sites: Site[], isNew: boolean): boolean =>
  !!(
    value?.label &&
    validateSiteName(value.name) &&
    (isNew ? !isSiteDuplicated(value.name, sites) : true) &&
    !hasAmbiguousLabel(sites, value) &&
    siteHasCurrency(value) &&
    siteHasCulture(value)
  );

export const getDefaultSite = (context: Context, parent?: Site): Site => ({
  name: undefined,
  label: undefined,
  currency: context.masterCurrency.id,
  isGroup: false,
  parentName: (parent && parent.name) || "all",
  timezone: "Etc/UTC",
  culture: undefined,
});

export const getParentSite = (sites: Site[] = [], value: Site) =>
  value && R.find((c) => c.name === value.parentName, sites);

export const availableParents = (
  isNew: boolean,
  sites: Site[] = [],
  value: Site,
) =>
  isNew
    ? sites.filter((s) => s.isGroup)
    : value
      ? R.difference(
          sites.filter((s) => s.isGroup),
          getSubTree(sites, value),
        )
      : [];

export const isChildSite = (site: Site) => site && !site?.isGroup;
export const isGroupSite = (site: Site) => site?.isGroup;

export const getSiteLabelByName = (sites: Site[] = [], siteName: string) => {
  const site = findSite(siteName, sites);
  return site ? site.label : "";
};
