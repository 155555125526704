import * as R from "ramda";
import { defaultFor, getTrue, toCamelCase } from "common";
import { Properties } from "common/types/records";
import { getDefinitionLabel } from "common/query/common";
// eslint-disable-next-line import/no-cycle
import { TableCell } from "common/query/table/cell";
// eslint-disable-next-line import/no-cycle
import { InnerPropTypes as PropTypes } from "common/query/table/table";
import { TableValue } from "common/query/table/types";
import { ModifyButtonSmall } from "common/ui/buttons";
import { createList } from "common/widgets/list";
import { OnChange, ValueComponent } from "common/with-value-for";

export const List = createList<Properties>("ReorderTableList");
const defaulValue = defaultFor<TableValue>();

/** A table where you can reorder rows. Used for ordering tasks for example */
export class ReorderTable extends ValueComponent<TableValue, PropTypes> {
  static readonly displayName = "ReorderTable";

  onChangeCell = (index: number, valueKey: string) => {
    return (properties: Properties) => {
      const { value } = this.props;
      const data = value.data.map((r, i) => {
        return i === index
          ? {
              ...r,
              properties: { ...r.properties, [valueKey]: properties[valueKey] },
            }
          : r;
      });
      this.mergeValue({ data });
    };
  };

  onDisplay = (record: Properties, _: OnChange<Properties>, index: number) => {
    const {
      context,
      withLinks,
      readOnly,
      columnDefinitions,
      widgetsMapper,
      value = defaulValue,
    } = this.props;

    const widgetsMap = widgetsMapper && widgetsMapper(record);

    return (
      <div className="x-reorder-table-item">
        <div className="x-flex x-order-column">{record.order}</div>
        {columnDefinitions
          .filter((def) => def.column.name !== "order")
          .map((def, i) => {
            const valueKey = record[def.valueKey]
              ? def.valueKey
              : toCamelCase(def.valueKey);

            const isDisabled =
              def.column?.quickInput ||
              !!widgetsMap?.[def.column.name]?.quickInputNode;

            return (
              <div className="x-flex" key={i}>
                <TableCell
                  context={context}
                  entity={value.query.entity}
                  recordId={record.id}
                  item={def.item}
                  column={def.column}
                  columnEntity={def.entity}
                  hasEmphasis={def.hasEmphasis}
                  withLinks={withLinks}
                  readOnly={readOnly}
                  isDisabled={isDisabled ? getTrue : undefined}
                  dependencies={
                    def.column?.getDependencies
                      ? def.column.getDependencies(record)
                      : undefined
                  }
                  widgetsMap={widgetsMap}
                  value={record[valueKey]}
                  onChange={this.onChangeCell(index, valueKey)}
                />
              </div>
            );
          })}
      </div>
    );
  };

  onChangeList = (list: Properties[]) => {
    const data = list.map((p, index) => {
      return R.mergeRight(p, { order: index + 1 });
    });
    this.mergeValue({ data });
  };

  render() {
    const {
      context,
      toggleOrder,
      columnDefinitions,
      value = defaulValue,
    } = this.props;
    const { data = [] } = value;

    if (!data.length) {
      return <div>{_("No results")}</div>;
    }
    return (
      <div className="x-reorder-table-list">
        <div className="x-reorder-table-header">
          <div className="x-reorder-table-header-content">
            <ModifyButtonSmall onClick={toggleOrder} title={_("Done")}>
              <i className="fa fa-check" />
            </ModifyButtonSmall>
            {columnDefinitions
              .filter((def) => def.column.name !== "order")
              .map((def, i) => {
                const label = getDefinitionLabel(
                  context.entities,
                  value.query.entity,
                  def,
                );
                return (
                  <div className="x-flex" key={i}>
                    <div className="x-ellipsis">{label}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <List
          onDisplay={this.onDisplay}
          small={true}
          hideOverflow={true}
          withBorder={true}
          value={data}
          onChange={this.onChangeList}
        />
      </div>
    );
  }
}
