import * as R from "ramda";
import type { Context } from "common/types/context";
import { Component } from "common/component";
import {
  getActionHandlersByBehaviors,
  ActionHandlerValue,
} from "common/record/actions/handlers";
import type { PropTypes as ActionPropTypes } from "common/record/actions/types";
import type { ComponentWithValue } from "common/with-value";
import { ActionButton } from "common/ui/buttons";
import type { Record } from "common/types/records";
import { trackEvent } from "common/utils/mixpanel";

const availableActions: ReadonlyArray<string> = ["Open", "ReOpen", "Close"];

const getAction = R.compose<
  ReadonlyArray<string>,
  ReadonlyArray<string>,
  string
>(R.head, R.intersection(availableActions));

const getActionLabel = (action: string) => {
  switch (action) {
    case "Open":
      return _("Open Work Order");
    case "ReOpen":
      return _("Reopen Work Order");
    case "Close":
      return _("Close Work Order");
    default:
      return action;
  }
};

interface PropTypes {
  context: Context;
  workOrder: Record;
  entityName: string;
  isPlanner: boolean;
  reload: () => void;
}

interface StateType {
  showAction: boolean;
  action: string;
  ActionUi: ComponentWithValue<ActionHandlerValue, ActionPropTypes>;
}

export class ButtonsSection extends Component<PropTypes, StateType> {
  static readonly displayName = "ButtonsSection";

  constructor(props: PropTypes) {
    super(props);
    this.state = this.getActionState(props);
  }

  componentDidUpdate(prevProps: PropTypes) {
    const { workOrder } = prevProps;
    const { workOrder: newWorkOrder } = this.props;
    if (getAction(newWorkOrder.actions) !== getAction(workOrder.actions)) {
      this.setState(this.getActionState(this.props));
    }
  }

  getActionState = (props: PropTypes): StateType => {
    const { workOrder, entityName, context } = props;
    const entity = context.entities[entityName];
    const action = getAction(workOrder.actions);
    return action
      ? {
          action,
          ActionUi: getActionHandlersByBehaviors(entity.behaviors)[action].Ui,
          showAction: false,
        }
      : {
          action: undefined,
          ActionUi: undefined,
          showAction: false,
        };
  };

  onDismiss = () => {
    const { action } = this.state;
    const { workOrder } = this.props;

    trackEvent("Scheduler Work Order Action Performed", { action, workOrder });

    this.setState({
      showAction: false,
    });

    this.props.reload();
  };

  getActionUi = () => {
    const { workOrder, context, entityName } = this.props;
    const { ActionUi, showAction } = this.state;
    const { entities } = context;
    const entity = entities[entityName];

    return showAction && entity && workOrder ? (
      <ActionUi
        goTo={undefined}
        query={undefined}
        context={context}
        dismiss={this.onDismiss}
        entity={entity}
        records={[workOrder]}
        value={undefined}
        onChange={undefined}
      />
    ) : undefined;
  };

  render() {
    const { action } = this.state;
    const { isPlanner } = this.props;

    return !isPlanner && action ? (
      <div className="x-scheduler-info-buttons">
        <ActionButton
          onClick={this.onChangeMergeDefaultState("showAction", true)}
        >
          {getActionLabel(action)}
        </ActionButton>
        {this.getActionUi()}
      </div>
    ) : null;
  }
}
