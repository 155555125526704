import { isValidElement, JSX } from "react";
import { ITooltip } from "react-tooltip";
import { jsxToString } from "common/utils/jsx";

type Props = Pick<
  ITooltip,
  | "border"
  | "content"
  | "delayHide"
  | "delayShow"
  | "float"
  | "hidden"
  | "offset"
  | "place"
  | "positionStrategy"
  | "wrapper"
>;

type DataPropsDictionaryType = {
  [name in keyof Props]: string;
};

const dataPropsDictionary: DataPropsDictionaryType = {
  border: "data-tooltip-border",
  content: "data-tooltip-content",
  delayHide: "data-tooltip-delay-hide",
  delayShow: "data-tooltip-delay-show",
  float: "data-tooltip-float",
  hidden: "data-tooltip-hidden",
  offset: "data-tooltip-offset",
  place: "data-tooltip-place",
  positionStrategy: "data-tooltip-position-strategy",
  wrapper: "data-tooltip-wrapper",
};

const propsToData = (props: Props = {}) =>
  Object.keys(props).reduce(
    (acc, name: keyof Props) => ({
      ...acc,
      [dataPropsDictionary[name]]: props[name],
    }),
    {},
  );

const jsxDefaultProps = (content: JSX.Element) => ({
  "data-tooltip-html": jsxToString(content),
});
const stringDefaultProps = (content: string) => ({
  "data-tooltip-content": content,
});

export const getTooltipProps = (
  content: string | JSX.Element,
  variant: ITooltip["variant"] = "dark",
  props?: Props,
) => ({
  "data-tooltip-id": "x5-tooltip",
  "data-tooltip-variant": variant,
  ...(isValidElement(content)
    ? jsxDefaultProps(content)
    : stringDefaultProps(content as string)),
  ...propsToData(props),
});
