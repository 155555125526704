import { Component } from "react";
import { v4 as uuid } from "uuid";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { getByBehaviorArgument } from "common/entities";
import { Entity } from "common/entities/types";
import { getFkId } from "common/functions/foreign-key";
import { merge2 } from "common/merge";
import { getSelectFieldByTitle } from "common/query/select";
import { TableValue } from "common/query/table/types";
import { QueryForEntity } from "common/query/types";
import { getPropertiesWithTemporaryIds } from "common/record/edit/value";
import { RelatedValue } from "common/record/form/content/related/types";
import { DetailUiValue } from "common/record/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import {
  Properties,
  Record,
  RecordPayload,
  RelatedPayload,
} from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { ActionButton } from "common/ui/buttons";
import { FullPageModal } from "common/widgets/full-page-modal";
import { Hint } from "common/widgets/hint";
import { LazyAdvancedSearchContent } from "common/widgets/record-selector/advanced-search/modal";
import { Required } from "common/widgets/required";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { ValueProps } from "common/with-value-for";
import {
  extractCommonColumns,
  getDefaultQueryForEntity,
  mapAssetsToRecords,
} from "./functions";

interface PropTypes extends ValueProps<RelatedValue> {
  context: Context;
  entity: Entity;
  assetsEntity: Entity;
  taskEntity: Entity;
  recordDetail: DetailUiValue;
}

interface StateType {
  showModal: boolean;
  assetsTable: TableValue;
  taskOptions: ForeignKey[];
  task: ForeignKey;
  error?: any;
}

export class TaskProcedures extends Component<PropTypes, StateType> {
  state: StateType = {
    showModal: undefined,
    assetsTable: undefined,
    task: undefined,
    taskOptions: [],
    error: undefined,
  };
  fetchTaskOptionsRequest: CancellablePromise<unknown>;

  componentDidMount() {
    const { context, taskEntity } = this.props;
    const { name, query } = taskEntity;

    this.fetchTaskOptionsRequest = searchApi(context.apiCall)
      .runQueryFkExpansion({ entity: name, query })
      .then((records: ForeignKey[]) => this.setState({ taskOptions: records }))
      .catch((error) => this.setState({ error }));
  }

  componentWillUnmount() {
    this.fetchTaskOptionsRequest?.cancel();
  }

  onChangeItem = (assetsTable: TableValue) => {
    this.setState({ assetsTable });
  };

  onAddTaskProcedures = () => {
    this.setState({ showModal: true });
  };

  mapProperties = (
    properties: Properties,
    order: number,
    assetId?: ForeignKey,
  ): RecordPayload => {
    const { context, entity } = this.props;
    const { procedureTypeId, c_step, step, meterId } = properties;

    const columnsToCopy = extractCommonColumns(context, entity, properties);
    const newProperties = getPropertiesWithTemporaryIds(entity, columnsToCopy);

    return {
      properties: {
        ...newProperties,
        assetId,
        order,
        procedureTypeId,
        meterId,
        step: c_step || step,
        tempId: uuid(),
      },
      deleted: false,
    };
  };

  getAssetsToInsert = (workOrderAssetEntity: Entity) => {
    const { recordDetail, value, assetsEntity } = this.props;
    const { assetsTable } = this.state;
    const { record, related } = value;

    const mainAsset = recordDetail?.form?.assetId || record.properties.assetId;
    const existingWorkOrderAssets =
      related.form?.[workOrderAssetEntity.name] || [];
    const newWorkOrderAssets =
      record.related?.[workOrderAssetEntity.name] || [];

    const assets = [mainAsset]
      .concat(existingWorkOrderAssets.map((x) => x.properties.assetId))
      .concat(newWorkOrderAssets.map((x) => x.properties.assetId))
      .filter((a) => !!a);

    const selectedAssets = assetsTable?.selected || [];
    const assetsToInsert = mapAssetsToRecords(
      selectedAssets.filter(
        (selectedAsset) =>
          !assets.some((asset) => getFkId(asset) === selectedAsset.id),
      ),
      assetsEntity,
    );

    return assetsToInsert.concat(existingWorkOrderAssets);
  };

  mapTaskProcedures = (
    procedure: Record,
    existingProceduresLength: number,
    newProceduresLength: number,
  ): RecordPayload[] | RecordPayload => {
    const { assetsEntity } = this.props;
    const { assetsTable } = this.state;
    const { properties } = procedure;
    const { order } = properties;

    const selectedAssets = assetsTable?.selected || [];
    if (!selectedAssets.length)
      return this.mapProperties(properties, order + existingProceduresLength);

    return selectedAssets.map((asset, index) => {
      const { id, number } = asset;
      const titleColumn = getSelectFieldByTitle(assetsEntity.query.select).name;
      const newOrder =
        order + newProceduresLength * index + existingProceduresLength;

      const assetId: ForeignKey = { id, number, title: asset?.[titleColumn] };

      return this.mapProperties(properties, newOrder, assetId);
    });
  };

  onModifyButtonClick = () => {
    const { context, entity, taskEntity, value, onChange } = this.props;
    const { task } = this.state;
    const { related } = value;

    const woTaskProceduresEntity = Object.values(context.entities).find(
      (e) =>
        e.arguments.ownerEntity === taskEntity.name &&
        e.arguments.targetEntity === entity.name,
    );

    const workOrderAssetEntity = getByBehaviorArgument(
      context.entities,
      "WorkOrderAsset",
      "workOrderEntity",
      entity.arguments?.ownerEntity,
    );

    recordsApi(context.apiCall)
      .get(taskEntity.name, task.id, true)
      .then((task) => {
        const existingProcedures = related.form?.[entity.name] || [];
        const taskProcedures =
          task.related?.[woTaskProceduresEntity.name] || [];

        if (taskProcedures.length) {
          const procedures: RecordPayload[] = taskProcedures.flatMap((t) =>
            this.mapTaskProcedures(
              t,
              existingProcedures.length,
              taskProcedures.length,
            ),
          );

          const assetsToInsert = this.getAssetsToInsert(workOrderAssetEntity);
          const newForm: RelatedPayload = {
            ...value.related.form,
            [entity.name]: existingProcedures.concat(procedures),
            [workOrderAssetEntity.name]: assetsToInsert,
          };

          const newValue = merge2("related", "form", newForm, value);

          const newValueIsDirty = merge2("related", "isDirty", true, newValue);
          onChange(newValueIsDirty);
        }

        this.onCloseButtonClick();
      })
      .catch((error) => this.setState({ error }));
  };

  onCloseButtonClick = () => {
    this.setState({
      showModal: false,
      task: undefined,
      assetsTable: undefined,
    });
  };

  onChangeTask = (task: ForeignKey) => {
    this.setState({ task });
  };

  runQuery = (query: QueryForEntity) => {
    const { context } = this.props;
    return searchApi(context.apiCall).runQueryFkExpansion(query);
  };

  render() {
    const { context, entity, taskEntity, assetsEntity } = this.props;
    const { showModal, taskOptions, task, error, assetsTable } = this.state;

    const { name, localizedName } = entity;
    const { name: assetsName, localizedName: assetsLabel } = assetsEntity;

    const newQuery =
      assetsTable?.query || getDefaultQueryForEntity(context, assetsEntity);
    return (
      <div className="x-procedures-custom qa-x-procedures-custom">
        {error ? (
          <ApiError className="x-margin-bottom-10-i" error={error} />
        ) : undefined}
        <ActionButton
          className="qa-btn-add-task-records"
          onClick={this.onAddTaskProcedures}
        >
          <i className="fa fa-plus x-padding-right-5" />
          {_("Add Records from Task")}
        </ActionButton>
        <Hint
          message={_(
            "Select a task to import procedures (optionally you can also associate them with specific assets)",
          )}
        />
        {showModal ? (
          <FullPageModal
            title={localizedName || name}
            modifyButtonTitle={_("Add")}
            closeButtonTitle={_("Close")}
            modifyButtonClick={task?.id ? this.onModifyButtonClick : undefined}
            closeButtonClick={this.onCloseButtonClick}
          >
            <div className="x-task-content qa-task-content">
              <Required value={taskOptions?.length && task}>
                <ForeignKeySelector
                  className="x-task-procedures qa-task-procedures"
                  placeholder={`${_("Type to search")}...`}
                  disabled={!taskOptions?.length}
                  context={context}
                  entity={taskEntity}
                  options={taskOptions}
                  value={task}
                  onChange={this.onChangeTask}
                />
              </Required>
              <Hint
                message={_(
                  "Select a task to import procedures and associate them to zero, one or many assets. All of the task procedures will be copied over per asset.",
                )}
              />
            </div>
            <div className="x-assets-content qa-assets-content">
              <h2>{assetsLabel || assetsName}</h2>
              <LazyAdvancedSearchContent
                runQuery={this.runQuery}
                query={newQuery}
                table={assetsTable}
                context={context}
                withLinks={true}
                allowMultipleSelect={true}
                onChange={this.onChangeItem}
                onSelect={undefined}
              />
            </div>
          </FullPageModal>
        ) : undefined}
      </div>
    );
  }
}
