import debounce from "lodash-es/debounce";
import { isMobileDevice } from "common/utils/device";
import { ActionSignature, LayoutActionName } from "common/form/types";
import { VerticalField } from "common/ui/field";
import { DateTime } from "common/widgets/date/date-time";
import { Pagination } from "common/widgets/pagination";
import { Required } from "common/widgets/required";
import { Signature } from "common/widgets/signature";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { getLocalizedName } from "common/index";
import { AlertInfo } from "common/widgets/alert";
import { isValidRecord } from "./functions";
import { RecordsSidebar } from "./records-sidebar";
// eslint-disable-next-line import/no-cycle
import { WorkOrderActionFormTabs } from "./tabs";
import {
  actionsWithDateApplicable,
  FormPropTypes,
  FormStateType,
  FormValue,
} from "./types";

const getRecordHeader = (recordNumber: number, className: string = "") => (
  <div className={`x-wo-action-recordNumber ${className}`}>
    {`${_("Record number")}: ${recordNumber}`}
  </div>
);

interface PropTypes extends FormPropTypes {
  totalRecords: number;
  signatureSetting: ActionSignature;
  actionName: LayoutActionName;
}

type Props = ValueProps<FormValue> & PropTypes;

export class WorkOrderRecordActionForm extends ValueComponent<
  FormValue,
  PropTypes,
  FormStateType
> {
  static readonly displayName = "WorkOrderRecordActionForm";

  constructor(props: Props) {
    super(props);
    this.state = {
      isMobile: isMobileDevice(),
      debounceResize: debounce(this.setIsMobile, 100),
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    const { debounceResize } = this.state;
    if (debounceResize) debounceResize();
  };

  setIsMobile = () => {
    const { isMobile } = this.state;
    const newIsMobile = isMobileDevice();
    if (isMobile !== newIsMobile) {
      this.setState({ isMobile: newIsMobile });
    }
  };

  componentWillUnmount() {
    const { debounceResize } = this.state;
    if (debounceResize) debounceResize.cancel();
    window.removeEventListener("resize", this.resize);
  }

  showError = (index: number) => {
    const { context, entity, actionName, value } = this.props;
    const { actionRecords } = value;
    return !isValidRecord(context, entity, actionName, actionRecords[index]);
  };

  render() {
    const {
      context,
      entity,
      actionName,
      totalRecords,
      signatureSetting = "disabled",
      value,
    } = this.props;
    const { date, signature, actionRecords = [], page } = value;
    const { isMobile } = this.state;

    const totalItems = actionRecords.length;
    const recordNumber = totalItems >= 1 && actionRecords[page].recordNumber;
    const containerClass =
      !!totalItems && totalItems > 1
        ? isMobile
          ? "x-wo-action-with-pagination"
          : "x-wo-action-with-sidebar"
        : "x-margin-top-20";
    const swoEntity = context.entities[entity.arguments.scheduledEventEntity];
    const isCloseAction = actionName === "closeOut";

    return (
      <>
        {isCloseAction ? (
          <AlertInfo
            message={_(
              "If the {WORK_ORDER_ENTITY} record was generated by Preventive Maintenance {SWO_ENTITY}, the 'Last Close Date' will be updated to today's date.",
            )
              .replace("{WORK_ORDER_ENTITY}", getLocalizedName(entity))
              .replace(
                "{SWO_ENTITY}",
                getLocalizedName(swoEntity) ?? _("Scheduled Work Orders"),
              )}
          />
        ) : undefined}
        {actionsWithDateApplicable.indexOf(actionName) > -1 ? (
          <VerticalField
            label={_("Close Out date")}
            input={
              <Required value={date}>
                <DateTime
                  uiFormat={context.uiFormat}
                  value={date}
                  onChange={this.onChangeMergeValue("date")}
                />
              </Required>
            }
          />
        ) : undefined}
        {!!totalItems && (
          <div className={containerClass}>
            {totalItems > 1 && (
              <>
                {isMobile ? (
                  <>
                    <div className="x-wo-action-pagination x-flex-center-center">
                      <Pagination
                        itemsPerPage={1}
                        totalItems={totalItems}
                        showError={this.showError}
                        refresh={actionRecords}
                        value={page}
                        onChange={this.onChangeMergeValue("page")}
                      />
                    </div>
                    {getRecordHeader(recordNumber)}
                  </>
                ) : (
                  <RecordsSidebar
                    records={actionRecords}
                    showError={this.showError}
                    value={page}
                    onChange={this.onChangeMergeValue("page")}
                  />
                )}
              </>
            )}
            {totalItems === 1 &&
              totalRecords > 1 &&
              getRecordHeader(recordNumber, "x-padding-top-10")}
            <WorkOrderActionFormTabs
              context={context}
              entity={entity}
              actionName={actionName}
              value={value}
              onChange={this.onChangeSetValue}
            />
          </div>
        )}
        {signatureSetting !== "disabled" && (
          <VerticalField
            className="x-margin-top-20"
            label={_("Signature")}
            error={signatureSetting === "required" && !signature}
            input={
              <Signature
                isSigned={false}
                value={signature}
                onChange={this.onChangeMergeValue("signature")}
              />
            }
          />
        )}
      </>
    );
  }
}
