import { Context } from "common/types/context";
import {
  PartCountSubmittedDetails,
  PartCountSubmittedEvent,
} from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { EmptyDetails } from "common/record/form/content/audit-trail/event-detail/events/empty";
import { defaultFor } from "common/index";
import { PartCountProperties } from "common/types/cycle-count";
import { LabelWidget } from "common/form/widget/label-widget";
import { getPayloadColumn } from "common/record/form/content/audit-trail/event-detail/events/payload/properties/functions";
import { Entity } from "common/entities/types";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { behaveAs, findColumn, getRelatedEntities } from "common/entities";
import { Row } from "../row";

interface PropTypes {
  context: Context;
  entity: Entity;
  event: PartCountSubmittedEvent;
  hideLinks: boolean;
}

export const PartCountSubmitted = ({
  context,
  entity,
  event,
  hideLinks,
}: PropTypes) => {
  const details = event?.details ?? defaultFor<PartCountSubmittedDetails>();
  const { cost, adjustmentType, onHand, partId, partLocationId } =
    details.properties ?? defaultFor<PartCountProperties>();
  const adjustmentTypeColumn = getPayloadColumn(
    context.entities,
    entity,
    "adjustmentType",
  );
  const partsToBeCountedEntity = getRelatedEntities(
    entity,
    context.entities,
  ).find((entity) => behaveAs("PartsToBeCounted", entity));

  const partsColumn = findColumn(partsToBeCountedEntity?.columns, "partId");
  const partLocationColumn = findColumn(
    partsToBeCountedEntity?.columns,
    "partLocationId",
  );

  return (
    <>
      <EmptyDetails event={event} uiFormat={context.uiFormat} />
      <hr />
      <legend>{_("Details")}</legend>
      <Row label={getLocalizedColumnName(partsToBeCountedEntity, "partId")}>
        <LabelWidget
          context={context}
          entityName={partsToBeCountedEntity?.name}
          column={partsColumn}
          withLinks={!hideLinks}
          value={partId}
        />
      </Row>
      <Row
        label={getLocalizedColumnName(partsToBeCountedEntity, "partLocationId")}
      >
        <LabelWidget
          context={context}
          entityName={partsToBeCountedEntity?.name}
          column={partLocationColumn}
          withLinks={!hideLinks}
          value={partLocationId}
        />
      </Row>
      <Row label={_("Adjustment Type")}>
        <LabelWidget
          context={context}
          entityName={adjustmentType?.entity}
          column={adjustmentTypeColumn}
          withLinks={!hideLinks}
          value={adjustmentType}
        />
      </Row>
      {cost ? <Row label={_("Cost")}>{cost}</Row> : undefined}
      <Row label={_("On Hand")}>{onHand}</Row>
      {details.updatedSyncDate ? (
        <>
          <hr />
          <Row label={_("Updated Sync Date")}>
            <DateTimeLabel
              uiFormat={context.uiFormat}
              value={details.updatedSyncDate}
            />
          </Row>
        </>
      ) : undefined}
    </>
  );
};
