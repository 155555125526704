import { Component } from "react";
import { toKebabCase } from "common";
import { Context } from "common/types/context";
import { getLabelWithHighlight, Highlight } from "common/widgets/highlight";
import { SearchRecord } from "./types";
import { getEntityIcon, getEntitySiteLabel } from "./functions";

interface PropTypes {
  context: Context;
  record: SearchRecord;
  textToHighlight: string;
  onClickRecord: () => void;
}

export class SearchedListItem extends Component<PropTypes> {
  static readonly displayName = "SearchedListItem";

  onClick = () => this.props.onClickRecord();

  render() {
    const { context, record, textToHighlight } = this.props;
    const { site, parentEntity, entity, id, title } = record;
    const hrefSite = site ?? context.site.name;
    const hrefEntity = parentEntity ?? entity;
    const href = `/#/${hrefSite}/${hrefEntity}/${id}`;

    return (
      <a
        className={`x-search-item qa-item-${toKebabCase(title)}`}
        onClick={this.onClick}
        href={href}
      >
        <i
          className={`fa fa-lg fa-fw ${getEntityIcon(
            entity,
            context,
          )} qa-item-icon`}
        />
        <div className="x-search-item-title qa-item-title">
          {textToHighlight ? (
            <Highlight
              text={getLabelWithHighlight(String(title), textToHighlight)}
            />
          ) : (
            title
          )}
        </div>
        <div className="x-search-item-entity-label qa-item-entity-label">
          {getEntitySiteLabel(record, context)}
        </div>
      </a>
    );
  }
}
