import * as R from "ramda";
import { bulkUpdateApi } from "common/api/entity-bulk-update";
import { Record } from "common/types/records";
import { ApiCall } from "common/types/api";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { getProtectedColumnNames } from "common/record/utils";
import { BulkUpdate } from "common/types/entity-bulk-update";
import { ValueComponent } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { UpdateListForm } from "./form";
// eslint-disable-next-line import/no-cycle
import { isValid } from "./functions";
// eslint-disable-next-line import/no-cycle
import { Row } from "./row";

export interface UpdateListValue {
  updateListForm: Row[];
}

const mapPayload = (records: Record[], value: Row[]): BulkUpdate => {
  const properties = value.map((field) => ({ [field.name]: field.value }));

  return {
    recordIds: records.map((r) => r.properties.id),
    recordPayload: { properties: R.mergeAll(properties) },
  };
};

export class UpdateListModal extends ValueComponent<
  UpdateListValue,
  PropTypes
> {
  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { entity, records, value } = this.props;
    const { updateListForm = [] } = value;
    return bulkUpdateApi(apiCall)
      .bulkUpdate(entity.name, mapPayload(records, updateListForm))
      .then(() => dismiss(true));
  };

  requiresAuthentication = (updateListForm: Row[]) => {
    const protectedColNames = getProtectedColumnNames(this.props.entity);
    return updateListForm.some((c) => protectedColNames.includes(c.name));
  };

  render() {
    const {
      context,
      entity,
      records,
      dismiss,
      value = {} as UpdateListValue,
    } = this.props;
    const { updateListForm = [] } = value;

    return (
      <Action
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Mass change values of")}
        size="large"
        btnLabel={_("Update")}
        onOk={isValid(updateListForm) ? this.onOk : undefined}
        requiresAuthentication={this.requiresAuthentication(updateListForm)}
      >
        <UpdateListForm
          context={context}
          entity={entity}
          value={updateListForm}
          onChange={this.onChangeMergeValue("updateListForm")}
        />
      </Action>
    );
  }
}
