import { Context } from "common/types/context";
import { Component } from "common/component";
import { RunQuery, QueryForEntity } from "common/query/types";
import { Output } from "common/data/types";
import { QueryToValue } from "common/data/query";

export interface PropTypes {
  context: Context;
  runQuery: RunQuery;
  query: QueryForEntity;
  output: Output;
  withLinks: boolean;
  className?: string;
}

export class Preview extends Component<PropTypes> {
  render() {
    const {
      context,
      runQuery,
      query,
      output,
      withLinks,
      className = "",
    } = this.props;

    return (
      <div className={`x-report-preview ${className}`}>
        <QueryToValue
          context={context}
          goTo={undefined}
          runQuery={runQuery}
          query={query}
          notOlderThan={undefined}
          output={output}
          withLinks={withLinks}
          reload={undefined}
        />
      </div>
    );
  }
}
