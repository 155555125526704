import { Component } from "react";
import { getIdProps } from "common/app/router";
import { GetCached, getCached } from "common/cache";
import { canDo } from "common/entities";
import { lazyComponent } from "common/lazy-loading";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { GoFn, QueryString } from "common/types/url";
import { PermissionsError } from "common/widgets/error";
import { NotFound } from "common/widgets/not-found";
import { ValueProps } from "common/with-value-for";
import { LazyAccountSettings } from "./account-settings";
import { AnnouncementsList } from "./announcements/list";
import { DashboardEditController } from "./dashboard/edit-controller";
import { DashboardViewController } from "./dashboard/view-controller";
import { PartsReorderListController } from "./parts-reorder";
import { RecordEditControllerIndex } from "./records/edit-controller";
import { RecordListController } from "./records/list-controller/list-controller";
import { ReportTableController } from "./records/list-controller/report-table-controller";
import { ReportEditController } from "./reports/edit-controller";
import { ReportListController } from "./reports/list-controller";
import { ReportPreviewController } from "./reports/preview-controller";
import { Scheduler2Controller } from "./scheduler2";
import { ProfileController } from "./user-profile/controller";

export interface RoutesValue {
  avatar: string;
  query: QueryForEntity;
  isDirty: boolean;
}

interface PropTypes {
  context: Context;
  page: string;
  id: string;
  extra: string[];
  reloadUi: () => CancellablePromise<any>;
  queryString: QueryString;
  goTo: GoFn;
}

type Props = PropTypes & ValueProps<RoutesValue>;

const LazySchedulerController = lazyComponent(() =>
  import("./scheduler").then((m) => ({ default: m.SchedulerController })),
);

export class UiRoutes extends Component<Props> {
  static readonly displayName = "UiRoutes";
  goTo: GetCached<GoFn>;
  reloadAndGo: GetCached<GoFn>;

  constructor(props: Props) {
    super(props);
    const { goTo } = props;

    this.goTo = getCached<GoFn>((p) => (id) => goTo(p.replace(":id", `${id}`)));
    this.reloadAndGo = getCached<GoFn>(
      (p) => (id) => this.props.reloadUi().then(() => this.goTo(p)(id)),
    );
  }

  onChangeAvatar = (avatar: string) => {
    this.props.onChange({ ...this.props.value, avatar });
  };

  onChangeQuery = (query: QueryForEntity) => {
    this.props.onChange({ ...this.props.value, query });
  };

  setDirty = (isDirty: boolean) => {
    const { value, onChange } = this.props;
    if (value.isDirty !== isDirty) {
      onChange({ ...value, isDirty });
    }
  };

  onRecordSaved = (id: string) => {
    this.reload(id);
  };

  reload = (id: string) => {
    const { context, page, extra, goTo, value, onChange } = this.props;

    onChange({ ...value, isDirty: false });

    const site = context.site.name;
    const isAdmin = page === "admin";
    const entityName = isAdmin ? extra[0] : page;
    const entity = context.entities[entityName];
    const prefix = isAdmin ? `/${site}/admin/References` : `/${site}`;

    goTo(
      entity.name === "ScheduledWorkOrders"
        ? `${prefix}/${entity.name}`
        : `${prefix}/${entity.name}/${id}`,
    );
  };

  renderRecords = (
    isAdmin: boolean,
    page: string,
    id: string,
    extra: string[],
  ) => {
    const { context, queryString, goTo } = this.props;
    const site = context.site.name;
    const entity = context.entities[page];

    const redirectUrl = isAdmin
      ? entity && entity.name
        ? `/${site}/admin/References/${entity.name}`
        : `/${site}/admin/References`
      : entity && entity.name
        ? `/${site}/${entity.name}`
        : `/${site}/dashboard`;

    if (id === "nopermissions") {
      return <PermissionsError />;
    }
    if (id === "notfound") {
      return <NotFound url={redirectUrl} />;
    }
    if (!entity) {
      const { url = redirectUrl } = queryString;
      return <NotFound url={url} />;
    }

    const { isNew, stringId } = getIdProps(id);

    if (isNew && !canDo(entity, "Create")) return <PermissionsError />;

    if (isNew || stringId) {
      const { defaultProperties, hidePanels, scheduledAssetIds, auditTrailId } =
        queryString;
      return (
        <RecordEditControllerIndex
          key={`${site}/${entity.name}/${id}`}
          context={context}
          goTo={goTo}
          entity={entity}
          id={stringId}
          isNew={isNew}
          formId={isNew && extra[0] ? parseInt(extra[0], 10) : undefined}
          withLinks={true}
          eventId={undefined}
          auditTrailId={auditTrailId}
          defaultProperties={defaultProperties}
          hidePanels={hidePanels}
          scheduledAssetIds={scheduledAssetIds}
          onHasChanged={this.setDirty}
          onSave={this.onRecordSaved}
          onDelete={this.goTo(redirectUrl)}
          onCancel={this.goTo(redirectUrl)}
          onNotFound={this.goTo(`/${site}/notfound?url=/${redirectUrl}`)}
        />
      );
    }

    const { listFilters } = queryString;

    return (
      <RecordListController
        key={`${site}/${entity.name}`}
        withLinks={true}
        context={context}
        entity={entity}
        report={undefined}
        listFilter={listFilters}
        onChangeQuery={this.onChangeQuery}
        goTo={goTo}
      />
    );
  };

  render() {
    const { context, reloadUi, queryString, goTo, page, id, extra, value } =
      this.props;
    const { isDirty } = value;
    const { hidePanels, tab } = queryString;

    const site = context.site.name;
    const key = [site, page, id, ...extra].filter((s) => !!s).join("/");

    const { isNew, numberId } = getIdProps(id);
    const isEdit = extra[0] === "edit";

    switch (page || "dashboard") {
      case "profile":
        return (
          <ProfileController
            key={key}
            context={context}
            goBack={this.reloadAndGo(`/${site}/dashboard`)}
            onImageChanged={this.onChangeAvatar}
          />
        );
      case "dashboard":
        if (isNew || (numberId && isEdit)) {
          return (
            <DashboardEditController
              key={key}
              context={context}
              id={numberId}
              isNew={isNew}
              onSave={this.reloadAndGo(`/${site}/dashboard/:id`)}
              onDelete={this.reloadAndGo(`/${site}/dashboard`)}
              onCancel={this.reloadAndGo(`/${site}/dashboard/${numberId}`)}
            />
          );
        }
        return (
          <DashboardViewController
            tab={tab}
            key={key}
            context={context}
            id={numberId}
            hidePanels={hidePanels}
            goTo={goTo}
            onEdit={this.goTo(`/${site}/dashboard/:id/edit`)}
            onNew={this.goTo(`/${site}/dashboard/new`)}
          />
        );
      case "Scheduler":
        // RTS: control at router level
        return (
          <LazySchedulerController
            context={context}
            hidePanels={hidePanels}
            data-qa="scheduler"
          />
        );
      case "Scheduler2":
        return context.isEnabledFeature("scheduler2") ? (
          <Scheduler2Controller context={context} hidePanels={hidePanels} />
        ) : (
          <NotFound url={`#/${site}/dashboard`} />
        );
      case "Reports": {
        if (isNew || numberId) {
          switch (extra[0]) {
            case "Table":
              return (
                <ReportTableController
                  key={key}
                  withLinks={true}
                  goTo={goTo}
                  context={context}
                  reportId={numberId}
                  onChangeQuery={this.onChangeQuery}
                />
              );
            case "preview":
              return (
                <ReportPreviewController
                  context={context}
                  id={numberId}
                  isNew={false}
                  withLinks={true}
                />
              );
          }
          return (
            <ReportEditController
              key={key}
              context={context}
              id={numberId}
              isNew={isNew}
              onSave={this.reloadAndGo(`/${site}/Reports`)}
              onDelete={this.reloadAndGo(`/${site}/Reports`)}
              onCancel={this.goTo(`/${site}/Reports`)}
              onNotFound={this.goTo(`/${site}/notfound?url=/${site}/Reports`)}
            />
          );
        } else {
          return (
            <ReportListController
              key={key}
              excludeGlobal={false}
              context={context}
              newPath={`#/${site}/Reports/new`}
              editPath={`#/${site}/Reports/:id`}
              previewPath={`#/${site}/Reports/:id/preview`}
            />
          );
        }
      }
      case "PartsReorderList": {
        const isFeatureEnabled = context.isEnabledFeature("partsReorderList");

        if (!isFeatureEnabled) return <NotFound url={`#/${site}/dashboard`} />;
        return <PartsReorderListController key={key} context={context} />;
      }
      case "admin": {
        const adminPage = id;
        const adminId = extra[0];
        const extraWithoutAdminId = extra.slice(1);

        if (adminPage === "References" && adminId && adminId !== "new") {
          const [refId, ...refExtra] = extraWithoutAdminId;
          return this.renderRecords(true, adminId, refId, refExtra);
        }

        return (
          <LazyAccountSettings
            key={key}
            context={context}
            page={adminPage}
            id={adminId}
            extra={extraWithoutAdminId}
            isDirty={isDirty}
            setDirty={this.setDirty}
            reloadUi={reloadUi}
            queryString={queryString}
            goTo={goTo}
            onChangeQuery={this.onChangeQuery}
          />
        );
      }
      case "Announcements": {
        return <AnnouncementsList key={key} context={context} />;
      }
      default: {
        return this.renderRecords(false, page, id, extra);
      }
    }
  }
}
