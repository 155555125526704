import { defaultFor, getFalse, getTrue } from "common";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
// eslint-disable-next-line import/no-cycle
import { Groups } from "common/form";
import { setColumnIsValid } from "common/form/functions/validation";
import { FormValidationProps, Group } from "common/form/types";
import { getIntFkId } from "common/functions/system-int";
import { translateDomainLoginType } from "common/functions/system-string-options";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { emailDomain } from "common/validate";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { Hint } from "common/widgets/hint";
import { InputWithSubmit } from "common/widgets/input-with-submit";
import { RadioButtonList } from "common/widgets/radio-button-list";
import { Required } from "common/widgets/required";
import { SiteSelector } from "common/widgets/site-selector";
import { ValueProps } from "common/with-value-for";
import { getSecuritySettingsHint } from "x/account-settings/functions";

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  entity: Entity;
  withLinks: boolean;
  groups: Group[];
  allowDynamicValues: boolean;
}

const LOGIN_TYPE_ID = "loginTypeId";
const DEFAULT_SITE = "defaultSite";
const EMAIL_DOMAIN = "emailDomain";

export const EmailDomainRulesForm = ({
  context,
  entity,
  withLinks,
  formValidation,
  groups,
  allowDynamicValues,
  onFormValidationChange,
  value = defaultFor<Properties>(),
  onChange,
}: PropTypes) => {
  const loginTypeIdColumn = getColumn(entity, LOGIN_TYPE_ID);
  const emailDomainColumn = getColumn(entity, EMAIL_DOMAIN);
  const defaultSiteColumn = getColumn(entity, DEFAULT_SITE);

  const displayLoginType = (item: SystemIntFk) =>
    translateDomainLoginType(item.title);

  const onChangeLoginType = (loginTypeId: SystemIntFk) =>
    onChange({ ...value, loginTypeId });

  const onChangeDefaultSite = (defaultSite: string) =>
    onChange({ ...value, defaultSite });

  const onChangeEmailDomain = (domain: string) => {
    const isValid = emailDomain(domain);
    onFormValidationChange(
      setColumnIsValid(formValidation, emailDomainColumn.name, isValid),
    );
    return onChange({ ...value, emailDomain: domain });
  };

  const loginTypeOptions =
    context.systemTables[loginTypeIdColumn.relatedSystem];

  const optionValue = loginTypeOptions.find(
    (option) => option.id === getIntFkId(value.loginTypeId),
  );

  const widgetsMap = {
    [LOGIN_TYPE_ID]: {
      ...(loginTypeIdColumn
        ? {
            inputNode: (
              <ConditionalRequired
                key={LOGIN_TYPE_ID}
                value={value.loginTypeId}
                isRequired={loginTypeIdColumn.required && !value?.loginTypeId}
              >
                <RadioButtonList
                  size="medium"
                  name={LOGIN_TYPE_ID}
                  display={displayLoginType}
                  items={loginTypeOptions}
                  value={optionValue}
                  onChange={onChangeLoginType}
                  isDisabled={value.id ? getTrue : getFalse}
                />
              </ConditionalRequired>
            ),
          }
        : undefined),
    },
    [DEFAULT_SITE]: {
      ...(defaultSiteColumn
        ? {
            inputNode: (
              <ConditionalRequired
                key={DEFAULT_SITE}
                value={value.defaultSite}
                isRequired={defaultSiteColumn.required && !value.defaultSite}
              >
                <SiteSelector
                  context={context}
                  value={value.defaultSite}
                  includeGroupSites={true}
                  onChange={onChangeDefaultSite}
                />
                <Hint
                  message={getSecuritySettingsHint(
                    context,
                    "When users are auto-created by SSO, you can have their Default Site Permissions determined here based on the user's email domain (i.e. fluke.com). Otherwise, they will receive the default site found in the {SECURITY_SETTINGS}",
                  )}
                />
              </ConditionalRequired>
            ),
          }
        : undefined),
    },
    [EMAIL_DOMAIN]: {
      ...(emailDomainColumn
        ? {
            inputNode: (
              <Required key={EMAIL_DOMAIN} value={value.emailDomain}>
                <InputWithSubmit
                  type="text"
                  onSubmit={undefined}
                  value={value.emailDomain}
                  onChange={onChangeEmailDomain}
                  hasError={
                    value.loginTypeId && !emailDomain(value.emailDomain)
                  }
                  errorMessage={_("Invalid domain")}
                />
              </Required>
            ),
          }
        : undefined),
    },
  };

  return (
    <Groups
      context={context}
      groups={groups}
      entity={entity}
      withLinks={withLinks}
      widgetsMap={widgetsMap}
      allowDynamicValues={allowDynamicValues}
      formValidation={formValidation}
      onFormValidationChange={onFormValidationChange}
      value={value}
      onChange={onChange}
    />
  );
};
