import * as R from "ramda";
import { ApiCall } from "common/types/api";
import { ValueComponent } from "common/with-value-for";
import { WithValue } from "common/with-value";
import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { required } from "common/validate";
import { VerticalField } from "common/ui/field";
import { Signature } from "common/widgets/signature";
import { hasProtectedColumns } from "common/record/utils";
import { PropTypes, DismissAction } from "../types";
import { Action } from "../action";

export interface SignValue {
  image: string;
}

export type Props = PropTypes & WithValue<SignValue>;

interface StateType {
  isDirty: boolean;
  isSigned: boolean;
}

export class Sign extends ValueComponent<SignValue, PropTypes, StateType> {
  static readonly displayName = "Sign";

  constructor(props: Props) {
    super(props);
    this.state = { isDirty: false, isSigned: !!props?.value?.image };
  }

  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const {
      entity,
      records = [],
      value = defaultFor<SignValue>(),
    } = this.props;
    return recordsApi(apiCall)
      .sign(entity && entity.name, R.head(records)?.properties?.id, value.image)
      .then(dismiss);
  };

  onChange = (image: string) => {
    this.mergeValue({ image });
    this.setState({ isDirty: true });
  };

  render() {
    const {
      context,
      entity,
      dismiss,
      records = [],
      value = defaultFor<SignValue>(),
    } = this.props;
    const { isDirty, isSigned } = this.state;
    const isSignatureValid = required(value?.image);

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Sign-off")}
        btnLabel={_("Confirm")}
        onOk={isDirty && isSignatureValid && this.onOk}
      >
        <VerticalField
          label={_("Signature")}
          error={!isSignatureValid}
          input={
            <Signature
              value={value.image}
              isSigned={isSigned}
              onChange={this.onChange}
            />
          }
        />
      </Action>
    );
  }
}
