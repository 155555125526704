import * as R from "ramda";
import { getLocalizedName } from "common";
import { Properties } from "common/types/records";
import { behaveAs, getRelatedEntities } from "common/entities";
import { ColumnTypes, EntityColumn } from "common/entities/entity-column/types";
import { ColumnDefinition } from "common/query/advanced-types";
import { TableConfig } from "common/query/table/types";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { hasPermissionToCreate } from "common/functions/roles";
import {
  PreviewPayload,
  PurchaseOrderItem,
  PurchaseOrderPayload,
} from "./purchase-order/types";

export const getFakeEntityColumns = (context: Context) => {
  // we can't guess the relatedEntity here (if there's more than one entity by behavior)
  // we use the entity name which comes from the expanded FKs
  const columns: EntityColumn[] = [
    {
      name: "partId",
      localizedName: _("Part"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "partDescription",
      localizedName: _("Description"),
      dataType: "string",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "partLocationId",
      localizedName: _("Part Location"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "preferredSupplier",
      localizedName: _("Preferred Supplier"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "onHand",
      localizedName: _("On Hand"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "onOrderQuantity",
      localizedName: _("On Order Quantity"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "reorderPoint",
      localizedName: _("Reorder Point"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "reorderQuantity",
      localizedName: _("Reorder Quantity"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "unitCost",
      localizedName: _("Unit Cost"),
      dataType: "currency",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      getDependencies: (row: Properties): Properties => ({
        currencyId: row.currency,
      }),
    },
    {
      name: "conversionRate",
      localizedName: "Conversion Rate",
      dataType: "ufloat",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "totalCost",
      localizedName: _("Total Cost"),
      dataType: "currency",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
  ];

  const siteColumn: EntityColumn = {
    name: "site",
    dataType: "string",
    columnType: ColumnTypes.System,
    localizedName: _("Site"),
    isSystem: true,
    readOnly: true,
    required: true,
    maxLength: 0,
  };

  return context.site.isGroup ? [siteColumn, ...columns] : columns;
};

export const getReorderListQuery = (context: Context): QueryForEntity => {
  return {
    entity: undefined,
    query: {
      select: getFakeEntityColumns(context).map(({ name }) => ({ name })),
      // filter: [], // TODO next story
    },
  };
};

export const getReorderListColumnDefinitions = (
  context: Context,
): ColumnDefinition[] =>
  getFakeEntityColumns(context).map((column) => ({
    entity: undefined,
    label: getLocalizedName(column),
    column,
    item: { name: column.name },
    valueKey: column.name,
    hasEmphasis: false,
  }));

export const toPurchaseOrderGroups = (
  purchaseOrderItems: PurchaseOrderItem[],
) => R.groupBy((item) => item.purchaseOrderNumber, purchaseOrderItems);

export const toPurchaseOrderPayload = (
  purchaseOrderItems: PurchaseOrderItem[],
  formId: number,
): PurchaseOrderPayload[] => {
  const groups = toPurchaseOrderGroups(purchaseOrderItems);

  return Object.keys(groups).map((poId) => {
    const { supplierId, supplierEntity, purchaseOrderEntity } = groups[poId][0];
    return {
      purchaseOrderNumber: poId,
      supplierId: supplierId.id,
      supplierEntity: supplierEntity,
      purchaseOrderEntity: purchaseOrderEntity,
      formId,
      items: groups[poId].map((poItem) => ({
        partId: poItem.partId.id,
        partLocationId: poItem.partLocationId.id,
        reorderQuantity: poItem.reorderQuantity,
        unitCost: poItem.unitCost,
        stockEntity: poItem.stockEntity,
      })),
    };
  });
};

export const toPreviewPayload = (
  purchaseOrderItems: PurchaseOrderItem[],
): PreviewPayload[] => {
  const groups = toPurchaseOrderGroups(purchaseOrderItems);

  return Object.keys(groups).flatMap((poId) =>
    groups[poId].map((poItem) => ({
      partId: poItem.partId.id,
      partLocationId: poItem.partLocationId.id,
      supplierId: poItem.supplierId.id,
      reorderQuantity: poItem.reorderQuantity,
      unitCost: poItem.unitCost,
    })),
  );
};

export const canCreatePoForRecords = (
  context: Context,
  selected: Properties[],
): boolean => {
  const { entities, userTypes, role } = context;
  return (
    selected?.length > 0 &&
    selected.every((selection) => {
      const purchaseOrderItemEntity = getRelatedEntities(
        entities[selection.purchaseOrderEntity],
        entities,
      ).find((entity) => behaveAs("PurchaseOrderItem", entity))?.name;

      return (
        hasPermissionToCreate(userTypes, role, selection.purchaseOrderEntity) &&
        hasPermissionToCreate(userTypes, role, purchaseOrderItemEntity)
      );
    })
  );
};

export const getReorderListConfig = (
  context: Context,
  data: Properties[],
): TableConfig => {
  return {
    allowFilter: false,
    allowOrder: false,
    allowDrag: false,
    ignore: [],
    allowStar: false,
    allowSelect: canCreatePoForRecords(context, data),
    allowActions: false,
  };
};
