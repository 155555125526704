import { searchApi } from "common/api/search";
import { QueryForEntity } from "common/query/types";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { getFksOrDefault } from "common/utils/foreign-key";
// eslint-disable-next-line import/no-cycle
import { AdvancedSearch } from "common/widgets/record-selector/advanced-search/advanced-search";
import { ValueComponent } from "common/with-value-for";
import { mergeWithDefaultListQuery } from "common/query/default";
import { getAdvancedSearchListQuery } from "common/query/list";
import { AdvancedSearchBaseProps, TabContentProps } from "../types";
import { getQueryFromPreferences } from "../functions";

export class AdvancedSearchCtrl extends ValueComponent<
  ForeignKey[],
  AdvancedSearchBaseProps
> {
  static readonly displayName = "AdvancedSearchCtrl";

  getTabsContent = (): TabContentProps[] => {
    const { entity, mergeQueryWithDefault, context, content = [] } = this.props;
    const { entities } = context;
    const { name: entityName } = entity;

    return content.map((tabContent) => {
      const { queryForEntity } = tabContent;
      const queryFromPref = getQueryFromPreferences(context, entityName);

      const query =
        mergeQueryWithDefault && queryForEntity
          ? mergeWithDefaultListQuery(
              entities,
              entityName,
              queryForEntity.query,
              queryFromPref,
            ).query
          : getAdvancedSearchListQuery(
              entities,
              entities[entityName],
              queryFromPref,
            );

      return { queryForEntity: { entity: entityName, query } };
    });
  };

  selectProperty = (newSelected: Properties[]) => {
    const { context, entity, addToSelect = [] } = this.props;

    getFksOrDefault(context.apiCall, entity, newSelected, addToSelect).then(
      this.setValue,
    );
  };

  runQuery = (query: QueryForEntity) => {
    const { context, recordSites } = this.props;
    return searchApi(context.apiCall).runQueryForLookup(query, recordSites);
  };

  render() {
    const {
      context,
      disabled,
      withLinks,
      mergeQueryWithDefault,
      content,
      allowMultipleSelect,
      approveChange,
      allowAdvancedAdding,
    } = this.props;

    return (
      <AdvancedSearch
        context={context}
        runQuery={this.runQuery}
        content={
          mergeQueryWithDefault || !content.length
            ? this.getTabsContent()
            : content
        }
        disabled={disabled}
        withLinks={withLinks}
        selectProperty={this.selectProperty}
        allowMultipleSelect={allowMultipleSelect}
        approveChange={approveChange}
        allowAdvancedAdding={allowAdvancedAdding}
      />
    );
  }
}
