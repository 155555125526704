import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { HorizontalField } from "common/form/ui";
import { mergeChain } from "common/merge";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import {
  DEFAULT_PROJECTION_FREQUENCY,
  Pm,
  PmFrequency,
  PmRecord,
  PmTrigger,
} from "common/types/scheduled-event";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { InvertedFancyCheckbox } from "common/widgets/fancy-checkbox/inverted";
import { ValueComponent } from "common/with-value-for";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { AssetsList } from "../asset-list";
import { Frequency } from "./frequency";

interface PropTypes {
  context: Context;
  entity: Entity;
  assetEntity: Entity;
  meterEntity: Entity;
  query?: QueryForEntity;
}

const defaultPm = defaultFor<PmTrigger>();

export type TriggerProps = PropTypes;

export class TriggersForm extends ValueComponent<PmRecord, PropTypes> {
  static readonly displayName = "TriggersForm";

  onChangeFrequency = (frequency: PmFrequency) => {
    this.mergeValue3("properties", "trigger", "frequency", frequency);
  };

  onChangeShadow = (isShadow: boolean) => {
    const newValue = mergeChain(this.props.value)
      .prop("properties")
      .set("hasProjections", !isShadow)
      .set(
        "projectionLength",
        !isShadow ? DEFAULT_PROJECTION_FREQUENCY.projectionLength : undefined,
      )
      .set(
        "projectionInterval",
        !isShadow ? DEFAULT_PROJECTION_FREQUENCY.projectionInterval : undefined,
      )
      .prop("trigger")
      .prop("frequency")
      .set("shadow", isShadow)
      .output();
    this.setValue(newValue);
  };

  onEnabledToggleChange = (enabled: boolean) =>
    this.mergeValue2("properties", "enabled", enabled);

  render() {
    const { context, entity, assetEntity, meterEntity, value, query } =
      this.props;
    const { trigger = defaultPm, enabled } =
      value?.properties ?? defaultFor<Pm>();
    const { frequency } = trigger;

    return (
      <div className="x-pm-trigger-event form-horizontal">
        <div className="x-pm-section qa-pm-section">
          <HorizontalField
            className="x-padding-bottom-10"
            label={_("Enabled")}
            disabled={false}
          >
            <YesNo
              name="enabled"
              value={enabled}
              onChange={this.onEnabledToggleChange}
            />
          </HorizontalField>
          <HorizontalField label={_("Calendar Frequency")}>
            <div className="x-pm-trigger-controls">
              <Frequency value={frequency} onChange={this.onChangeFrequency} />
            </div>
          </HorizontalField>
          {trigger?.frequency && (
            <HorizontalField label={_("When to trigger work order")}>
              <fieldset className="x-shadow qa-shadow">
                <div className="x-frequency-shadow">
                  <InvertedFancyCheckbox
                    label={_("Use a fixed frequency")}
                    value={frequency?.shadow}
                    onChange={this.onChangeShadow}
                  />
                  <FancyCheckboxLarge
                    label={_("After previous work order is closed")}
                    value={frequency?.shadow}
                    onChange={this.onChangeShadow}
                  />
                </div>
              </fieldset>
            </HorizontalField>
          )}
        </div>
        <div className="x-pm-section qa-pm-section">
          <HorizontalField label={_("Assets")}>
            <AssetsList
              context={context}
              entity={entity}
              assetEntity={assetEntity}
              meterEntity={meterEntity}
              value={value}
              query={query}
              onChange={this.onChangeSetValue}
            />
          </HorizontalField>
        </div>
      </div>
    );
  }
}
