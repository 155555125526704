import * as R from "ramda";
import { Children, cloneElement, PropsWithChildren } from "react";
import { arrayToString } from "common/utils/array";
import { Required } from "common/widgets/required";
import { getUniqueId, toKebabCase } from "common";

interface HeaderPropTypes {
  title: string;
}
export const Header = ({ title }: HeaderPropTypes) =>
  title ? (
    <div className="x-form-header">
      <header>{title}</header>
    </div>
  ) : null;
Header.displayName = "Header";

// --- Field ---------------------------------------------------------------- //
interface FieldPropTypes {
  label: string;
  error?: boolean;
  disabled?: boolean;
  className?: string; // for qa classes!
  labelBreakpoints?: string;
  valueBreakpoints?: string;
  children?: any;
  inputId?: string;
  withoutDefaultClasses?: boolean;
  omitElementId?: boolean;
}

const defaultFieldClasses = "row form-group x-ui-form-row";
const defaultLabelClasses = "x-group-label";
const defaultValueClasses = "x-group-content x-group-value";
const childProps = (
  element: any,
  disabled: boolean,
  id: string,
  label: string,
) =>
  R.type(element.type) === "Function"
    ? { label: element.props?.label ?? label, disabled, inputId: id }
    : { label: element.props?.label ?? label, disabled };

export const HorizontalField = ({
  label,
  error,
  disabled,
  className = "",
  labelBreakpoints = "col-md-2",
  valueBreakpoints = "col-md-9",
  children,
  inputId,
  omitElementId,
  withoutDefaultClasses = false,
}: FieldPropTypes) => {
  const id = inputId || getUniqueId();
  const childArray = Children.toArray(children).map((c: any) =>
    cloneElement(c, childProps(c, disabled, id, label)),
  );

  const fieldClasses = arrayToString([
    className,
    error ? "x-has-error" : undefined,
    withoutDefaultClasses ? undefined : defaultFieldClasses,
  ]);
  const labelClasses = arrayToString([
    labelBreakpoints,
    withoutDefaultClasses ? undefined : defaultLabelClasses,
  ]);
  const valueClasses = arrayToString([
    valueBreakpoints,
    withoutDefaultClasses ? undefined : defaultValueClasses,
  ]);

  const htmlFor = omitElementId ? undefined : id;

  return (
    <div className={fieldClasses}>
      <label className={labelClasses} htmlFor={htmlFor}>
        {label}
      </label>
      <div className={valueClasses}>{childArray}</div>
    </div>
  );
};
HorizontalField.displayName = "HorizontalField";

// --- Required field ------------------------------------------------------- //
interface RequiredFieldPropTypes extends FieldPropTypes {
  value: any;
}

export const RequiredField = (props: RequiredFieldPropTypes) => (
  <HorizontalField {...props}>
    <Required value={props.value}>{props.children}</Required>
  </HorizontalField>
);

RequiredField.displayName = "RequiredField";

// --- Section -------------------------------------------------------------- //
interface SectionPropTypes {
  showWarning?: boolean;
  legend?: string;
  className?: string;
}

export const Section = ({
  showWarning,
  legend,
  className = "",
  children,
}: PropsWithChildren<SectionPropTypes>) => {
  const classes = arrayToString([
    "x-section",
    legend ? `qa-${toKebabCase(legend)}-section` : undefined,
    className,
  ]);
  const warningIcon = showWarning ? (
    <i className="fa fa-exclamation-circle" aria-hidden="true" />
  ) : undefined;

  return (
    <div className={classes}>
      {legend ? (
        <legend className="x-legend">
          {legend}
          {warningIcon}
        </legend>
      ) : undefined}
      {children}
    </div>
  );
};
Section.displayName = "Section";
