import * as R from "ramda";
import { UTCDateTime } from "common/date-time/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { frequencyOptions } from "common/scheduled-event/functions";
import { Context } from "common/types/context";
import { PmFrequency } from "common/types/scheduled-event";
import { cronToString } from "common/utils/cron";
import { CalendarLabel } from "common/widgets/calendar-selector/label";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { LinkRecord } from "common/widgets/link-record";
import { ForeignKey } from "common/types/foreign-key";

const calendarLabels = (context: Context, calendarIds: number[] = []) =>
  calendarIds.length ? (
    <ul>
      {calendarIds.map((id, i) => (
        <li key={i}>
          <CalendarLabel
            calendars={context.calendars}
            withLinks={true}
            site={context.site.name}
            value={id}
          />
        </li>
      ))}
    </ul>
  ) : undefined;

const nestedPMs = (
  context: Context,
  entity: Entity,
  overriddenBy: ForeignKey[] = [],
) =>
  overriddenBy.length ? (
    <ul>
      {overriddenBy.map((swo, i) => (
        <li key={i}>
          <LinkRecord site={context.site.name} entity={entity} id={swo?.id}>
            {swo?.title ?? swo?.id}
          </LinkRecord>
        </li>
      ))}
    </ul>
  ) : undefined;

const triggerFrequency = (freq: PmFrequency) => {
  const freqKeys = R.keys(freq);

  if (!freqKeys.length) return undefined;

  return freqKeys.map((freqKey) => {
    if (freqKey === "value") {
      return (
        <Row key={freqKey} label={_("Frequency value")}>
          {freq.value}
        </Row>
      );
    }
    if (freqKey === "interval") {
      const interval = R.find(
        (o) => o.value === freq.interval,
        frequencyOptions(),
      );
      return (
        <Row key={freqKey} label={_("Frequency Interval")}>
          {interval?.label}
        </Row>
      );
    }
    if (freqKey === "shadow") {
      return (
        <Row key={freqKey} label={_("When to trigger work order")}>
          {freq.shadow
            ? _("After previous work order is closed")
            : _("Use a fixed frequency")}
        </Row>
      );
    }
    return undefined;
  });
};

const triggerCrons = (crons: string[] = []) => {
  return crons.length ? (
    <Row key="crons" label={_("Schedules")}>
      <ul>
        {crons.map((c, i) => (
          <li key={i}>{cronToString(c)}</li>
        ))}
      </ul>
    </Row>
  ) : undefined;
};

const triggerStartDate = (context: Context, startDate: UTCDateTime) => (
  <Row key="startDate" label={_("Start Date")}>
    <DateTimeLabel value={startDate} uiFormat={context.uiFormat} />
  </Row>
);

const trigger = (context: Context, trigger: any) => {
  const triggerKeys = R.keys(trigger);

  if (!triggerKeys.length) return undefined;

  return (
    <div className="x-inner-object">
      {triggerKeys.map((triggerKey) => {
        switch (triggerKey) {
          case "frequency":
            return triggerFrequency(trigger.frequency);
          case "crons":
            return triggerCrons(trigger.crons);
          case "startDate":
            return triggerStartDate(context, trigger.startDate);
        }
        return undefined;
      })}
    </div>
  );
};

export const getScheduledEventContent = (
  context: Context,
  entity: Entity,
  column: EntityColumn,
  value: any,
) => {
  switch (column?.name) {
    case "calendarIds":
      return calendarLabels(context, value);

    case "overriddenBy":
      return nestedPMs(context, entity, value);

    case "trigger":
      return trigger(context, value);

    default:
      return undefined;
  }
};
