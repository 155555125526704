import * as R from "ramda";
import { Context } from "common/types/context";
import { WorkOrderClosedEvent } from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { collapseReferenceFks } from "common/functions/foreign-key";
import { Entity } from "common/entities/types";
import { Row } from "../row";
import { ReasonDetails } from "./reason";
import { PropertiesPayload } from "./payload/properties";

interface PropTypes {
  event: WorkOrderClosedEvent;
  entity: Entity;
  context: Context;
  hideLinks?: boolean;
}

export const WorkOrderClosed = ({
  event,
  entity,
  context,
  hideLinks,
}: PropTypes) => (
  <div>
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={true}
      title={_("Updated record fields")}
      properties={event?.details?.record?.properties}
    />
    {!R.isNil(event?.details?.record?.properties) &&
    !R.isEmpty(event?.details?.record?.properties) ? (
      <hr />
    ) : undefined}
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={true}
      title={_("Previous record fields")}
      properties={collapseReferenceFks(
        event?.details?.oldProperties,
        context.entities,
      )}
    />
    {!R.isNil(event?.details?.oldProperties) &&
    !R.isEmpty(event?.details?.oldProperties) ? (
      <hr />
    ) : undefined}
    <legend>{_("Details")}</legend>
    <Row label={_("Close Out Date")}>
      <DateTimeLabel
        uiFormat={context.uiFormat}
        value={event.details.closeDate}
      />
    </Row>
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
  </div>
);

WorkOrderClosed.displayName = "WorkOrderClosed";
