import * as R from "ramda";
import { Context } from "common/types/context";
import { StaticEntry } from "common/types/preferences";
import { Link } from "common/widgets/link";
import { getLocalizedName } from "common";
import { Menu } from "./menu";
import { canView, getStaticMenuEntry } from "./functions";

interface PropTypes {
  context: Context;
  location: string;
  explorerEntity: string;
  lastNonAdminPath: string;
  explorerOpen: boolean;
}

const adminEntries = (): StaticEntry[] => [
  {
    name: "Dashboard",
    label: _("Dashboard"),
    icon: "fa-pie-chart",
    requiredFeatures: ["dashboard"],
  },
  {
    name: "Integrations",
    label: _("Integrations"),
    icon: "fa-download",
    requiredFeatures: ["integrations", "teletrac"],
  },
  {
    name: "Rules",
    label: _("Workflows"),
    icon: "fa-bolt",
    requiredFeatures: ["rule"],
  },
  {
    name: "Entities",
    label: _("Entities"),
    icon: "fa-table",
    requiredFeatures: ["entity"],
  },
  {
    name: "Forms",
    label: _("Forms"),
    icon: "fa-table",
    requiredFeatures: ["form"],
  },
  {
    name: "PrintableLabels",
    label: _("Printable Labels"),
    icon: "fa-barcode",
    requiredFeatures: ["printableLabels"],
  },
  { name: "Roles", label: _("Roles"), icon: "fa-group" },
  { name: "Users", label: _("Users"), icon: "fa-user" },
  { name: "Security", label: _("Security"), icon: "fa-lock" },
  { name: "Branding", label: _("Branding"), icon: "fa-tag" },
  { name: "Cultures", label: _("Cultures"), icon: "fa-flag" },
  {
    name: "Sites",
    label: _("Sites"),
    icon: "fa-globe",
    requiredFeatures: ["site"],
  },
  {
    name: "Currencies",
    label: _("Currencies"),
    icon: "fa-usd",
    requiredFeatures: ["site"],
  },
  {
    name: "DataImport",
    label: _("Data Import"),
    icon: "fa-file-excel-o",
    requiredFeatures: ["dataImport"],
  },
  {
    name: "Calendars",
    label: _("Calendars"),
    icon: "fa-calendar",
    requiredFeatures: ["calendar"],
  },
  {
    name: "VisualTemplates",
    label: _("Visual Templates"),
    icon: "fa-th-large",
    requiredFeatures: ["visualTemplates"],
  },
  {
    name: "EmailTemplates",
    label: _("Email Templates"),
    icon: "fa-envelope-o",
    requiredFeatures: ["emailTemplate"],
  },
  {
    name: "ReportSchedules",
    label: _("Scheduled Reports"),
    icon: "fa-calendar-times-o",
    requiredFeatures: ["reportSchedule"],
  },
  { name: "References", label: _("References"), icon: "fa-file" },
  {
    name: "Scheduler",
    label: _("Scheduler"),
    icon: "fa-calendar-o",
    requiredFeatures: ["scheduler"],
  },
  {
    name: "PurchaseOrders",
    label: _("Purchase Orders"),
    icon: "fa-shopping-cart",
    requiredFeatures: ["purchaseOrder"],
  },
  {
    name: "WorkOrders",
    label: _("Work Orders"),
    icon: "fa-wrench",
  },
  {
    name: "ESignature",
    label: _("E-Signature"),
    icon: "fa-pencil",
  },
];

const getEntityAdminEntries = (context: Context): StaticEntry[] => {
  const entities = Object.values(context.entities);
  const adminEntities = entities
    .filter((e) => e.displayAsSystem)
    .map((entity) => ({
      name: entity.name,
      label: getLocalizedName(entity),
      icon: entity.css ?? "fa-file-o",
    }));

  return adminEntities;
};

export const AdminMenu = ({
  context,
  location,
  explorerEntity,
  lastNonAdminPath,
  explorerOpen,
}: PropTypes) => (
  <Menu
    location={location}
    entries={adminEntries()
      .concat(getEntityAdminEntries(context))
      .filter((e) => canView(context, e.name))
      .filter(
        (e) =>
          !e.requiredFeatures ||
          context.anyFeatureEnabled([].concat(e.requiredFeatures)),
      )
      .map(getStaticMenuEntry)
      .map((e) =>
        R.mergeRight(e, {
          link: e.link
            .replace(":site", `${context.site.name}/admin`)
            .replace("/Dashboard", ""),
        }),
      )}
    explorerEntity={explorerEntity}
    explorerOpen={explorerOpen}
  >
    <div className="x-menu-item x-go-back">
      <Link
        className="x-menu-item-link qa-go-back"
        href={`#/${context.site.name}/${lastNonAdminPath || "dashboard"}`}
        title={_("Go back")}
      >
        <i className="fa fa-lg fa-fw fa-arrow-left" />
        <div className="x-menu-item-label">{_("Go back")}</div>
      </Link>
    </div>
  </Menu>
);
