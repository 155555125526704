import { PasswordPolicy } from "common/types/settings";
import { Context } from "common/types/context";
import { Preferences } from "common/types/preferences";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { ValueProps } from "common/with-value-for";
import { Details, RemoveAvatar, UploadAvatar, validDetails } from "./details";
import { PreferencesForm } from "./preferences";
import { UserDetails } from "./types";

export interface UserProfileFormValue {
  details: UserDetails;
  preferences: Preferences;
}

interface PropTypes extends ValueProps<UserProfileFormValue> {
  context: Context;
  uploadAvatar: UploadAvatar;
  removeAvatar: RemoveAvatar;
  passwordPolicy: PasswordPolicy;
}

export const isValid = (
  value: UserProfileFormValue,
  isSystem: boolean,
  passwordPolicy: PasswordPolicy,
): boolean =>
  value && (isSystem || validDetails(value.details, passwordPolicy));

export const UserProfileForm = ({
  context,
  uploadAvatar,
  removeAvatar,
  value,
  onChange,
  passwordPolicy,
}: PropTypes) => {
  const { details, preferences } = value;

  const onChangeUserDetails = (details: UserDetails) => {
    onChange({ ...value, details });
  };

  const onChangePreferences = (preferences: Preferences) => {
    onChange({ ...value, preferences });
  };

  return (
    <Tabs key="user-profile-tabs" className="x-user-profile x-padding-20">
      {!context.isSystem && (
        <Tab value="userDetails" label={_("User details")}>
          <Details
            context={context}
            uploadAvatar={uploadAvatar}
            removeAvatar={removeAvatar}
            cultures={context.cultures}
            passwordPolicy={passwordPolicy}
            value={details}
            onChange={onChangeUserDetails}
          />
        </Tab>
      )}
      <Tab value="preferences" label={_("Preferences")}>
        <PreferencesForm
          context={context}
          value={preferences}
          onChange={onChangePreferences}
        />
      </Tab>
    </Tabs>
  );
};
