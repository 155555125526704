import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import {
  behaveAs,
  filterByBehavior,
  filterChargeEntities,
} from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { getFormWithResolvedDefaults } from "common/form/defaults";
import { FormSelector } from "common/form/form-selector";
import { isFormDirty } from "common/form/functions/validation";
import { FormValidationProps, Layout as FormLayout } from "common/form/types";
import { merge1, merge2, mergeChain } from "common/merge";
import { Content } from "common/record/form/content";
import { generalInfo, Sidebar } from "common/record/sidebar";
import { SidebarElement } from "common/record/sidebar/types";
import { StandardUiValue as UiValue, StandardValue } from "common/record/types";
import { MEDIA_FILES } from "common/record/utils";
import { Context } from "common/types/context";
import { Properties, Record } from "common/types/records";
import { Warning } from "common/widgets/warning";
import { OnConfirmationOpen } from "common/widgets/warning/use-confirmation";
import { WithConfirmation } from "common/widgets/warning/with-confirmation";
import { ValueProps } from "common/with-value-for";
import { filterFormsByEntity, getFormById } from "common/functions/forms";
import { Required } from "common/widgets/required";
import { VerticalField } from "common/ui/field";
import { Form } from "common/types/forms";
import { getWoTemplateStaticEntries } from "./functions";

interface PropTypes extends ValueProps<StandardValue>, FormValidationProps {
  context: Context;
  entity: Entity;
  record: Record;
  woEntity: Entity;
  layout: FormLayout;
  dependencies?: { defaultValue: StandardValue };
}

const defaultValue = defaultFor<StandardValue>();
const defaultUiValue = defaultFor<UiValue>();

export class PmEntityForm extends Component<PropTypes> {
  static readonly displayName = "PmEntityForm";

  componentDidMount() {
    this.checkDefaultValue();
  }

  checkDefaultValue = () => {
    const { value, dependencies, onChange } = this.props;
    if (!value.ui) {
      onChange(dependencies.defaultValue);
    }
  };

  mapSidebarElements = (elements: SidebarElement[]) => {
    const { entity, record } = this.props;

    /*
      This code assumes WO has Attachment behavior added already (true for master and basicmvp)
      @TODO: Refactor SidebarElement to store a tree structure inside SidebarComp
            which will help with menu manipulation and full implementation of this function
            (Add Documents to the Other section even when WO entity doesn't have
            the Attachment behavior but Scheduled WO entity has)
     */

    if (!behaveAs("Attachment", entity) || !record?.properties?.id) {
      return elements.filter((el) => el.label !== MEDIA_FILES);
    }

    return elements.map((el) =>
      el.label === MEDIA_FILES
        ? {
            ...el,
            entity: entity.name,
          }
        : el,
    );
  };

  getDescriptionIfTaskIsSelectedBeforeForm = (
    forms: Form[],
    woEntity: Entity,
    properties: Properties,
    record: Record,
  ) => {
    const woForms = filterFormsByEntity(forms, woEntity?.name);
    const taskDescription = record?.properties?.taskId?.title;
    const isTaskSelectedBeforeForm =
      woForms?.length > 1 && !properties?.formId && !!taskDescription;

    return isTaskSelectedBeforeForm
      ? { description: taskDescription }
      : undefined;
  };
  onWorkOrderFormChange = (formId: number) => {
    // formId changed. we then propagate this to record.properties. if there are defaults, we reset the record to them
    const {
      value,
      record: swoRecord,
      context,
      woEntity,
      onChange,
    } = this.props;
    const { ui, record } = value;

    // we can't use dependencies.defaultValue because the dependency component only runs on mount :-) RTS
    const form = getFormById(context.forms, formId);
    const additionalProperties = {
      formId,
      id: record?.properties?.id,
    };

    // if task is selected before selecting the available multiple form, populate the description from task
    const defaultsFromTask = this.getDescriptionIfTaskIsSelectedBeforeForm(
      context.forms,
      woEntity,
      record?.properties,
      swoRecord,
    );

    const newValue = form?.settings?.defaults
      ? {
          ui,
          record: {
            ...record,
            properties: {
              ...form?.settings?.defaults,
              ...additionalProperties,
              ...defaultsFromTask,
            },
          },
        }
      : merge2("record", "properties", { formId }, this.props.value);

    getFormWithResolvedDefaults(
      context,
      woEntity,
      form,
      newValue,
      additionalProperties,
    ).then((formValue) => {
      const valueWithDefaults = formValue?.record?.properties
        ? merge2(
            "record",
            "properties",
            formValue?.record?.properties,
            newValue,
          )
        : newValue;
      onChange(valueWithDefaults);
    });
  };

  onWorkOrderFormChangeWithConfirm =
    (onOpen: OnConfirmationOpen<number>) => (formId: number) => {
      onOpen().then(() => this.onWorkOrderFormChange(formId));
    };

  onSidebarChange = (sidebar: SidebarElement) => {
    const { value, onChange } = this.props;
    onChange({ ...value, ui: { ...value.ui, sidebar } });
  };

  getFormDefaults = (context: Context, formId: number) => {
    const workOrderForm = getFormById(context.forms, formId);
    return workOrderForm?.settings?.defaults;
  };

  render() {
    const {
      context,
      woEntity,
      record: swoRecord,
      layout,
      formValidation,
      onFormValidationChange,
      value = defaultValue,
      onChange,
    } = this.props;
    const { record, ui = defaultUiValue } = value;
    const { sidebar = generalInfo } = ui;

    const defaultFormValues = this.getFormDefaults(
      context,
      record?.properties?.formId,
    );

    // if task is selected before selecting the available multiple forms, prevent isDirty
    const defaultsFromTask = this.getDescriptionIfTaskIsSelectedBeforeForm(
      context.forms,
      woEntity,
      record?.properties,
      swoRecord,
    );

    const isDirty = isFormDirty(
      { ...defaultFormValues, ...defaultsFromTask },
      undefined,
      record?.properties,
      ["formId", "$id", "id"],
    );

    const content = !ui.sidebar // if no sidebar, inject overview into value
      ? R.mergeRight(value, { ui: R.mergeRight(ui, { sidebar }) })
      : value;

    const filteredEntities = filterChargeEntities(context.entities);

    // TODO: move all the massage of the context into a function
    const filteredForms = context.forms.map((f) => {
      if (!f.settings.relatedEntities) return f;
      const newValue = f.settings.relatedEntities.filter(
        (e) => filteredEntities[e.name],
      );
      return merge2("settings", "relatedEntities", newValue, f);
    });

    const formsForEntity = filteredForms.filter(
      (f) => f.entityName === woEntity.name,
    );

    const layoutWithoutCharges = layout.relatedEntities
      ? merge1(
          "relatedEntities",
          layout.relatedEntities.filter((e) => filteredEntities[e.name]),
          layout,
        )
      : layout;

    const { staticEntries = [] } = layoutWithoutCharges;
    const sidebarLayout = R.mergeRight(layoutWithoutCharges, {
      reports: undefined,
      staticEntries: getWoTemplateStaticEntries(staticEntries),
    });

    const assignmentEntities = filterByBehavior("Assignment", context.entities);

    const newAssignmentEntities = R.mapObjIndexed(
      (entity: Entity) =>
        merge1(
          "columns",
          entity.columns.map((c: EntityColumn) =>
            c.name === "rangeFrom" || c.name === "rangeTo"
              ? merge1("dataType", "dateoffset", c)
              : c,
          ),
          entity,
        ),
      assignmentEntities,
    );

    const newContext = mergeChain(context)
      .setWith("entities", (ent) => R.mergeRight(ent, newAssignmentEntities))
      .output();

    const newRecord: Record = { ...record, related: undefined };
    const formId = record?.properties?.formId;

    return (
      <div className="x-record-layout x-wo-template">
        {formsForEntity.length ? (
          <div className="col-lg-9 x-form-selector-container">
            <VerticalField
              className={"x-form-selector qa-form-selector-label"}
              label={_("Work Order Form")}
              input={
                <WithConfirmation<number>>
                  {({ onOpen, onClose, onConfirm, isOpen }) => {
                    const confirmation = isOpen ? (
                      <Warning
                        title={_("Changes are not saved")}
                        content={_(
                          "On discard all information in the main form will be removed and the default values of the new form will be populated.",
                        )}
                        action1={_("Keep working on this form")}
                        action2={_(
                          "Discard changes and continue to another form",
                        )}
                        onAction1={onClose}
                        onAction2={onConfirm}
                      />
                    ) : undefined;
                    return (
                      <>
                        {confirmation}
                        <Required value={formId}>
                          <FormSelector
                            className="qa-form-selector"
                            forms={formsForEntity}
                            preselectFirstByDefault={false}
                            value={formId}
                            onChange={
                              isDirty
                                ? this.onWorkOrderFormChangeWithConfirm(onOpen)
                                : this.onWorkOrderFormChange
                            }
                          />
                        </Required>
                      </>
                    );
                  }}
                </WithConfirmation>
              }
            />
          </div>
        ) : undefined}
        {!formsForEntity?.length || !!formId ? (
          <div className="x-form-container">
            <div className="col-lg-3 x-sidebar-container">
              <Sidebar
                disabled={false}
                entity={woEntity}
                context={newContext}
                layout={sidebarLayout}
                record={newRecord}
                ui={ui}
                omitAuditTrail={true}
                mapElements={this.mapSidebarElements}
                onChange={this.onSidebarChange}
                value={sidebar}
              />
            </div>

            <div className="col-lg-9 x-content-container">
              <Content
                context={newContext}
                entity={woEntity}
                layout={layoutWithoutCharges}
                withLinks={true}
                auditTrailId={undefined}
                runQuery={undefined}
                reload={undefined}
                saving={false}
                // this makes it so confusing. sometimes we rely on ui, sometimes not. RTS
                updateRecordOnChange={true}
                isTemplate={true}
                allowDynamicValues={true}
                formValidation={formValidation}
                onFormValidationChange={onFormValidationChange}
                value={content}
                onChange={onChange}
              />
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }
}
