import { Context } from "common/types/context";
import { WorkOrderOpenedEvent } from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "../row";
import { ReasonDetails } from "./reason";

interface PropTypes {
  event: WorkOrderOpenedEvent;
  context: Context;
  hideLinks?: boolean;
}

export const WorkOrderOpened = ({ event, context, hideLinks }: PropTypes) => {
  const { uiFormat } = context;
  return (
    <div>
      <legend>{_("Details")}</legend>
      <Row label={_("Scheduled Start Date")}>
        <DateTimeLabel
          uiFormat={uiFormat}
          value={event.details.scheduledStartDate}
        />
      </Row>
      {!!event.details.startDate && (
        <Row label={_("Start Date")}>
          <DateTimeLabel uiFormat={uiFormat} value={event.details.startDate} />
        </Row>
      )}
      <Row label={_("Username")}>{event.userName}</Row>
      <ReasonDetails
        reason={event.details.reason}
        context={context}
        hideLinks={hideLinks}
      />
    </div>
  );
};

WorkOrderOpened.displayName = "WorkOrderOpened";
