import { Component } from "react";
import { CancellablePromise } from "common/types/promises";
import { reportsApi } from "common/api/reports";
import { canAccessReportEntities } from "common/query/common";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Report } from "common/types/reports";
import { GoFn } from "common/types/url";
import { ApiError } from "common/ui/api-error";
import { AlertWarning } from "common/widgets/alert";
import { PermissionsError } from "common/widgets/error";
import { RecordListController } from "x/records/list-controller/list-controller";
import { ContentLoading } from "x/records/list/content-loading";

const PERMISSIONS_ERROR = "PermissionsError";
type RequestStatus = "idle" | "pending" | "resolved" | "rejected";

interface PropTypes {
  context: Context;
  reportId: number;
  onChangeQuery: (q: QueryForEntity) => any;
  goTo: GoFn;
  withLinks: boolean;
}

interface StateType {
  report: Report;
  error: ApiErrorResponse | "PermissionsError";
  requestStatus: RequestStatus;
}

/**
 * This component is used to show report's result when the report is accessed directly from the main menu
 */
export class ReportTableController extends Component<PropTypes, StateType> {
  state: StateType = {
    report: undefined,
    error: undefined,
    requestStatus: "idle",
  };
  fetchReportsRequest: CancellablePromise<unknown>;

  componentDidMount() {
    if (this.props.reportId) this.loadReport();
  }

  componentWillUnmount() {
    this.fetchReportsRequest?.cancel();
  }

  loadReport = () => {
    const { context, reportId } = this.props;
    const { apiCall, entities, uiFormat } = context;

    this.setState({ requestStatus: "pending" });
    this.fetchReportsRequest = reportsApi(apiCall, uiFormat.culture, entities)
      .get(reportId)
      .then((report) => {
        if (canAccessReportEntities(entities, report)) {
          this.setState({ requestStatus: "resolved", report });
        } else {
          this.setState({
            requestStatus: "resolved",
            error: PERMISSIONS_ERROR,
          });
        }
      })
      .catch((error) => this.setState({ requestStatus: "rejected", error }));
  };

  render() {
    const { withLinks, goTo, context, onChangeQuery, reportId } = this.props;
    const { entities } = context;
    const { report, error, requestStatus } = this.state;

    switch (requestStatus) {
      case "idle":
        if (!reportId)
          return <AlertWarning message={_("No report ID provided")} />;
      // fallthrough
      case "pending":
        return <ContentLoading />;
      case "rejected":
        return <ApiError error={error as ApiErrorResponse} />;
      case "resolved": {
        if (error === PERMISSIONS_ERROR) return <PermissionsError />;
        if (!report)
          return (
            <AlertWarning
              message={_("No report with the given ID has been found")}
            />
          );
      }
    }

    return (
      <RecordListController
        withLinks={withLinks}
        entity={entities[report.entity]}
        goTo={goTo}
        context={context}
        report={report}
        onChangeQuery={onChangeQuery}
      />
    );
  }
}
