import { genericSortByField } from "common/functions/generics";
import { getSitesDictionary } from "common/functions/sites";
import { Site, Sites } from "common/types/sites";

type SiteWithPath = Site & { path: string };

export const getSingleSites = (sites: Site[]) =>
  genericSortByField<Site>("name")(sites.filter((s) => !s.isGroup));

export const getSitePath = (site: Site, sitesDictionary: Sites): string =>
  (site.parentName
    ? getSitePath(sitesDictionary[site.parentName], sitesDictionary) +
      site.label
    : site.label) + "/";

export const getSitesWithPaths = (sites: Site[]): SiteWithPath[] => {
  const sitesDictionary = getSitesDictionary(sites);
  return sites.map((site) => ({
    ...site,
    path: getSitePath(site, sitesDictionary),
  }));
};

export const getPathsOfMatchingSites = (
  sites: SiteWithPath[],
  searchTerm: string,
) =>
  sites.reduce(
    (acc, site) =>
      site.path.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) !==
      -1
        ? acc.concat(site.path)
        : acc,
    [],
  );

/**
 * It returns all sites whose labels match the given `searchTerm`. In order
 * to render a valid tree it also returns all ancestors. To improve the UX it
 * also returns all descendants as well.
 *
 * With the given structure:
 *
 * All
 * |- Europe
 *  |- Ireland
 *   |- Dublin
 *   |- Cork
 * |- America
 *
 * Searching for `Irel` produces (It's a search when you know at least a
 * part of a parent location but don't remember exactly the child location):
 *
 * All
 * |- Europe
 *  |- Ireland
 *   |- Dublin
 *   |- Cork
 *
 * Searching for `Dub` produces (It's a search when you know at least a part of
 * a child location's name):
 *
 * All
 * |- Europe
 *  |- Ireland
 *   |- Dublin
 *
 * @param allSites
 * @param searchTerm
 */
export const getSitesForTree = (allSites: Site[], searchTerm: string) => {
  if (!searchTerm) return allSites;

  const sitesWithPaths = getSitesWithPaths(allSites);
  const matchingPaths = getPathsOfMatchingSites(sitesWithPaths, searchTerm);

  return sitesWithPaths.filter((site) =>
    matchingPaths.some(
      (path: string) =>
        // Exact match - user was searching for
        site.path === path ||
        // Descendant sites - related to the parent, matching site
        site.path.indexOf(path) === 0 ||
        // Ancestor sites - chain of group sites containing the matched site
        path.indexOf(site.path) === 0,
    ),
  );
};
