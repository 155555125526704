import { Component } from "react";
import { hasPermissionToRead } from "common/functions/roles";
import { Tabs } from "common/widgets/tabs/tabs";
import { Tab } from "common/widgets/tabs/tab";
import { DevExpressController } from "./devexpress";
import { Dashboards, PropTypes as DashboardProps } from "./view";

export interface PropTypes extends DashboardProps {
  tab: string;
  hidePanels: boolean;
}

export class DashboardViewController extends Component<PropTypes> {
  static readonly displayName = "DashboardViewController";

  onTabChange = (selectedTab: string) => {
    const { context, goTo } = this.props;
    const { site } = context;

    if (selectedTab === "devexpress") {
      goTo(`#/${site.name}/dashboard?tab=devexpress`);
    } else {
      goTo(`#/${site.name}/dashboard`);
    }
  };

  render() {
    const { context, id, goTo, onEdit, onNew, tab, hidePanels } = this.props;

    const x5dashboard = (
      <Dashboards
        context={context}
        goTo={goTo}
        onNew={onNew}
        onEdit={onEdit}
        id={id}
      />
    );
    const devexpress = (
      <DevExpressController
        context={context}
        hidePanels={hidePanels}
        urlId={id}
      />
    );
    if (hidePanels && tab === "devexpress") {
      return devexpress;
    }

    return (
      <div className="x-dashboard">
        {context.isEnabledFeature("devExpress") &&
        hasPermissionToRead(context.userTypes, context.role, "Dashboard") ? (
          <Tabs
            key="dashboard-tabs"
            className="x-expand-devexpress"
            defaultValue={tab}
            onChange={this.onTabChange}
          >
            <Tab value="x5dashboard" label={_("X5")}>
              {x5dashboard}
            </Tab>
            <Tab
              className="x-expand-devexpress"
              value="devexpress"
              label={_("Dashboard 2.0")}
            >
              {devexpress}
            </Tab>
          </Tabs>
        ) : (
          x5dashboard
        )}
      </div>
    );
  }
}
