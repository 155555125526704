import * as R from "ramda";
import { getLocalizedName } from "common";
import { Entities } from "common/entities/types";
import { defaultJoinType } from "common/query-builder/joins/functions";
import { getFlatRulesMapper, isEmptyFilter } from "common/query/filter";
import {
  Filter,
  GroupField,
  isExpressionRule,
  isSelectExpression,
  isSelectExpressionWithoutValues,
  isSelectField,
  isSelectFieldWithAliasWithoutValues,
  isSelectFieldWithFnWithoutValues,
  isSummaryField,
  isSummaryFieldWithoutValues,
  JoinItem,
  JoinType,
  Query,
  QueryForEntity,
  SelectExpression,
  SelectField,
  SelectItem,
} from "common/query/types";
import { getField, getFields } from "./functions";
import { Field } from "./types";

export const MAX_ALIAS_LENGTH = 128;

interface Item {
  fn?: string;
  name?: string;
  path?: string;
  entityName?: string;
  expression?: string;
}

export interface Error {
  field: string;
  message: string;
}

interface FieldError extends Error {
  name?: string;
  path?: string;
}

export type GroupError = FieldError;

export interface SelectError extends FieldError {
  alias: string;
  expression: string;
  part: Part;
}

export interface FilterError extends FieldError {
  expression: string;
}

type Part =
  | "alias"
  | "value"
  | "expression"
  | "invalid"
  | "duplicated"
  | "entityName"
  | "tooLong";

const messages = (): { [index: string]: string } => ({
  alias: _("Label is required"),
  value: _("Value is required"),
  expression: _("Expression is required"),
  invalid: _("ID, Site and Number are in use"),
  duplicated: _("Label is duplicated"),
  entityName: _("Entity name is invalid"),
  tooLong: _("Label can be up to 128 characters"),
});

const withFunctions = <T extends Item>(items: T[]) =>
  R.filter((f: T) => !!f.fn, items);

const withoutFunctions = <T extends Item>(items: T[]) =>
  items.filter((f: T) => !f.fn && !f.expression && !!f.name);

const uniqErrors = <T extends FieldError>(err: T[]): T[] =>
  R.uniqWith((f1: T, f2: T) => f1.name === f2.name && f1.path === f2.path, err);

const toLower = (s: string) => (s ? R.toLower(s) : s);

const informedFields = (f: SelectItem) =>
  isSummaryField(f)
    ? toLower(f.entityName)
    : toLower(f.alias) ||
      toLower((f as SelectField).name) ||
      (f as SelectExpression).expression;

// === Group ================================================================ //
const defaultQuery: Query = { select: undefined };

export const getGroupErrors = (
  fields: Field[],
  q: QueryForEntity,
): GroupError[] => {
  const query = q?.query ?? defaultQuery;
  const { select = [], order = [], group = [], having } = query;

  if (
    !group.length &&
    isEmptyFilter(having) &&
    !withFunctions(select).length &&
    !withFunctions(order).length
  )
    return [];

  const notInGroup = (item: Item) =>
    !group.find(
      (g: GroupField) => item.name === g.name && item.path === g.path,
    );

  const createError = (item: Item): GroupError => {
    const field = getField(fields, item);
    if (!field) return undefined;

    const message = `${field.minPath}.${getLocalizedName(field.column)}`;
    return {
      field: "query.group",
      message: _("MESSAGE is required").replace("MESSAGE", message),
      name: item.name,
      path: item.path,
    };
  };

  const invalidGroup = (items: Item[]): FieldError[] =>
    items.reduce((acc: FieldError[], item: Item) => {
      if (!notInGroup(item)) return acc;

      const itemError = createError(item);
      if (!itemError) return acc;

      return acc.concat([itemError]);
    }, []);

  const selectErrors = invalidGroup(withoutFunctions(select));
  const orderErrors = invalidGroup(withoutFunctions(order));

  const getHavingErrors = getFlatRulesMapper((rule) => {
    if (!rule || isExpressionRule(rule) || rule.fn || !rule.name) return [];

    const { valueFromColumn } = rule;

    const columnGroupError =
      valueFromColumn?.name &&
      notInGroup(valueFromColumn) &&
      createError(valueFromColumn);

    const errors: GroupError[] = columnGroupError ? [columnGroupError] : [];
    const ruleGroupError = notInGroup(rule) && createError(rule);

    return ruleGroupError ? errors.concat([ruleGroupError]) : errors;
  });

  const havingErrors = getHavingErrors(having).flat();

  const groupErrors = uniqErrors(
    selectErrors.concat(orderErrors, havingErrors),
  );

  if (!group.length && !isEmptyFilter(having) && groupErrors.length === 0) {
    const missingGroupBy: GroupError = {
      field: "query.group",
      message: _(
        "At least one Group By field is required when Having filter is present",
      ),
    };

    return [missingGroupBy];
  }

  return groupErrors;
};

// === Select =============================================================== //
const selectItemError = (item: SelectItem, part: Part): SelectError => ({
  alias: !isSummaryFieldWithoutValues(item) ? item.alias : "",
  path: (item as SelectField).path,
  name: (item as SelectField).name,
  expression: (item as SelectExpression).expression,
  field: "query.select",
  message: messages()[part],
  part,
});

const getSelectItemErrors = (item: SelectItem) => {
  const noEntityNameValueError =
    isSummaryFieldWithoutValues(item) && !item.entityName
      ? [selectItemError(item, "entityName")]
      : [];

  const noAliasError =
    ((isSelectFieldWithFnWithoutValues(item) && item.fn) ||
      isSelectExpressionWithoutValues(item)) &&
    !item.alias
      ? [selectItemError(item, "alias")]
      : [];

  const noExpressionValueError =
    isSelectExpressionWithoutValues(item) && !item.expression
      ? [selectItemError(item, "expression")]
      : [];

  const badAliasError =
    (isSelectFieldWithAliasWithoutValues(item) ||
      isSelectExpressionWithoutValues(item)) &&
    item.alias &&
    R.includes(item.alias.toLowerCase().trim(), ["id", "site", "number"])
      ? [selectItemError(item, "invalid")]
      : [];

  const tooLongAliasError =
    isSelectField(item) && item.alias && item.alias.length > MAX_ALIAS_LENGTH
      ? [selectItemError(item, "tooLong")]
      : [];

  const errors: SelectError[] = []
    .concat(noEntityNameValueError)
    .concat(noAliasError)
    .concat(noExpressionValueError)
    .concat(badAliasError)
    .concat(tooLongAliasError);

  return errors;
};

const fieldToError = (field: SelectItem): SelectError =>
  isSelectField(field) || isSelectExpression(field)
    ? {
        alias: field.alias,
        path: (field as SelectField).path,
        name: (field as SelectField).name,
        expression: (field as SelectExpression).expression,
        field: "query.select",
        message: field.alias
          ? _("Label is duplicated")
          : _("Label is required"),
        part: field.alias ? "duplicated" : "alias",
      }
    : undefined;

const getSelectErrorsFromDuplicates = (select: SelectItem[]): SelectError[] =>
  R.compose(
    R.map(fieldToError),
    R.unnest,
    (sels: SelectItem[][]) => R.filter<SelectItem[]>((f) => f.length > 1, sels),
    R.values,
    R.groupBy(informedFields),
    (sels: SelectItem[]) =>
      R.filter((f) => !!informedFields(f) && !isSummaryField(f), sels),
  )(select);

export const getSelectErrors = (select: SelectItem[]): SelectError[] => {
  return R.compose(
    (l: SelectError[]) => R.uniq(l),
    R.concat(getSelectErrorsFromDuplicates(select)),
    R.unnest,
    (selErrs: SelectError[][]) =>
      R.filter<SelectError[]>((err) => err.length > 0, selErrs),
    (sels: SelectItem[]) => R.map(getSelectItemErrors, sels),
  )(select);
};

// === Filter =============================================================== //
export const getFilterErrors = (filter: Filter) => {
  const expressionRuleErrors = getFlatRulesMapper((rule): FilterError => {
    return rule && isExpressionRule(rule) && !rule.expression
      ? {
          expression: rule.expression,
          field: "query.filter",
          message: messages().expression,
        }
      : undefined;
  })(filter);

  return R.uniq(expressionRuleErrors).filter((e) => e);
};

// === ### =============================================================== //

export const getErrors = (fields: Field[], query: QueryForEntity): Error[] => {
  if (!query || !query.entity)
    return [{ field: "entity", message: _("required") }];

  if (!query.query) return [{ field: "query", message: _("required") }];

  const { select } = query.query;
  if (!select || !select.length)
    return [{ field: "query.select", message: _("required") }];

  return [
    ...getSelectErrors(query.query.select),
    ...getGroupErrors(fields, query),
    ...getFilterErrors(query.query.filter),
  ];
};

const areJoinsValid = (query: QueryForEntity, validJoinTypes: JoinType[]) => {
  const areJoinTypesValid = (joins: JoinItem[]): boolean =>
    !joins ||
    joins.every(
      (join) =>
        validJoinTypes.includes(join.type ?? defaultJoinType) &&
        areJoinTypesValid(join.joins),
    );
  return !validJoinTypes || areJoinTypesValid(query.query.joins);
};

export const isQueryValid = (
  entities: Entities,
  query: QueryForEntity,
  validJoinTypes?: JoinType[],
) =>
  getErrors(getFields(entities, query), query).length === 0 &&
  areJoinsValid(query, validJoinTypes);
