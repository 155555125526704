import { isForeignKey } from "common/functions/foreign-key";
import { isSystemIntFk } from "common/functions/system-int";
import { isSystemStringFk } from "common/functions/system-strings";
import { merge2 } from "common/merge";
import { QueryForEntity } from "common/query/types";

export const shouldExcludeFromFkExpansion = (value: any) =>
  isForeignKey(value) || isSystemIntFk(value) || isSystemStringFk(value);

export const setFkExpansion = (query: QueryForEntity): QueryForEntity =>
  query && merge2("query", "fkExpansion", true, query);
