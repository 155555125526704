import { Entities, Entity } from "common/entities/types";
import { getFields } from "common/query-builder/functions";
import { RuleActions, WorkflowRule } from "common/types/workflows";
import { isApproveRequestActionValid } from "x/account-settings/workflows/form/actions/approve-request/functions";
import {
  isEmailNotificationValid,
  areEmailReceiversValid,
} from "x/account-settings/workflows/form/actions/email-notification/functions";
import { isUpdateValueActionValid } from "x/account-settings/workflows/form/actions/update-table-value/functions";
import { isCreateRecordValid } from "./create-record/functions";

const isActionEnabled =
  (actions: RuleActions) => (actionName: keyof RuleActions) =>
    !!(actions && actionName in actions);

export const areActionsValid = (rule: WorkflowRule) => {
  const actions = rule?.actions;
  const isEnabled = isActionEnabled(actions);

  return (
    (isEnabled("updatetablevalue") ||
      isEnabled("sendnotification") ||
      isEnabled("approverequest") ||
      isEnabled("addrecord")) &&
    (!isEnabled("updatetablevalue") ||
      isUpdateValueActionValid(actions?.updatetablevalue)) &&
    (!isEnabled("approverequest") ||
      isApproveRequestActionValid(actions?.approverequest)) &&
    (!isEnabled("sendnotification") ||
      (isEmailNotificationValid(actions?.sendnotification) &&
        areEmailReceiversValid(actions?.sendnotification))) &&
    (!isEnabled("addrecord") || isCreateRecordValid(actions?.addrecord))
  );
};

export const getUpdateSourceFieldsForEntity = (
  entity: Entity,
  entities: Entities,
) =>
  getFields(entities, {
    entity: entity.name,
    query: { ...entity.query, joins: entity.joins.filter((j) => j.outbound) },
  });
