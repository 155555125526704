import * as R from "ramda";
import { Component } from "react";
import { getMediaUrl, mediaApi } from "common/api/media";
import { Entity } from "common/entities/types";
import { RecordDetailValue } from "common/record/form/content/detail";
import { MEDIA_FILES } from "common/record/utils";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { MediaUploadController } from "common/widgets/file-selector/media-file-upload";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { ValueProps } from "common/with-value-for";
import { MediaFileList } from "./list";

interface PropTypes extends ValueProps<RecordDetailValue> {
  context: Context;
  entity: Entity;
  disableEdit?: boolean;
}

interface StateType {
  error?: any;
  loading?: boolean;
  files?: FileType[];
}

export class RecordMedia extends Component<PropTypes, StateType> {
  state: StateType = {
    error: undefined,
    loading: false,
    files: [],
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: PropTypes) {
    const oldImage = prevProps.value.record.properties.image;
    const newImage = this.props.value.record.properties.image;
    const { files } = this.state;

    if (
      newImage &&
      newImage !== oldImage &&
      !R.any((f) => f.url === newImage, files)
    ) {
      this.load();
    }
  }

  getId = (properties: Properties) => properties.id ?? properties.$id;

  appendUrl = (file: FileType): FileType => {
    const { context, entity, value } = this.props;
    const recordId = this.getId(value.record.properties);

    return {
      ...file,
      url: getMediaUrl(
        context.site.name,
        file?.source || entity.name,
        file?.sourceId || recordId,
        file.name,
      ),
    };
  };

  load = () => {
    const { context, entity, value } = this.props;
    const recordId = this.getId(value.record.properties);

    this.setState({ loading: true });
    mediaApi(context.apiCall)
      .list(entity.name, recordId)
      .then((files) => files.map(this.appendUrl))
      .then((files) => this.setState({ loading: false, files: files }))
      .catch((error) => this.setState({ loading: false, error }));
  };

  onSetAsDefault = (file: FileType) => {
    this.setAsDefault(file.url);
  };

  setAsDefault = (url: string) => {
    const { context, entity, value, onChange } = this.props;
    const recordId = this.getId(value.record.properties);

    mediaApi(context.apiCall)
      .setAsDefault(entity.name, recordId, url)
      .then(() => {
        const valueWithImage: RecordDetailValue = {
          ...value,
          record: {
            ...value?.record,
            properties: {
              ...value?.record?.properties,
              image: url,
            },
          },
        };
        onChange(valueWithImage);
      });
  };

  onUpload = (file: FileType) => {
    this.setState({
      files: [...this.state.files, file],
    });
  };

  getRemovedFiles = (oldFiles: FileType[], newFiles: FileType[]) => {
    return oldFiles.filter(
      (oldFile) => !newFiles.some((newFile) => newFile.url === oldFile.url),
    );
  };

  onChange = (newFiles: FileType[] = []) => {
    const { context, entity, value } = this.props;
    const properties = value.record.properties;
    const { files = [] } = this.state;

    const { image } = properties;
    const recordId = this.getId(properties);
    const removedFiles = this.getRemovedFiles(files, newFiles);
    if (image && R.any((f) => image.indexOf(f.url) !== -1, removedFiles)) {
      this.setAsDefault(null);
    }

    const mediaApiService = mediaApi(context.apiCall);
    CancellablePromise.all(
      removedFiles.map((f) =>
        mediaApiService.remove(entity.name, recordId, f.name),
      ),
    ).then(() => this.setState({ files: newFiles }));
  };

  isRecordSaved = (value: RecordDetailValue) => {
    return !!value.record?.properties?.id;
  };

  render() {
    const { context, entity, disableEdit, value } = this.props;

    const { files, loading, error } = this.state;

    const setAsDefault = this.isRecordSaved(value)
      ? this.onSetAsDefault
      : undefined;

    return (
      <Tabs key="media-tabs">
        <Tab value={MEDIA_FILES} label={_("Documents")}>
          {error ? (
            <ApiError error={error} />
          ) : (
            <>
              <MediaFileList
                apiCall={context.apiCall}
                value={files}
                entity={entity}
                setAsDefault={setAsDefault}
                onChange={this.onChange}
                loading={loading}
                defaultImage={value.record.properties.image}
                disableEdit={disableEdit}
              />
              {!disableEdit ? (
                <MediaUploadController
                  context={context}
                  entityName={entity.name}
                  recordId={this.getId(value.record.properties)}
                  onUpload={this.onUpload}
                />
              ) : undefined}
            </>
          )}
        </Tab>
      </Tabs>
    );
  }
}
