import { Component } from "react";
import { CancellablePromise } from "common/types/promises";
import { partReorderApi } from "common/api/part-reorder";
import { merge2 } from "common/merge";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { defaultPageSize } from "common/types/pagination";
import { ContentLoading } from "x/records/list/content-loading";
import { PartsReorderContent } from "./content";
import { getReorderListQuery } from "./functions";
import { ListValue } from "./types";

interface PropTypes {
  context: Context;
}

interface StateType {
  page: number;
  pageSize: number;
  totalRecords: number;
  initialLoading: boolean;
  loadingRecords: boolean;
  error: ApiErrorResponse;
  value: ListValue;
}

export class PartsReorderListController extends Component<
  PropTypes,
  StateType
> {
  static readonly displayName = "PartsReorderListController";

  fetchRecordsRequest: CancellablePromise<unknown>;

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      page: 0,
      pageSize: defaultPageSize,
      totalRecords: 0,
      initialLoading: true,
      loadingRecords: false,
      error: undefined,
      value: {
        table: {
          widths: [],
          selected: [],
          query: getReorderListQuery(props.context),
        },
      },
    };
  }

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.fetchRecords(page, pageSize);
  }

  componentWillUnmount() {
    this.fetchRecordsRequest?.cancel();
  }

  onError = (error: ApiErrorResponse) => {
    this.setState({
      initialLoading: false,
      error,
    });
  };

  onReload = () => {
    const { page, pageSize } = this.state;
    this.fetchRecords(page, pageSize);
  };

  onChangePage = (newPage: number) => {
    this.setState({ page: newPage });
    this.fetchRecords(newPage, this.state.pageSize);
  };

  onChangePageSize = (newPageSize: number) => {
    const page = 0;
    this.setState({ page, pageSize: newPageSize });
    this.fetchRecords(page, newPageSize);
  };

  fetchRecords = (page: number, pageSize: number) => {
    const { context } = this.props;
    const { value } = this.state;

    this.setState({
      loadingRecords: true,
      error: undefined,
    });

    this.fetchRecordsRequest = partReorderApi(context)
      .list(page, pageSize)
      .then((response) => {
        const { data, fullCount } = response;

        // reset selected on record fetch
        const valueWithoutSelected = merge2("table", "selected", [], value);
        this.setState({
          initialLoading: false,
          loadingRecords: false,
          value: merge2("table", "data", data, valueWithoutSelected),
          totalRecords: fullCount,
        });
      })
      .catch(this.onError);
  };

  onChange = (newValue: ListValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { context } = this.props;
    const {
      page,
      pageSize,
      initialLoading,
      loadingRecords,
      totalRecords,
      error,
      value,
    } = this.state;

    if (initialLoading) return <ContentLoading />;

    return (
      <PartsReorderContent
        context={context}
        loadingRecords={loadingRecords}
        onReload={this.onReload}
        error={error}
        page={page}
        pageSize={pageSize}
        totalRecords={totalRecords}
        value={value}
        onChangePage={this.onChangePage}
        onChangePageSize={this.onChangePageSize}
        onChange={this.onChange}
      />
    );
  }
}
