import * as R from "ramda";
import { getLocalizedName } from "common";
import { deepEqual } from "common/component";
import { behaveAs, getColumn } from "common/entities";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities } from "common/entities/types";
import { merge1, merge2, mergeChain } from "common/merge";
import { flattenFkValues, similarFilter } from "common/query/filter";
import {
  canAccessJoinEntities,
  getJoins,
  getPathMap,
} from "common/query/joins";
import {
  addToSelectQuery,
  getSelectField,
  mapSelectToRelatedSummary,
} from "common/query/select";
import { CancellablePromise } from "common/types/promises";
import { Report } from "common/types/reports";
import { similarArray } from "common/utils/array";
import { ColumnDefinition, PathMap } from "./advanced-types";
import {
  Filter as FilterItem,
  Filter,
  isFieldRule,
  isSelectField,
  isSummaryField,
  Page,
  PagedResult,
  QueryForEntity,
  RunQuery,
  SelectItem,
} from "./types";

export const emptyGUID = "00000000-0000-0000-0000-000000000000";
const REQUIRED_FIELDS = [{ name: "id" }, { name: "number" }];

// Two queries produce the same result set if they are identical or their select
// fields are similar (but in different order).
export const sameResultSet = (
  a: QueryForEntity,
  b: QueryForEntity,
): boolean => {
  if (a === b) return true;
  if (a.entity !== b.entity) return false;
  if (deepEqual(a.query, b.query)) return true;
  if (
    similarArray(a.query.select, b.query.select) &&
    similarArray(a.query.order, b.query.order) &&
    similarFilter(a.query.filter, b.query.filter) &&
    similarFilter(a.query.having, b.query.having) &&
    similarArray(a.query.joins, b.query.joins)
  )
    return true;
  return R.equals(a, b);
};

export const hasEmphasis = (column: EntityColumn) =>
  (column.required && column.unique) || column.name === "number";

export const getDefinition =
  (pathMap: PathMap, entities: Entities) =>
  (selectCol: SelectItem): ColumnDefinition => {
    if (!selectCol) return undefined;

    if (isSummaryField(selectCol)) {
      const { entityName } = selectCol;
      const entity = entities && entities[entityName];
      const label = entity && getLocalizedName(entity);
      return {
        entity: entityName,
        column: undefined,
        item: undefined,
        valueKey: entityName,
        label,
        hasEmphasis: false,
        isRelatedEntity: true,
      };
    }

    if (isSelectField(selectCol)) {
      const colName = selectCol.name;
      const pathEntity = pathMap[selectCol.path];
      const column =
        colName &&
        ((pathEntity && getColumn(pathEntity, colName)) ||
          getColumn(pathMap[""], colName));
      const alias = selectCol.alias;
      const valueKey = alias || colName;

      return column
        ? {
            valueKey,
            label: selectCol.label || alias || getLocalizedName(column),
            item: selectCol,
            entity: (pathEntity && pathEntity.name) || pathMap[""].name,
            column,
            hasEmphasis: hasEmphasis(column),
          }
        : {
            valueKey,
            label: valueKey,
            item: selectCol,
            column: undefined,
            entity: undefined,
            hasEmphasis: false,
          };
    }
    const valueKey = selectCol.alias;

    return {
      valueKey,
      label: valueKey,
      item: selectCol,
      column: undefined,
      entity: undefined,
      hasEmphasis: false,
    };
  };

export const getColumnDefinitions = (
  entities: Entities,
  query: QueryForEntity,
) => {
  const pathMap = getPathMap(entities, query);
  const select = query?.query?.select;
  return select && pathMap
    ? select
        .map(getDefinition(pathMap, entities))
        .filter((c) => !c.column || !!c.column.name)
    : [];
};

export const getDefinitionLabel = (
  entities: Entities,
  baseEntityName: string,
  cd: ColumnDefinition,
) => {
  if (!cd) return undefined;
  const { entity, valueKey, label, column } = cd;

  if (!(entity && baseEntityName && valueKey && R.includes(".", valueKey))) {
    return label || (column && (getLocalizedName(column) || column.name));
  }

  const [key, name] = valueKey.split(".");
  const columnName = getLocalizedColumnName(entities[entity], name);
  const localizedKey = getLocalizedColumnName(entities[baseEntityName], key);
  return `(${localizedKey}) ${columnName}`;
};

export const getAliasOrNameOrEntityName = (field: SelectItem): string => {
  if (!field) return undefined;

  return isSelectField(field)
    ? field.alias || field.name
    : isSummaryField(field)
      ? field.entityName
      : field.alias;
};

const mergePage = (page: Page, num: number, pages: Page[]): Page[] => [
  ...R.slice(0, num, pages),
  page,
];

export const runQueryWithPage = (
  runQuery: RunQuery,
  pages: Page[],
  query: QueryForEntity,
  page: number,
): CancellablePromise<PagedResult> => {
  const pagedQuery = mergeChain(query).prop("query").set("page", page).output();
  const pageSize = query?.query?.pageSize || 0;

  return runQuery(pagedQuery).then((data: any) => ({
    pages: mergePage(data, page, pages),
    isLastPage: pageSize === 0 || data.length < pageSize,
  }));
};

export const getRecordsForPages = (pages: Page[]) => R.flatten(pages);

export const equals = (value: string, ids: string[]) => {
  if (!value && !ids) return true;
  const values = R.split(",", value);
  if (values.length !== ids.length) return false;
  return R.reduce(
    (acc, v) => acc && R.any((id) => id === v, ids),
    true,
    values,
  );
};

export const isStarCondition = (ids: string[], rule: FilterItem) =>
  !!rule &&
  isFieldRule(rule) &&
  rule.name === "id" &&
  rule.op === "in" &&
  equals(rule.value, ids?.length ? ids : [emptyGUID]);

export const injectFieldsByBehavior = (
  entities: Entities,
  query: QueryForEntity,
): QueryForEntity => {
  const group = query?.query?.group;
  if (group && group.length > 0) return query;

  const entityName = query?.entity;
  const entity = entities && entities[entityName];

  if (behaveAs("WorkOrder", entity) || behaveAs("Request", entity)) {
    return R.mergeRight(query, {
      query: addToSelectQuery([{ name: "status" }], query && query.query),
    });
  }

  if (behaveAs("Requisitioning", entity)) {
    return merge2("query", "restrict", ["Requisitioning"], query);
  }
  return query;
};

export const canAccessQueryEntities = (
  entities: Entities,
  query: QueryForEntity,
) => {
  const entity = query?.entity;
  if (!entities || !entities[entity]) return false;
  return canAccessJoinEntities(entities, getJoins(query), entity);
};

export const canAccessReportEntities = (
  entities: Entities,
  { entity, query }: Report,
) => canAccessQueryEntities(entities, { entity, query });

export const getDisplayFieldFilters = (
  select: SelectItem[],
  queryString: string,
) =>
  ["title", "subtitle", "subsubtitle"].reduce(
    (acc: Filter[], alias: string) => {
      const field = getSelectField(alias, select);
      return field
        ? acc.concat({
            name: field.name,
            op: "contains",
            value: queryString,
            path: field.path,
          })
        : acc;
    },
    [],
  );

export const getFormattedQuery = (
  queryForEntity: QueryForEntity,
  title?: string,
) => {
  const { query } = flattenFkValues(queryForEntity);
  const withRelatedSummary = mapSelectToRelatedSummary(query);
  const withSelect = addToSelectQuery(REQUIRED_FIELDS, withRelatedSummary);
  return merge1("title", title, withSelect);
};
