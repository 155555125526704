import { Record } from "common/types/records";
import { Entity } from "common/entities/types";
import { OverviewPosition, RelatedEntity } from "common/form/types";
import { RelatedEntityInOverview } from "common/form/types/overview-related";
import {
  isRelatedEntityReadonly,
  shouldDisableRelatedEntity,
} from "common/record/disabled-related-entity";
import { Context } from "common/types/context";
import { canShowRelated } from "common/record/sidebar/functions";
import { getContextWithBehaviors } from "../functions";

export const getRelatedEntitiesShowInOverview = (
  relatedEntities: RelatedEntity[],
  context: Context,
  overviewPosition?: OverviewPosition,
): RelatedEntityInOverview[] => {
  const relatedEntitiesWithPermission = relatedEntities?.filter(
    (re) => context.entities[re.name],
  );
  return relatedEntitiesWithPermission?.reduce(
    (acc: RelatedEntityInOverview[], re: RelatedEntity) => {
      if (
        !re.showInOverview ||
        (re?.overviewPosition || "bottom") !== overviewPosition
      )
        return acc;
      const newContext = getContextWithBehaviors(context, re.name);
      const relatedEntity = newContext.entities[re.name];
      return [...acc, { ...re, context: newContext, entity: relatedEntity }];
    },
    [],
  );
};

export const filterWritableRelatedEntities = (
  context: Context,
  entity: Entity,
  record: Record,
  relatedEntities: RelatedEntity[],
) =>
  relatedEntities?.filter(
    (re) =>
      !isRelatedEntityReadonly(
        entity,
        context.entities[re.name],
        record.properties,
      ) && canShowRelated(context.site, entity)(context.entities[re.name]),
  );

export const filterEnabledRelatedEntities = (
  context: Context,
  entity: Entity,
  record: Record,
  relatedEntities: RelatedEntity[],
) =>
  relatedEntities?.filter(
    (re) =>
      !shouldDisableRelatedEntity(
        entity,
        context.entities[re.name],
        record.properties,
      ) && canShowRelated(context.site, entity)(context.entities[re.name]),
  );
