import { Component } from "react";
import { defaultFor } from "common";
import { Email } from "common/types/records";
import { Culture, EN_US } from "common/culture/supported-cultures";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { EmailTemplate } from "common/types/email-templates";
import { VerticalField } from "common/ui/field";
import { RequiredField } from "common/ui/required-field";
import { arrayToCommaString, commaStringToArray } from "common/utils/array";
import {
  getFieldsForCulture,
  getFieldsForDefaultCulture,
  getTemplatesAvailableInEntity,
  getTemplatesAvailableInSites,
} from "common/widgets/email-action/functions";
import { EmailChickletsSelector } from "common/widgets/email-chicklets-selector";
import { LanguageSelector } from "common/widgets/language-selector";
import { ValueProps } from "common/with-value-for";
import { Selector } from "common/widgets/selector";
import { StringInput } from "common/widgets/input-with-submit/string";
import { LazyTextEditor } from "common/widgets/text-editor";

interface PropTypes extends ValueProps<Email> {
  context: Context;
  entity?: Entity;
}

export const isValid = (value: Email): boolean =>
  !!value?.to && !!value?.subject;

export const getTemplateLanguages = (
  cultures: Culture[] = [],
  template?: EmailTemplate,
) =>
  template
    ? cultures.filter((c) => Object.keys(template.fields).includes(c))
    : undefined;

export class EmailAction extends Component<PropTypes> {
  static readonly displayName = "EmailAction";

  onChangeTemplate = (template: EmailTemplate) => {
    const { value, onChange, context } = this.props;
    const defaultTranslations = getFieldsForDefaultCulture(template);
    const userCultureSpecificTranslations = getFieldsForCulture(
      template,
      context.uiFormat.culture,
    );

    const availableTranslations = template
      ? getTemplateLanguages(context.cultures, template)
      : [];
    const defaultDisplayedTranslation = availableTranslations.find(
      (culture) => culture === context.uiFormat.culture,
    );

    onChange({
      ...value,
      culture: defaultDisplayedTranslation || EN_US,
      emailTemplateId: template ? template.id : undefined,
      body: template
        ? userCultureSpecificTranslations?.emailBody ||
          defaultTranslations?.emailBody
        : undefined,
      subject: template
        ? userCultureSpecificTranslations?.emailSubject ||
          defaultTranslations?.emailSubject
        : undefined,
    });
  };

  onChangeEmailBody = (body: string) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      emailTemplateId: undefined,
      body,
    });
  };

  onChangeRecipient = (field: string) => (recipients: string[]) => {
    const { value, onChange } = this.props;
    const emails = arrayToCommaString(recipients);

    onChange({
      ...value,
      [field]: emails,
    });
  };

  onSubjectChange = (subject: string) => {
    const { value, onChange } = this.props;

    onChange({ ...value, subject, emailTemplateId: undefined });
  };

  onLanguageChange = (language: Culture) => {
    const { context, value, onChange } = this.props;

    const template = context.emailTemplates.find(
      (template) => template.id === value.emailTemplateId,
    );
    const selectedTranslation = getFieldsForCulture(template, language);

    onChange({
      ...value,
      culture: language,
      body: selectedTranslation?.emailBody,
      subject: selectedTranslation?.emailSubject,
    });
  };

  display = (template: EmailTemplate) => {
    const { context } = this.props;
    const culture = template
      ? getTemplateLanguages(context.cultures, template).find(
          (c) => c === context.uiFormat.culture,
        )
      : undefined;

    return getFieldsForCulture(template, culture || EN_US)?.templateName;
  };

  render() {
    const { context, entity, value = defaultFor<Email>() } = this.props;
    const { emailTemplates, site, sites } = context;

    const templatesInCurrentSite = getTemplatesAvailableInSites(
      emailTemplates,
      site,
      sites,
    );
    const availableTemplates = getTemplatesAvailableInEntity(
      templatesInCurrentSite,
      entity,
    );

    const selectedTemplate = availableTemplates.find(
      (t) => t.id === value.emailTemplateId,
    );

    const templateLanguages = getTemplateLanguages(
      context.cultures,
      selectedTemplate,
    );

    return (
      <div className="qa-email-action">
        <RequiredField
          label={_("To")}
          value={value.to}
          input={
            <EmailChickletsSelector
              context={context}
              entity={entity}
              value={commaStringToArray(value.to)}
              onChange={this.onChangeRecipient("to")}
            />
          }
        />
        <VerticalField
          label={_("Cc")}
          input={
            <EmailChickletsSelector
              context={context}
              entity={entity}
              value={commaStringToArray(value.cc)}
              onChange={this.onChangeRecipient("cc")}
            />
          }
        />
        {entity ? (
          <VerticalField
            label={_("Email Template")}
            className="x-padding-bottom-10 qa-template-dropdown"
            input={
              <Selector<EmailTemplate>
                placeholder={_("Select a template")}
                getOptionLabel={this.display}
                options={availableTemplates}
                allowClear={true}
                value={selectedTemplate}
                onChange={this.onChangeTemplate}
              />
            }
          />
        ) : undefined}
        {entity ? (
          <VerticalField
            className="x-site-language qa-language"
            label={_("Language")}
            input={
              <LanguageSelector
                value={value.culture}
                cultures={templateLanguages || []}
                onChange={this.onLanguageChange}
              />
            }
          />
        ) : undefined}
        <RequiredField
          label={_("Email Subject")}
          className="x-padding-bottom-10"
          value={value.subject}
          input={
            <StringInput
              className="qa-email-subject"
              value={value.subject}
              onChange={this.onSubjectChange}
            />
          }
        />
        <VerticalField
          className="qa-body"
          label={_("Email Body")}
          input={
            <LazyTextEditor
              value={value.body}
              height="150px"
              onChange={this.onChangeEmailBody}
            />
          }
        />
      </div>
    );
  }
}
