import * as R from "ramda";
import { Component } from "react";
import { arrayToString } from "common/utils/array";
import { getFormWithResolvedDefaults } from "common/form/defaults";
import { KeysOf, Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { findRecordFromProperties } from "common/record/utils";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { ActionButton } from "common/ui/buttons";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { ValueProps } from "common/with-value-for";
import { TableWithFormValue } from "./table-with-form/types";

interface PropTypes extends ValueProps<TableWithFormValue> {
  context: Context;
  entity: Entity;
  defaultForm: Properties;
}

export class FormSwitcher extends Component<PropTypes> {
  switchForm = (form: Form) => {
    return () => {
      const { context, entity, defaultForm = {}, value, onChange } = this.props;
      const { form: oldForm = {}, records } = value;
      const columns = form
        ? R.chain(
            (g) => g.columns.map((c) => c.columnName),
            form?.settings?.groups,
          )
        : (R.keys(defaultForm) as KeysOf<Properties>);

      const filteredForm = R.pick(columns, oldForm);
      const oldRecord = findRecordFromProperties(oldForm, records);
      const formDefaults = form?.settings?.defaults;

      const newForm = R.mergeAll([
        formDefaults,
        oldRecord ? oldRecord.properties : {},
        filteredForm,
        form ? { formId: form.id } : defaultForm,
      ]);

      getFormWithResolvedDefaults(
        context,
        entity,
        form,
        undefined,
        newForm,
      ).then((formValue) =>
        onChange({ ...value, form: formValue?.record?.properties ?? newForm }),
      );
    };
  };

  render() {
    const { context, entity, value } = this.props;

    const forms = filterFormsByEntity(context.forms, entity.name);
    const currentForm = value?.form?.formId;

    return forms.length > 1 ? (
      <DropdownMenu
        button={
          <ActionButton>
            {_("Switch form")} <i className="fa fa-caret-down" />
          </ActionButton>
        }
        menu={forms.map((form) => {
          const className = arrayToString([
            "dropdown-item",
            currentForm === form.id ? "active" : undefined,
          ]);
          return (
            <a
              className={className}
              key={form.id}
              onClick={this.switchForm(form)}
            >
              {form.label}
            </a>
          );
        })}
      />
    ) : null;
  }
}
