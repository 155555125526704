interface PropTypes {
  label: string;
  icon?: string;
  clickHandler: () => void;
}

export const ActionItemButton = ({
  label,
  icon = "fa fa-times-circle-o",
  clickHandler,
}: PropTypes) => (
  <span
    className={`x-float-right x-action-item qa-action-${label.replace(
      /\s/g,
      "",
    )}`}
    onClick={clickHandler}
  >
    <i className={`x-font-large x-highlight ${icon}`} />
    {label}
  </span>
);
