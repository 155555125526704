import { Context } from "common/types/context";
import { RecordDeletedEvent } from "common/types/events";
import { EmptyDetails } from "./empty";
import { ReasonDetails } from "./reason";

interface PropTypes {
  event: RecordDeletedEvent;
  context: Context;
  hideLinks?: boolean;
}

export const RecordDeleted = ({ event, context, hideLinks }: PropTypes) => (
  <div>
    <legend>{_("Details")}</legend>
    <EmptyDetails event={event} uiFormat={context.uiFormat} />
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
  </div>
);

RecordDeleted.displayName = "RecordDeleted";
