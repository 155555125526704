import { Component, createRef } from "react";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { getColumn } from "common/entities";
import { isCustomOrSystemFk } from "common/entities/entity-column/functions";
import { Entity } from "common/entities/types";
import { LookupConfiguration, MappedField } from "common/form/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { getFkId, getSubFKTitle } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { arrayToString } from "common/utils/array";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { Selector } from "common/widgets/selector";
import { SelectorOption } from "common/widgets/selector/types";
import { ValueProps } from "common/with-value-for";
import { getEmptyOption, getLevelQuery, unwrap } from "./functions";

interface InternalPropTypes extends ValueProps<Properties> {
  context: Context;
  entity: Entity;
  index: number;
  mainFkColumnName: string;
  targetEntityName: string;
  mappedFields: MappedField[];
  previousLvlName: string;
  level: MappedField;
  unwrap: (value: FkValue) => ForeignKey;
  readOnly?: boolean;
}

interface StateType {
  options: ForeignKey[];
  menuIsOpen: boolean;
}

const getFkOptionLabel = (option: SelectorOption<ForeignKey>) =>
  isGroupedOption(option) ? option.label : getSubFKTitle(option.title);

class InternalLevel extends Component<InternalPropTypes, StateType> {
  drilldownLevelRef = createRef<Selector<ForeignKey>>();
  state: StateType = {
    options: undefined,
    menuIsOpen: undefined,
  };

  componentDidMount() {
    const { value, previousLvlName } = this.props;
    if (!previousLvlName || !!value[previousLvlName]) this.fetchOptions();
  }

  componentDidUpdate(prevProps: InternalPropTypes) {
    if (this.props.index === 0) return;
    const { level, value, onChange, previousLvlName } = this.props;

    const previousChanged =
      value[previousLvlName] !== prevProps.value[previousLvlName];
    if (!previousChanged) return;

    if (value[previousLvlName]) {
      this.fetchOptions().then((options) => {
        if (options.length === 1) {
          // only 1 possible option, set it
          onChange({ ...value, [level.columnName]: options[0] });
        } else if (options.length > 1) {
          this.setState({ menuIsOpen: true });
        }
      });
    } else {
      this.setState({ options: undefined, menuIsOpen: undefined });
    }
  }

  fetchOptions = () => {
    const {
      index,
      targetEntityName,
      mappedFields,
      context,
      value,
      unwrap,
      level: { targetColumnName },
    } = this.props;

    const query = getLevelQuery(
      targetEntityName,
      targetColumnName,
      mappedFields,
      value,
      index,
    );

    return searchApi(context.apiCall)
      .runQueryFkExpansion(query)
      .then((results: Properties[]) => {
        const options = results.map((properties) =>
          unwrap(properties[targetColumnName]),
        );

        this.setState({ options });
        return options;
      });
  };

  onLevelChange = (levelValue: ForeignKey) => {
    const { mappedFields, mainFkColumnName, value, index, level, onChange } =
      this.props;

    const fieldsToReset = mappedFields
      .slice(index + 1)
      .reduce((acc, f) => ({ ...acc, [f.columnName]: undefined }), {});

    onChange({
      ...value,
      ...fieldsToReset,
      [mainFkColumnName]: undefined, // main field/fk cleared too
      [level.columnName]: levelValue,
    });
  };

  getOptions = () => {
    const { value, level } = this.props;
    const { options } = this.state;
    const levelValue: FkValue = value[level.columnName];
    const levelValueId = getFkId(levelValue);

    const selected =
      levelValue && options?.find((option) => option.id === levelValueId);

    if (levelValue && !selected) {
      // if level value doesn't match with any available option we insert it just
      // to render it nicely. user can select another valid option but can't go back to invalid.
      return {
        selected: levelValue,
        options: (options ?? []).concat(levelValue as ForeignKey),
      };
    } else {
      return { selected, options };
    }
  };

  render() {
    const {
      entity,
      context,
      readOnly,
      level: { columnName },
      value,
    } = this.props;
    const { menuIsOpen } = this.state;
    const { options, selected } = this.getOptions();
    const levelColumn = getColumn(entity, columnName);
    const className = arrayToString([
      `qa-${columnName}`,
      levelColumn?.required && !selected ? "x-has-error" : undefined,
    ]);

    return (
      <VerticalField
        key={columnName}
        className={className}
        label={getLocalizedName(levelColumn)}
        input={
          readOnly ? (
            <div className="x-read-only-label-wrapper">
              <LabelWidget
                context={context}
                column={levelColumn}
                value={value[columnName]}
              />
            </div>
          ) : (
            <Selector
              ref={this.drilldownLevelRef}
              getOptionLabel={getFkOptionLabel}
              allowClear={true}
              options={options}
              menuIsOpen={menuIsOpen}
              value={selected}
              onChange={this.onLevelChange}
            />
          )
        }
      />
    );
  }
}

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  entity: Entity;
  index: number;
  mainFkColumnName: string;
  lookupConfiguration: LookupConfiguration;
  readOnly?: boolean;
}

export const DrilldownLevel = ({
  context,
  entity,
  index,
  readOnly,
  mainFkColumnName,
  lookupConfiguration,
  value,
  onChange,
}: PropTypes) => {
  const { targetEntity, mappedFields } = lookupConfiguration;
  const level = mappedFields[index];
  const levelColumn = getColumn(entity, level.columnName);
  const unwrapLevel = (value: FkValue) => {
    return value
      ? unwrap(value, isCustomOrSystemFk(levelColumn))
      : getEmptyOption();
  };

  return (
    <InternalLevel
      context={context}
      entity={entity}
      readOnly={readOnly}
      unwrap={unwrapLevel}
      mainFkColumnName={mainFkColumnName}
      targetEntityName={targetEntity}
      index={index}
      mappedFields={mappedFields}
      previousLvlName={mappedFields[index - 1]?.columnName}
      level={level}
      value={value}
      onChange={onChange}
    />
  );
};
