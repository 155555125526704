import * as React from "react";
import * as R from "ramda";
import { FunctionSelector } from "common/query-builder/function-selector";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { Entities } from "common/entities/types";
import { Expression } from "common/query-builder/expression";
import { getField } from "common/query-builder/functions";
import { Item } from "common/query-builder/item";
import { NotFound } from "common/query-builder/not-found";
import { Field } from "common/query-builder/types";
import { SelectError } from "common/query-builder/validation";
import {
  isSelectExpressionWithoutValues,
  isSelectFieldWithoutValues,
  isSummaryFieldWithoutValues,
  SelectItem,
} from "common/query/types";
import {
  filterErrorExpressions,
  filterErrorFieldItems,
  isFieldFromOneToManyJoin,
  noError,
} from "./functions";

interface ItemPropTypes {
  context: Context;
  entities: Entities;
  fields: Field[];
  errors: SelectError[];
  allowAggregates: boolean;
  forceFkAggregates: boolean;
  index: number;
  noAliases?: boolean;
}

export class SelectListItem extends Item<SelectItem, ItemPropTypes> {
  static readonly displayName = "SelectItem";

  onExpressionChange = (expression: string) => {
    this.mergeValue({ expression: expression || undefined });
  };

  onFunctionChange = (fn: string) => {
    this.mergeValue({ fn: fn || undefined });
  };

  onAliasChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = this.props;

    if (!isSummaryFieldWithoutValues(value)) {
      const alias = e.target.value || undefined;
      this.mergeValue({ alias, label: alias });
    }
  };

  render() {
    const {
      context,
      fields,
      allowAggregates,
      forceFkAggregates,
      errors,
      noAliases,
      entities,
      value,
    } = this.props;

    const fieldValue = isSelectFieldWithoutValues(value) ? value : undefined;
    const expressionValue = isSelectExpressionWithoutValues(value)
      ? value
      : undefined;
    const summaryValue = isSummaryFieldWithoutValues(value) ? value : undefined;

    const fieldErrors = fieldValue
      ? filterErrorFieldItems(errors, fieldValue)
      : expressionValue
        ? filterErrorExpressions(errors, expressionValue)
        : [];

    const invalidAlias = R.find((e) => e.part === "invalid", fieldErrors);
    const duplicatedAlias = R.find((e) => e.part === "duplicated", fieldErrors);
    const tooLongAlias = R.find((e) => e.part === "tooLong", fieldErrors);

    const invalidMessage = invalidAlias ? (
      <div className="x-alias-error">{invalidAlias.message}</div>
    ) : duplicatedAlias ? (
      <div className="x-alias-error">{duplicatedAlias.message}</div>
    ) : tooLongAlias ? (
      <div className="x-alias-error">{tooLongAlias.message}</div>
    ) : undefined;

    const message: string = R.compose(
      R.join(" "),
      R.uniq,
      R.map(R.prop("message")),
      (errs: SelectError[]) =>
        R.filter<SelectError>((e) => e.part === "alias", errs),
    )(fieldErrors);

    const alias = noAliases ? undefined : (
      <div
        className={
          "x-flex-grow-1 x-margin-left-10 qa-select-alias" +
          (invalidMessage || message ? " x-has-error" : "")
        }
      >
        <input
          placeholder={message || _("Label")}
          value={
            fieldValue?.label ||
            expressionValue?.label ||
            fieldValue?.alias ||
            expressionValue?.alias ||
            ""
          }
          onChange={this.onAliasChange}
        />
        {invalidMessage}
      </div>
    );

    if (summaryValue) {
      return (
        <div className="x-flex x-summary-field qa-summary-field">
          {getLocalizedName(entities[summaryValue.entityName])}
        </div>
      );
    }

    if (expressionValue) {
      const error = (
        R.find((e) => e.part === "expression", fieldErrors) || noError
      ).message;
      return (
        <div className="x-flex">
          <Expression
            context={context}
            className={
              "x-flex-grow-2 x-select-expression qa-select-expression" +
              (error ? " x-has-error" : "")
            }
            placeholder={error}
            fields={fields}
            onChange={this.onExpressionChange}
            value={expressionValue.expression || ""}
          />
          {alias}
        </div>
      );
    }

    const field = getField(fields, fieldValue);
    if (!field) {
      const message = fieldValue?.name || _("Select column");
      return <NotFound message={message} />;
    }

    const fieldLabel = `${field.minPath}.${getLocalizedName(field.column)}`;

    const fkWithForcedAggregates =
      forceFkAggregates && isFieldFromOneToManyJoin(fieldValue.path, entities);

    return (
      <div className="x-flex x-select-field qa-select-field">
        <input className="x-flex-grow-1" value={fieldLabel} readOnly={true} />
        {allowAggregates || fkWithForcedAggregates ? (
          <FunctionSelector
            className="x-flex-grow-1 x-margin-left-10"
            column={field.column}
            allowEmpty={!fkWithForcedAggregates}
            value={fieldValue.fn}
            onChange={this.onFunctionChange}
          />
        ) : undefined}
        {alias}
      </div>
    );
  }
}
