import * as React from "react";
import * as R from "ramda";
import { required as hasValue } from "common/validate";
import { getUniqueId } from "common";
import { DataType } from "common/entities/entity-column/data-type/types";
import { classNames } from "common/utils/array";

interface PropTypes {
  value: any;
  className?: string;
  inputId?: string;
  disabled?: boolean;
  dataType?: DataType;
  label?: string;
}

const childProps = (
  element: any,
  disabled: boolean,
  id: string,
  label: string,
) => ({
  label: element.props?.label ?? label,
  ...(disabled !== undefined ? { disabled } : undefined),
  ...(R.type(element.type) === "Function" ? { id } : undefined),
});

export const Required = ({
  value,
  children,
  className = "",
  inputId,
  disabled,
  dataType,
  label,
}: React.PropsWithChildren<PropTypes>) => {
  const id = inputId || getUniqueId();

  const childArray = React.Children.toArray(children).map((c: any) =>
    React.cloneElement(c, childProps(c, disabled, id, label)),
  );
  return (
    <div
      className={classNames([
        className,
        !hasValue(value, dataType) ? "x-has-error" : undefined,
      ])}
    >
      {childArray}
    </div>
  );
};

Required.displayName = "Required";
