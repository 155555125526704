import { v4 as uuid } from "uuid";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { getSelectFieldByTitle } from "common/query/select";
import { ForeignKey } from "common/types/foreign-key";
import { Properties, RecordPayload } from "common/types/records";
import { getFormByIdOrEntity } from "common/functions/forms";
import { mergeWithDefaultListQuery } from "common/query/default";
import { Query, QueryForEntity } from "common/query/types";

export const mapAssetsToRecords = (
  assets: Properties[],
  assetsEntity: Entity,
): RecordPayload[] => {
  return (
    assets.map((asset) => {
      const { id, number } = asset;
      const titleColumn = getSelectFieldByTitle(assetsEntity.query.select);
      const assetId: ForeignKey = {
        id,
        number,
        title: asset?.[titleColumn.name],
      };

      return {
        properties: {
          status: "P",
          assetId,
          tempId: uuid(),
        },
      };
    }) || []
  );
};

export const extractCommonColumns = (
  context: Context,
  entity: Entity,
  properties: Properties,
) => {
  const groups =
    getFormByIdOrEntity(context.forms, entity.name)?.settings?.groups || [];

  const allColumns = groups
    .flatMap((c) => c.columns)
    .filter((c) => properties?.[c.columnName]);

  return allColumns.reduce(
    (acc, column) => ({
      ...acc,
      [column.columnName]: properties?.[column.columnName],
    }),
    {},
  );
};

export const getDefaultQueryForEntity = (
  context: Context,
  entity: Entity,
): QueryForEntity => {
  const { entities, preferenceService, site } = context;
  const queryFromPreference: Query = preferenceService
    .get()
    ?.list.find(
      (pref) => pref.entity === entity.name && pref.site === site.name,
    )?.lastFilter?.filter?.query;

  return mergeWithDefaultListQuery(
    entities,
    entity?.name,
    entity?.query,
    queryFromPreference,
  );
};
