import { FormSelector } from "common/form/form-selector";
import { filterFormsByEntity } from "common/functions/forms";
import { Action } from "common/record/actions/action";
import { getRedirectUrl } from "common/record/actions/functions/url";
import {
  DismissAction,
  PropTypes as ActionPropTypes,
} from "common/record/actions/types";
import {
  ApproveDependencies,
  ApproveValue,
} from "common/record/actions/ui/approve/types";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { VerticalField } from "common/ui/field";
import { ValueComponent } from "common/with-value-for";

interface PropTypes extends ActionPropTypes {
  dependencies?: ApproveDependencies;
}

export class ApproveAction extends ValueComponent<ApproveValue, PropTypes> {
  static readonly displayName = "ApproveAction";

  onOk = (_: ApiCall, dismiss: DismissAction) => {
    const { goTo, context, value, entity, dependencies, onPerform } =
      this.props;
    const { entities } = context;

    onPerform?.();
    const redirectUrl = getRedirectUrl(
      context,
      dependencies?.fullRecord?.properties,
      value?.formId,
      entities[entity.arguments?.workOrderEntity],
    );

    if (goTo && redirectUrl) goTo(redirectUrl);
    return CancellablePromise.resolve(dismiss);
  };

  render() {
    const { context, dismiss, entity, records = [], value } = this.props;
    const { forms } = context;

    const woEntityName = entity.arguments?.workOrderEntity;
    const entityForms = filterFormsByEntity(forms, woEntityName);

    return (
      <Action
        requiresAuthentication={false}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Approve")}
        btnLabel={_("Approve")}
        onOk={this.onOk}
      >
        {entityForms.length > 1 ? (
          <VerticalField
            label={_("Form")}
            input={
              <FormSelector
                forms={entityForms}
                onChange={this.onChangeMergeValue("formId")}
                preselectFirstByDefault={true}
                value={value?.formId}
              />
            }
          />
        ) : undefined}
      </Action>
    );
  }
}
