import * as R from "ramda";
import { Culture, EN_US } from "common/culture/supported-cultures";
import { merge2 } from "common/merge";
import {
  isSummaryField,
  Query,
  SelectExpression,
  SelectField,
} from "common/query/types";
import { Report } from "common/types/reports";
import { cleanUp, translateName } from "./functions";
import { Captions } from "./types";

export const translateKey =
  (report: Report) =>
  (key: string): string =>
    key === "name"
      ? report.label || report.name || _("Name")
      : key.replace(/^alias\.*/, "");

export const translateQuery = (captions: Captions, query: Query): Query => {
  if (!query || !captions) return query;

  const select = (query.select || []).map(
    (f: SelectField | SelectExpression) => {
      if (!f.alias) return f;

      const label = captions[`alias.${f.alias}`];
      if (!label) return f;
      return R.mergeRight(f, { label });
    },
  );

  return R.mergeRight(query, { select });
};

export const translate = (culture: Culture, report: Report): Report => {
  const { label, labels, id, query } = report;
  const captions = labels ? labels[culture] || labels[EN_US] : {};

  return R.mergeRight(report, {
    label: R.isEmpty(captions || {})
      ? label || (id && `${_("Report")} ${id}`) || ""
      : translateName(captions),
    query: translateQuery(captions, query),
  });
};

const getSelectsWithAlias = (
  report: Report,
): Array<SelectField | SelectExpression> =>
  report && report.query && report.query.select
    ? (
        (report.query.select as Array<SelectField | SelectExpression>) || []
      ).filter((s) => !isSummaryField(s) && !!s.alias)
    : [];

// --- Extract -------------------------------------------------------------- //

export const extractReportCaptions = (report: Report): Captions => {
  return report && report.query
    ? R.fromPairs(
        getSelectsWithAlias(report)
          .map((s: SelectField | SelectExpression) => {
            return [`alias.${s.alias}`, s.label] as [string, string];
          })
          .concat(report.name ? [["name", report.name]] : []),
      )
    : {};
};

export const getKeys = (report: Report): string[] =>
  report ? Object.keys(extractReportCaptions(report)) : [];

const cleanUpLabels = cleanUp(getKeys);

export const extract = (culture: Culture, report: Report): Report => {
  return report
    ? cleanUpLabels(
        merge2("labels", culture, extractReportCaptions(report), report),
      )
    : undefined;
};
