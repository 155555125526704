import { CancellablePromise, pseudoCancellable } from "common/types/promises";

export const asyncScriptLoader = (
  url: string,
  id: string,
): CancellablePromise<void> => {
  return pseudoCancellable(
    new Promise<void>((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = url;
      script.id = id;
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject();
      };
      document.body.appendChild(script);
    }),
  );
};
