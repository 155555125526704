import { ActionsSelector } from "common/record/actions/components/selector";
import { Action, ActionsGroup } from "common/record/actions/types";
import { Ribbon, RibbonButtons } from "x/layout/ribbon";
import { Crumb } from "x/layout/ribbon/breadcrumb";

export const CREATE_PURCHASE_ORDER_ACTION = "CREATE_PURCHASE_ORDER_ACTION";

interface PropTypes {
  onRefresh: () => void;
  onActionSelect: (action: Action) => void;
  loadingRecords: boolean;
  showActionsButton: boolean;
  action?: Action;
}

export const PartsReorderRibbon = ({
  onRefresh,
  loadingRecords,
  onActionSelect,
  showActionsButton,
  action,
}: PropTypes) => {
  const crumbs: Crumb[] = !loadingRecords
    ? [
        { name: _("Parts Reorder List") },
        ...(action ? [{ name: action.label }] : []),
      ]
    : [{ name: `${_("Loading")}...` }];

  const actionsGroup: ActionsGroup = [
    "common",
    [
      {
        label: _("Create Purchase Order"),
        name: CREATE_PURCHASE_ORDER_ACTION,
      },
    ],
  ];

  return (
    <Ribbon
      className="x-parts-reorder-ribbon"
      crumbs={crumbs}
      onRefresh={action ? undefined : onRefresh}
    >
      {showActionsButton && !action ? (
        <RibbonButtons>
          <ActionsSelector
            displayType="label"
            onTheRight={true}
            actionGroups={[actionsGroup]}
            onActionSelect={onActionSelect}
          />
        </RibbonButtons>
      ) : undefined}
    </Ribbon>
  );
};

PartsReorderRibbon.displayName = "PartsReorderRibbon";
