import { JSX, PropsWithChildren } from "react";
import { isElement } from "react-dom/test-utils";
import { BadgeType } from "common/types/badges";
import { ModifyLinkButton } from "common/ui/buttons";
import { classNames } from "common/utils/array";
import { Highlight } from "common/widgets/highlight";
import { Link } from "common/widgets/link";

interface PropTypes {
  label: string;
  editPath: string;
  linkLabel?: string;
  className?: string;
  actions?: JSX.Element[];
  disabled?: boolean;
  badge?: string | JSX.Element;
  badgeType?: BadgeType;
}

const getBadge = (badge: string | JSX.Element, badgeType: BadgeType) =>
  typeof badge === "string" ? (
    <div className="x-badge-container">
      <span className={classNames(["badge qa-list-item-badge", badgeType])}>
        {badge}
      </span>
    </div>
  ) : isElement(badge) ? (
    badge
  ) : undefined;

export const ListItem = ({
  label,
  editPath,
  linkLabel,
  className = "",
  children,
  actions,
  badge,
  badgeType,
  disabled,
}: PropsWithChildren<PropTypes>) => (
  <div className={`clearfix ${className}`} data-testid="list-item">
    <div className="x-list-grid-item-label">
      {disabled || !editPath ? (
        <Highlight text={label} />
      ) : (
        <Link className="x-list-grid-view-link" href={editPath}>
          <Highlight text={label} />
        </Link>
      )}
      {badge ? getBadge(badge, badgeType) : undefined}
    </div>
    <div className="x-list-grid-buttons">
      {editPath ? (
        <ModifyLinkButton
          className="qa-edit-item x-margin-left-10"
          href={!disabled ? editPath : undefined}
          disabled={disabled}
        >
          <i className="fa fa-cog x-margin-right-5" />
          {linkLabel ? linkLabel : `${_("Edit")}`}
        </ModifyLinkButton>
      ) : undefined}
      {actions || undefined}
    </div>
    <div className="x-list-grid-item-cells">{children}</div>
  </div>
);

ListItem.displayName = "ListItem";
