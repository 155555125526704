import { ApiCall } from "common/types/api";
import { defaultFor } from "common";
import { searchApi } from "common/api/search";
import { Action } from "common/record/actions/action";
import { PropTypes, DismissAction } from "common/record/actions/types";
import { Email } from "common/types/records";
import { hasProtectedColumns } from "common/record/utils";
import { EmailAction, isValid } from "common/widgets/email-action";
import { withValue, WithValue } from "common/with-value";

export interface EmailListValue {
  emailForm: Email;
}
type Props = PropTypes & WithValue<EmailListValue>;

const SendEmailModalList = ({
  context,
  entity,
  query,
  records,
  dismiss,
  value = defaultFor<EmailListValue>(),
  onChangeMergeValue,
}: Props) => {
  const onOk = (apiCall: ApiCall, dismiss: DismissAction) =>
    searchApi(apiCall).sendResults(value.emailForm, query).then(dismiss);

  return (
    <Action
      requiresAuthentication={hasProtectedColumns(entity)}
      context={context}
      dismiss={dismiss}
      entity={entity}
      records={records}
      title={_("E-mail")}
      size="large"
      btnLabel={_("Send")}
      onOk={isValid(value.emailForm) && onOk}
      hideReason={true}
    >
      <EmailAction
        context={context}
        entity={entity}
        value={value.emailForm}
        onChange={onChangeMergeValue("emailForm")}
      />
    </Action>
  );
};

export const EmailList = withValue<EmailListValue, PropTypes>(
  SendEmailModalList,
  "SendEmailModalList",
);
