import { defaultFor } from "common";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { createColumn } from "common/form/functions/group-column";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "common/form/group/field";
import { FormValidationProps } from "common/form/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { ValueProps } from "common/with-value-for";
import { ExceptionFieldToggle } from "./exception-field-toggle";
import { getQueryForMeterColumn } from "./functions";
// eslint-disable-next-line import/no-cycle
import { WorkOrderGenerationFields } from "./work-order-generation-fields";

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  entity: Entity;
  withLinks: boolean;
}

export const ConditionMonitoringForm = ({
  context,
  entity,
  withLinks,
  formValidation,
  onFormValidationChange,
  value = defaultFor<Properties>(),
  onChange,
}: PropTypes) => {
  const meterIdColumn = getColumn(entity, "meterId");
  return (
    <>
      <GroupField
        context={context}
        entity={entity}
        groups={[]}
        layoutColumn={createColumn(getColumn(entity, "meterId"))}
        withLinks={withLinks}
        widgetsProps={
          meterIdColumn
            ? {
                query: getQueryForMeterColumn(
                  context,
                  entity,
                  meterIdColumn,
                  value.meterId,
                ),
              }
            : undefined
        }
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
      <ExceptionFieldToggle
        key="upperBound"
        context={context}
        entity={entity}
        column={getColumn(entity, "upperBound")}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
      <ExceptionFieldToggle
        key="lowerBound"
        context={context}
        entity={entity}
        column={getColumn(entity, "lowerBound")}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
      <WorkOrderGenerationFields
        context={context}
        entity={entity}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
      {entity.columns
        .filter((column) => !column.isSystem)
        .map((column) => (
          <GroupField
            key={column.name}
            context={context}
            entity={entity}
            groups={[]}
            layoutColumn={createColumn(column)}
            withLinks={withLinks}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value}
            onChange={onChange}
          />
        ))}
    </>
  );
};
