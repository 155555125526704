import * as R from "ramda";
import { noOp } from "common";
import { Record } from "common/types/records";
import { TableWithReorder } from "common/query/table";
import { TableValue } from "common/query/table/types";
import { isOrderField, OrderField, QueryForEntity } from "common/query/types";
import { ValueComponent, ValueProps } from "common/with-value-for";
import {
  getConfig,
  getQuery,
  setWidthsToPreferences,
  updateRecordsWithProperties,
} from "./functions";
import { PropTypes } from "./types";

interface StateType {
  query?: QueryForEntity;
  expandedAction?: { actionName: string; recordId: string };
  widths: number[];
}

type Props = PropTypes & ValueProps<Record[]>;

export class RelatedEntityTable extends ValueComponent<
  Record[],
  PropTypes,
  StateType
> {
  static readonly displayName = "RelatedEntityTable";

  state: StateType = { widths: [] };

  componentDidMount() {
    this.setState({ widths: this.getWidthsFromPreferences() });
  }

  componentDidUpdate(prevProps: Props) {
    const oldRelatedEntity = this.props.entity;
    const newRelatedEntity = prevProps.entity;

    if (oldRelatedEntity !== newRelatedEntity) {
      this.setState({ query: undefined });
    }
  }

  getWidthsFromPreferences = () => {
    // could be memoized
    const { context, parentEntityName, entity } = this.props;

    const relatedPrefs = R.find(
      (setting) =>
        setting.entityName === parentEntityName &&
        setting.site === context.site.name,
      context.preferenceService.get().related,
    );
    return relatedPrefs?.widths[entity.name];
  };

  onTableChanged = (tableValue: TableValue) => {
    const { value = [], parentEntityName, entity, context } = this.props;
    const { query, expandedAction, data, widths } = tableValue;

    if (widths)
      setWidthsToPreferences(
        parentEntityName,
        context.site.name,
        entity.name,
        widths,
        context.preferenceService,
      );

    this.setState({ ...(widths && { widths }), query, expandedAction });
    this.setValue(updateRecordsWithProperties(value, data));
  };

  hideExpandedAction = () => {
    this.setState({ expandedAction: undefined });
  };

  getData = () => {
    const { value = [] } = this.props;
    const query = this.state.query || getQuery(this.props);
    const { order } = query.query;

    const orderBy = R.find((o) => isOrderField(o), order) as OrderField;
    const field = orderBy?.name || "number";
    const orderedRecords = R.sortBy((r) => r.properties[field], value);

    const records = orderBy?.desc ? R.reverse(orderedRecords) : orderedRecords;

    return records.map((r) => r.properties);
  };

  render() {
    const {
      context,
      readOnly,
      highlighted,
      widgetsMapper,
      withLinks = true,
    } = this.props;
    const { query, expandedAction, widths } = this.state;

    const tableQuery = query || getQuery(this.props);
    const config = getConfig(this.props, tableQuery, context.entities);
    const data = this.getData().filter((r) => !r.isDeleted);

    return (
      <div className="x-related-table">
        <TableWithReorder
          isLoading={false}
          highlighted={highlighted}
          starred={[]}
          toggleStar={undefined}
          goTo={undefined}
          withLinks={withLinks}
          reload={noOp}
          context={context}
          config={config}
          readOnly={readOnly}
          withTotals={true}
          widgetsMapper={widgetsMapper}
          value={{
            data,
            query: tableQuery,
            secondaryQueries: undefined,
            widths,
            expandedAction,
            selected: [],
          }}
          onChange={this.onTableChanged}
        />
      </div>
    );
  }
}
