import * as R from "ramda";
import { cloneElement, JSX } from "react";
import { defaultFor } from "common";
import { Component } from "common/component";
import { dataDog } from "common/monitoring/datadog";
import { CancellablePromise } from "common/types/promises";
import { KeysOf } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";

export interface ResolvedDependencies {
  [index: string]: any;
}

type PromisedDependencies<T> = {
  [P in keyof T]: CancellablePromise<T[P]>;
};

interface PropTypes<T> {
  dependencies: PromisedDependencies<T>;
  child: JSX.Element;
  mapDependencies?: (dependencies: T) => T;
  loadingMessage?: string;
  className?: string;
}

interface StateType<T> {
  dependencies: T;
  error?: any;
}

export const dependenciesInjected = <T extends ResolvedDependencies>(): Pick<
  StateType<T>,
  "dependencies"
> => ({ dependencies: undefined as T });

function resolveDependencies<T>(
  deps: PromisedDependencies<T> = defaultFor<PromisedDependencies<T>>(),
): CancellablePromise<T> {
  return CancellablePromise.all(R.values(deps)).then((values) =>
    R.zipObj(R.keys(deps) as KeysOf<PromisedDependencies<T>>, values),
  ) as CancellablePromise<T>;
}

export class DependenciesComp<T = ResolvedDependencies> extends Component<
  PropTypes<T>,
  StateType<T>
> {
  static readonly displayName = "DependenciesComp";
  state: StateType<T> = {
    ...dependenciesInjected<T>(),
    error: undefined,
  };
  fetchDependenciesRequest: CancellablePromise<unknown>;

  componentDidMount() {
    this.loadDependencies();
  }

  componentWillUnmount() {
    this.fetchDependenciesRequest?.cancel();
  }

  loadDependencies = () => {
    const { dependencies = defaultFor<PromisedDependencies<T>>() } = this.props;

    this.fetchDependenciesRequest = resolveDependencies<T>(dependencies)
      .then((dependencies) => this.setState({ dependencies }))
      .catch((error) => {
        dataDog.logCustomError(error);

        // this setState will often fail, because the previous setState
        // encountered an error and left the app in a bad state. This is why
        // we log the error first.
        this.setState({ error });
      });
  };

  render() {
    const { mapDependencies, loadingMessage, className } = this.props;
    const { dependencies, error } = this.state;

    const mappedDependencies = mapDependencies
      ? mapDependencies(dependencies)
      : dependencies;

    return (
      <div className={className}>
        {error ? (
          <ApiError error={error} />
        ) : dependencies ? (
          cloneElement(this.props.child, {
            dependencies: mappedDependencies,
          })
        ) : (
          <LoadingIcon message={loadingMessage} />
        )}
      </div>
    );
  }
}
