import { getLocationOrigin } from "common/utils/window-location";

export const AUTH_CONFIG = {
  domain: "fluke-sso.eu.auth0.com",
  callbackUrl: getLocationOrigin() + "/login?sso=true",
  returnToUrl: getLocationOrigin() + "/login",
  defaultLogo: getLocationOrigin() + "/api/branding/desktop",
  // this namespace is used for custom claims in id token defined in Auth0
  namespace: "https://www.x5.com/",
  defaultScope: "openid login:sso",
  audience: `https://x5.com/api`,
  responseType: "token id_token",
};

export const getLogoutParams = (auth0ClientId: string) => ({
  clientId: auth0ClientId,
  logoutParams: {
    returnTo: AUTH_CONFIG.returnToUrl,
    federated: true,
  },
});
