import * as R from "ramda";
import { getLocalizedName } from "common";
import { reportsApi } from "common/api/reports";
import { Entities } from "common/entities/types";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import { createInjected } from "common/ui/controllers/list";
import { ExternalPropTypes } from "x/controllers/list/ribbon-wrapper";
import { listCtrlWithRibbon } from "x/controllers/list/with-ribbon";
import { MiniReport, MiniReportProps } from "x/reports/list/types";
import { List } from "./list";

interface PropTypes extends ExternalPropTypes {
  newPath: string;
  editPath: string;
  previewPath: string;
  excludeGlobal: boolean;
  context: Context;
}

const ListController = listCtrlWithRibbon<string, MiniReport>();
const injected = createInjected<string, MiniReport>();

const convertReportsToMiniReports =
  (entities: Entities) =>
  (reports: Report[]): MiniReport[] =>
    reports.map((report) =>
      R.mergeRight(
        R.pick<Report, MiniReportProps>(
          ["id", "name", "label", "query", "entity"],
          report,
        ),
        { entityLabel: getLocalizedName(entities[report.entity]) },
      ),
    );

/**
 * This component is used to list all available reports in the Reports section
 */
export const ReportListController = (props: PropTypes) => {
  const api = reportsApi(
    props.context.apiCall,
    props.context.uiFormat.culture,
    props.context.entities,
    props.context.site.name,
  );
  const fetch = props.excludeGlobal ? api.excludeGlobal : api.list;

  const wrappedFetch = () =>
    fetch().then(convertReportsToMiniReports(props.context.entities));

  return (
    <ListController
      {...props}
      fetch={wrappedFetch}
      permissionCategory="Reports"
      hideNew={!props.context.isEnabledFeature("report")}
      title={_("Reports")}
    >
      <List
        {...injected}
        context={props.context}
        editPath={props.editPath}
        previewPath={props.previewPath}
      />
    </ListController>
  );
};

ReportListController.displayName = "ReportListController";
