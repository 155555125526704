import * as R from "ramda";
import { HighlightProps, Label } from "common/widgets/label";

interface PropTypes extends HighlightProps {
  value: boolean;
  getLabel?: (value: boolean) => string;
}

export const getBooleanLabel = (value: boolean) =>
  R.isNil(value) ? undefined : value ? _("True") : _("False");

export const getBooleanFromString = (value: string) =>
  getBooleanLabel(value ? JSON.parse(value) : undefined);

export const CheckboxLabel = ({
  value,
  getLabel,
  textToHighlight,
}: PropTypes) => {
  const getValueLabel = getLabel ?? getBooleanLabel;
  return (
    <Label value={getValueLabel(value)} textToHighlight={textToHighlight} />
  );
};
CheckboxLabel.displayName = "CheckboxLabel";
