import { Component } from "react";
import { getColumn } from "common/entities";
import { isCustomOrSystemFk } from "common/entities/entity-column/functions";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { LookupPropTypes as PropTypes } from "../types";
import { MainForeignKey } from "./foreign-key";
import {
  getEmptyOption,
  hasRestrictedColumn,
  INTERNAL_EMPTY,
  unwrap,
} from "./functions";
import { DrilldownLevel } from "./level";

interface StateType {
  tempValue: Properties;
}

export class Drilldown extends Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);
    const { value, layoutColumn, mainFkColumnName } = props;
    const fkHasValue = !!value[mainFkColumnName];

    // if FK already set we replace null levels with an empty option
    const tempValue =
      layoutColumn.lookupConfiguration.mappedFields.reduce<Properties>(
        (acc, { columnName }) => {
          return {
            ...acc,
            [columnName]: value[columnName]
              ? unwrap(value[columnName], this.isLevelFk(columnName))
              : fkHasValue
                ? getEmptyOption()
                : undefined,
          };
        },
        value,
      );

    this.state = { tempValue };
  }

  isLevelFk = (columnName: string) => {
    const levelColumn = getColumn(this.props.entity, columnName);
    return isCustomOrSystemFk(levelColumn);
  };

  wrap = (columnName: string, levelValue: ForeignKey): FkValue => {
    const isEmpty = (levelValue?.id as any) === INTERNAL_EMPTY;
    return isEmpty
      ? undefined
      : this.isLevelFk(columnName)
        ? levelValue
        : levelValue?.id;
  };

  onDrilldownChange = (newValue: Properties) => {
    const { onChange, layoutColumn, value, mainFkColumnName } = this.props;

    this.setState({ tempValue: newValue });

    // removes internal state "empty" to undefined
    const drilldownLevelsValue =
      layoutColumn.lookupConfiguration.mappedFields.reduce<Properties>(
        (acc, { columnName }) => ({
          ...acc,
          [columnName]: this.wrap(columnName, newValue[columnName]),
        }),
        {},
      );

    onChange({
      ...value,
      ...drilldownLevelsValue,
      [mainFkColumnName]: newValue[mainFkColumnName],
    });
  };

  render() {
    const { context, entity, layoutColumn, mainFkColumnName } = this.props;
    const { tempValue } = this.state;
    const { lookupConfiguration } = layoutColumn || {};
    const { mappedFields } = lookupConfiguration || {};

    const readOnly = hasRestrictedColumn(
      context,
      entity,
      mappedFields,
      mainFkColumnName,
    );

    return (
      <>
        {mappedFields.map((field, index) => {
          return (
            <DrilldownLevel
              key={field.columnName}
              index={index}
              context={context}
              readOnly={readOnly}
              entity={entity}
              lookupConfiguration={lookupConfiguration}
              mainFkColumnName={mainFkColumnName}
              value={tempValue}
              onChange={this.onDrilldownChange}
            />
          );
        })}
        <MainForeignKey
          context={context}
          entity={entity}
          readOnly={readOnly}
          layoutColumn={layoutColumn}
          mainFkColumnName={mainFkColumnName}
          value={tempValue}
          onChange={this.onDrilldownChange}
        />
      </>
    );
  }
}
