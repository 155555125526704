import * as R from "ramda";
import { getSubFKTitle } from "common/functions/foreign-key";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { defaultFor } from "common";
import { Adjustment, Properties, Transfer } from "common/types/records";
import { filterByBehavior } from "common/entities";
import { setReadOnly } from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { merge1 } from "common/merge";
import { Context } from "common/types/context";
import { AdjustmentFormValue, type BatchFk, TransferFormValue } from "./types";

export const getAdjustmentEntity = (
  entities: Entities,
  entity: Entity,
): Entity =>
  R.values(filterByBehavior("Adjustment", entities)).filter(
    (e) => e.arguments.stockEntity === entity.name,
  )[0];

export const getAdjustment = (entities: Entities, entity: Entity): Entity => {
  const adjustment = getAdjustmentEntity(entities, entity);
  return adjustment
    ? merge1(
        "columns",
        adjustment.columns.filter((c) => !c.isSystem || c.name === "number"),
        adjustment,
      )
    : undefined;
};

export const hasValue = (
  entity: Entity,
  originalOnHand: number,
  hasBatchSelection: boolean,
  value: AdjustmentFormValue,
): boolean =>
  value &&
  value.onHand >= 0 &&
  value.onHand !== originalOnHand &&
  value.adjustmentType?.id !== undefined &&
  value.date !== undefined &&
  (!hasBatchSelection || value.batch?.id !== undefined) &&
  (!hasBatchSelection || value.batch.id !== "new" || value.cost) &&
  entity.columns
    .filter((c) => c.name !== "number")
    .reduce((_, c) => !c.required || !!value[c.name], true);

export const hasTransferValue = (
  onHand: number,
  hasBatchSelection: boolean,
  value: TransferFormValue,
): boolean =>
  value &&
  value.quantity > 0 &&
  value.quantity <= onHand &&
  value.date !== undefined &&
  value.destination !== undefined &&
  (!hasBatchSelection || (value.batch && value.batch.id !== undefined)) &&
  (!hasBatchSelection || value.batch.id !== "new" || value.cost);

export const formToApi = (value: AdjustmentFormValue): Adjustment => {
  const { onHand, adjustmentType, batch, date, cost, comment } = value;
  const batchId = batch && batch.id !== "new" ? batch.id : undefined;
  return {
    adjustmentType: adjustmentType.id,
    cost: batchId ? undefined : cost,
    onHand,
    batchId,
    date,
    comment,
  };
};

export const transferFormToApi = (
  value: TransferFormValue,
  stocks: Properties[],
): Transfer => {
  const { quantity, destination, date, comment, batch } = value;
  return {
    quantity,
    toStockId: R.find(R.pathEq(["locationId", "id"], destination.id), stocks)
      .id,
    date,
    comment,
    fromBatchId: batch ? batch.id : undefined,
  };
};

export const updateEntity = (entity: Entity = defaultFor<Entity>()) => {
  const { columns = [] } = entity;
  const updatedColumns = columns.map(setReadOnly(["isDefault"]));
  return merge1("columns", updatedColumns, entity);
};

export const updateDefaultForm = (
  defaultForm: Properties,
  isDefaultLocation: boolean,
) => {
  return { ...(defaultForm ?? defaultFor()), isDefault: isDefaultLocation };
};

export const getFormatBatchFn =
  (context: Context) =>
  (option: LabelledOptionOrGroup<BatchFk>, meta: FormatMeta<BatchFk>) => {
    if (isGroupedOption(option)) return option.label;

    const { id, title, number, quantity, cost } = option.value;

    const formatted =
      id === "new"
        ? getSubFKTitle(title)
        : _("Batch {BATCH_NAME} - {QUANTITY} available - {CURRENCY}{COST} each")
            .replace("{BATCH_NAME}", getSubFKTitle(title) ?? number?.toString())
            .replace("{QUANTITY}", quantity)
            .replace("{COST}", cost)
            .replace("{CURRENCY}", context.currency.symbol);

    return <ReactHighlightWords text={formatted} search={meta?.inputValue} />;
  };
