import { Component } from "react";
import * as R from "ramda";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { ValueProps } from "common/with-value-for";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { Node } from "common/widgets/tree/types";
import { flattenNodesFrom } from "common/widgets/tree/functions";
import { merge1 } from "common/merge";
import { Filter, Query, FilterRule } from "common/query/types";
import { EntityTreeController } from "./entity-tree-controller";

interface PropTypes extends ValueProps<Query> {
  context: Context;
  entity: Entity;
}

interface StateType {
  selectedNode?: Node;
}

// TODO: move to a functional location folder with index and functions
const getFkToTree = (entity: Entity, context: Context) =>
  entity.columns.filter(
    (c) =>
      c.isForeignKey &&
      behaveAs("FunctionalLocation", context.entities[c.relatedEntity]) &&
      c.relatedEntity !== entity.name,
  )[0];

const getFilterWithNodesIds = (nodes: Node[], name: string): FilterRule[] =>
  nodes.map(
    (node): FilterRule => ({
      excludeFromFkExpansion: true,
      name,
      op: "eq",
      value: merge1("id", node.name, node.data),
    }),
  );

const getInFilter = (entity: Entity, context: Context, node: Node): Filter => {
  const nodeWithChildren = flattenNodesFrom(node);
  const fkToTreeEntity = getFkToTree(entity, context);

  const functionalLocationFilter =
    node.name === "ungrouped" // TODO: RTS
      ? {
          excludeFromFkExpansion: true,
          name: fkToTreeEntity.name,
          op: "isnull",
        }
      : {
          or: getFilterWithNodesIds(nodeWithChildren, fkToTreeEntity.name),
        };

  return {
    and: [{ name: "isDeleted", op: "isfalse" }, functionalLocationFilter],
  };
};

export class FunctionalLocation extends Component<PropTypes, StateType> {
  static getDerivedStateFromProps(newProps: PropTypes): StateType {
    const { value = defaultFor() } = newProps;
    const { filter } = value;

    const defaultFilter = {
      and: [{ name: "isDeleted", op: "isfalse" }],
    };

    return R.equals(filter, defaultFilter) ? { selectedNode: undefined } : null;
  }

  static readonly displayName = "FunctionalLocation";
  state: StateType = {
    selectedNode: undefined,
  };

  getFunctionalLocationRelatedEntity = () => {
    const { context, entity } = this.props;

    const foreignKeyToTreeEntity = getFkToTree(entity, context);

    return context.entities[foreignKeyToTreeEntity.relatedEntity];
  };

  onNodeSelected = (newNode: Node) => {
    const { context, entity, value = defaultFor(), onChange } = this.props;

    const filterInIds = getInFilter(entity, context, newNode);

    const newFilter =
      newNode === this.state.selectedNode
        ? { and: [{ name: "isDeleted", op: "isfalse" }] }
        : filterInIds;

    onChange({ ...value, filter: newFilter });

    const node = newNode === this.state.selectedNode ? undefined : newNode;

    this.setState({ selectedNode: node });
  };

  mapNodes = (nodes: Node[]): Node[] =>
    nodes.concat({ name: "ungrouped", label: _("Ungrouped"), isGroup: false });

  render() {
    const { context } = this.props;
    const { selectedNode } = this.state;

    return (
      <EntityTreeController
        link={undefined}
        hasRecords={true}
        showTitle={false}
        isExpandable={true}
        withNodeIcons={true}
        showUngroup={true}
        className="x-functional-location"
        mapNodes={this.mapNodes}
        context={context}
        entity={this.getFunctionalLocationRelatedEntity()}
        value={selectedNode}
        onChange={this.onNodeSelected}
      />
    );
  }
}
