import * as React from "react";
import * as R from "ramda";
import { cloneElement, Component } from "react";
import { Context } from "common/types/context";
import { hasPermissionToCreate } from "common/functions/roles";
import { Injected } from "common/ui/controllers/list";
import { Ribbon, RibbonButtons } from "x/layout/ribbon";
import { NewButton } from "./button-new";

export interface ExternalPropTypes {
  context: Context;
}

export interface RibbonWrapperPropTypes extends ExternalPropTypes {
  newPath: string;
  permissionCategory: string;
  title: string;
  hideNew?: boolean;
  ribbonButtons?: any;
  newButton?: React.ReactElement;
}

interface PropTypes<TValue, TRecord>
  extends RibbonWrapperPropTypes,
    Injected<TValue, TRecord> {
  children?: any;
}

export class RibbonWrapper<TValue, TRecord> extends Component<
  PropTypes<TValue, TRecord>
> {
  static readonly displayName = "RibbonWrapper";

  render() {
    const {
      newPath,
      context,
      permissionCategory,
      title,
      hideNew,
      ribbonButtons,
      newButton,
      children,
      reload,
    } = this.props;
    const { userTypes, role } = context;

    const canShowNewButton =
      !hideNew && hasPermissionToCreate(userTypes, role, permissionCategory);

    return (
      <div className="x-ui-simple-list x-container-with-ribbon">
        <Ribbon onRefresh={reload} crumbs={[{ name: title }]}>
          <RibbonButtons>
            {canShowNewButton
              ? newButton ?? (
                  <NewButton
                    newPath={newPath}
                    permissionCategory={permissionCategory}
                  />
                )
              : undefined}
            {ribbonButtons
              ? cloneElement(ribbonButtons, this.props)
              : undefined}
          </RibbonButtons>
        </Ribbon>
        <div className="x-content-with-ribbon">
          {children
            ? cloneElement(children, R.omit(["children"], this.props))
            : undefined}
        </div>
      </div>
    );
  }
}
