import { Children, cloneElement, ReactNode } from "react";

interface PropTypes {
  className?: string;
  label?: string;
  error?: boolean;
  input: ReactNode;
  addOn?: ReactNode;
}

export const VerticalField = ({
  label,
  className = "",
  error,
  input,
  addOn,
}: PropTypes) => {
  if (!input) return null;

  const inputWithLabel = Children.toArray(input).map((c: any) =>
    cloneElement(c, { label: c.props?.label ?? label }),
  );

  const errorClass = error ? " x-has-error" : "";
  return (
    <fieldset className={`x-field ${className}${errorClass}`}>
      <label className="x-field-label">{label}</label>
      {addOn ? (
        <div className="x-field-input x-flex x-flex-between-start">
          <div className="x-field-input-item">{inputWithLabel}</div>
          <div className="x-field-addon">{addOn}</div>
        </div>
      ) : (
        <div className="x-field-input">
          <div className="x-field-input-item">{inputWithLabel}</div>
        </div>
      )}
    </fieldset>
  );
};

VerticalField.displayName = "VerticalField";
