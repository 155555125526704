import { QueryForEntity } from "common/query/types";

export const getPartSupplierQuery = (
  entityName: string,
  partIds: string[],
): QueryForEntity => {
  return {
    entity: entityName,
    query: {
      select: [
        { name: "number" },
        { name: "id" },
        { name: "currency" },
        { name: "unitCost" },
        { name: "supplierId" },
        { name: "id", alias: "partId", path: "/partId" },
        { name: "name", alias: "title", path: "/supplierId" },
      ],
      joins: [{ column: "supplierId" }, { column: "partId" }],
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          {
            name: "partId",
            op: "in",
            value: partIds.join(","),
            excludeFromFkExpansion: true,
          },
        ],
      },
    },
  };
};
