import { Component } from "react";
import { getLocalizedName } from "common";
import { isInteractiveMapBehaviorName } from "common/api/behavior";
import { EntityMap } from "common/data/map/entity-map";
import { getColumn, getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import { getIntFkId } from "common/functions/system-int";
import { merge2, merge3 } from "common/merge";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { FileType } from "common/types/media";
import { ActionButtonLarge, DeleteButtonLarge } from "common/ui/buttons";
import { VerticalField } from "common/ui/field";
import { MapValue, Pin } from "common/vendor-wrappers/leaflet/types";
import { EntitySelector } from "common/widgets/selector/entity-selector";
import { MediaUploadController } from "common/widgets/file-selector/media-file-upload";
import { StringInput } from "common/widgets/input-with-submit/string";
import { RecordPopOut } from "common/widgets/record-pop-out";
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { ValueProps } from "common/with-value-for";
import { visualTemplatesUIApi } from "common/api/visual-templates";
import { DEFAULT_PIN_COLOR } from "common/vendor-wrappers/leaflet/wrapper";
import { ColorLegendButton } from "common/widgets/color-legend-button";
import { Page } from "common/widgets/simple-pagination";
import { InputWidget } from "common/form/widget/input-widget";
import { VisualTemplate } from "x/account-settings/visual-templates/types";
import { LabelsFor, LabelsTable } from "x/culture/labels";
import { ColorLegendGroup } from "x/records/list/functions";
import { Ribbon, RibbonButtons } from "x/layout/ribbon";
import { Crumb } from "x/layout/ribbon/breadcrumb";
import { getRecordQueryWithFilter } from "x/interactive-maps/query";
import {
  defaultProperties,
  defaultRecord,
  defaultValue,
  getNewPin,
  InteractiveMapProperties,
  InteractiveMapValue,
} from "x/interactive-maps/types";
import {
  generateColorLegendGroups,
  applyLabelTranslation,
  getTranslationKeys,
  resetDescriptionTranslation,
  translateKey,
} from "x/interactive-maps/functions";

interface Props extends ValueProps<InteractiveMapValue> {
  context: Context;
  entity: Entity;
  hidePanels: boolean;
  colorTemplates: VisualTemplate[];
}

interface StateType {
  labelsPage: Page;
}

const getGeneralColorLegendGroup = (): ColorLegendGroup => ({
  label: _("General"),
  items: [{ label: _("Default Color"), color: { r: 38, g: 127, b: 202 } }],
});

export class InteractiveMapForm extends Component<Props> {
  static readonly displayName = "InteractiveMapForm";

  state: StateType = {
    labelsPage: 0,
  };

  previewPins = (pins: Pin[], templateId: number): Promise<Pin[]> => {
    const { context, entity } = this.props;
    const { apiCall } = context;

    return visualTemplatesUIApi(apiCall)
      .previewPins(pins, templateId, entity.name)
      .then(({ pins }) => pins);
  };

  onUpload = (file: FileType) => {
    const { value, onChange } = this.props;
    const newValue = merge3("record", "properties", "image", file.url, value);
    onChange(newValue);
  };

  onDelete = () => {
    const { value, onChange } = this.props;
    const {
      selectedPin,
      record: { properties },
    } = value;

    const updatedPins = (properties.pins || []).filter(
      (pin) => pin.layer !== selectedPin.layer || pin.id !== selectedPin.id,
    );

    onChange({
      ...value,
      record: merge2("properties", "pins", updatedPins, value.record),
      selectedPin: undefined,
    });
  };

  onChangeMap = (mapValue: MapValue) => {
    const { value, onChange } = this.props;
    const { pins = [], selected, imgSize } = mapValue;

    onChange({
      ...value,
      record: merge2("properties", "pins", pins, value.record),
      selectedPin: selected,
      imgSize,
    });
  };

  onChangeEntity = (entity: Entity) => {
    const { value, onChange } = this.props;
    onChange({ ...value, pinEntity: entity.name, pinRecord: undefined });
  };

  onChangeRecord = (pinRecord: ForeignKey) => {
    const { value, onChange } = this.props;
    onChange({ ...value, pinRecord });
  };

  onDescriptionChange = (name: string) => {
    const { value, onChange, context } = this.props;
    const { culture } = context.uiFormat;
    const newValue = merge3("record", "properties", "description", name, value);
    onChange(resetDescriptionTranslation(culture, newValue));
  };

  onAdd = () => {
    const { value, onChange, context } = this.props;
    const { record } = value;
    const { properties } = record;

    const width = value?.imgSize?.width;
    const height = value?.imgSize?.height;

    const updatedPins = (properties.pins || []).concat([
      getNewPin(value, width, height),
    ]);

    const templateId = getIntFkId(properties.templateId);

    if (context.isEnabledFeature("visualTemplates") && templateId) {
      this.previewPins(updatedPins, templateId).then((pins) =>
        onChange({
          ...value,
          record: merge2("properties", "pins", pins, record),
          pinRecord: undefined,
        }),
      );
    } else {
      onChange({
        ...value,
        record: merge2("properties", "pins", updatedPins, record),
        pinRecord: undefined,
      });
    }
  };

  getRecordQuery = (entityName: string) => {
    const { context, value } = this.props;
    const { entities } = context;

    const entity = entities[entityName];
    const pins = value.record.properties.pins;
    const pinsToOmit = pins && pins.filter((p) => p.layer === entityName);

    return getRecordQueryWithFilter(entity, pinsToOmit);
  };

  onChangeTemplate = (templateId: number) => {
    const { onChange, value } = this.props;
    const { record } = value;
    const { properties } = record;

    if (templateId) {
      this.previewPins(properties.pins, templateId).then((pins) => {
        const newProperties = {
          ...properties,
          templateId: templateId ?? null,
          pins: pins,
        };
        onChange(merge2("record", "properties", newProperties, value));
      });
    } else {
      const newProperties = {
        ...properties,
        templateId: templateId ?? null,
        pins: properties.pins.map((pin) => ({
          ...pin,
          color: DEFAULT_PIN_COLOR,
        })),
      };

      onChange(merge2("record", "properties", newProperties, value));
    }
  };

  createColorLegendGroups = (): ColorLegendGroup[] => {
    const { value, colorTemplates, context } = this.props;
    const { record } = value;
    const { properties } = record;
    const { uiFormat, site } = context;

    const groups = [getGeneralColorLegendGroup()];

    if (context.isEnabledFeature("visualTemplates")) {
      const templateId = getIntFkId(properties.templateId);
      const selectedTemplate = colorTemplates.find(
        (template) => template.id === templateId,
      );

      if (selectedTemplate) {
        return [
          ...groups,
          ...generateColorLegendGroups(
            context.entities,
            selectedTemplate,
            uiFormat.culture,
            site.culture,
          ),
        ];
      }
    }

    return groups;
  };

  onLabelsChange = (valueWithLabels: LabelsFor<InteractiveMapProperties>) => {
    const { onChange, value } = this.props;

    const newValue = merge3(
      "record",
      "properties",
      "labels",
      valueWithLabels.item.labels,
      value,
    );

    onChange(newValue);

    this.setState({ labelsPage: valueWithLabels.page });
  };

  render() {
    const { context, entity, hidePanels, value = defaultValue } = this.props;
    const { labelsPage } = this.state;
    const {
      record = defaultRecord,
      selectedPin,
      pinEntity,
      pinRecord,
      imgSize,
    } = value;
    const { properties = defaultProperties } = record;
    const { id, image } = properties;
    const { entities, site, uiFormat } = context;

    const isFeatureEnabled = context.isEnabledFeature("visualTemplates");

    const map = image ? (
      <EntityMap
        context={context}
        image={image}
        isReadOnly={false}
        value={{
          pins: properties.pins || [],
          selected: selectedPin,
          imgSize,
        }}
        onChange={this.onChangeMap}
      />
    ) : undefined;

    const crumbs: Crumb[] = [
      {
        name: getLocalizedName(entity),
        url: getUrl(entity, site?.name),
      },
      {
        name: id
          ? applyLabelTranslation(properties, uiFormat.culture)?.description
          : _("New"),
      },
    ];

    const translatedLabel = applyLabelTranslation(
      properties,
      context.uiFormat.culture,
    );

    return (
      <div className="x-map-container">
        <Ribbon onRefresh={undefined} crumbs={crumbs}>
          <RibbonButtons>
            {!hidePanels && (
              <RecordPopOut
                id={id || ""}
                entity={entity}
                site={context.site.name}
              />
            )}
          </RibbonButtons>
        </Ribbon>
        <div className="x-map-form x-padding-15">
          <VerticalField
            className="qa-map-name"
            label={_("Name")}
            input={
              <div className="x-map-input-row">
                <StringInput
                  className="x-margin-bottom-10 x-margin-right-10"
                  placeholder={_("Description")}
                  value={translatedLabel.description || ""}
                  onChange={this.onDescriptionChange}
                />
                {id && (
                  <MediaUploadController
                    context={context}
                    entityName={entity?.name}
                    recordId={id}
                    onUpload={this.onUpload}
                    acceptedFileTypes={"image"}
                  />
                )}
              </div>
            }
          />
          {map ? (
            <>
              <div className="x-add-pin">
                <VerticalField
                  className="qa-map-entity"
                  label={_("Select entity")}
                  input={
                    <EntitySelector
                      placeholder={_("New Pin")}
                      entities={entities}
                      filter={(entity) =>
                        entity.behaviors.some((b) =>
                          isInteractiveMapBehaviorName(b.name),
                        )
                      }
                      required={true}
                      value={pinEntity ? entities[pinEntity] : undefined}
                      onChange={this.onChangeEntity}
                    />
                  }
                />
                {pinEntity ? (
                  <VerticalField
                    className="qa-map-record"
                    label={_("Select a record")}
                    input={
                      <AdvancedSingleRecordSelector
                        key={pinEntity}
                        withLinks={true}
                        allowClear={true}
                        context={context}
                        entity={entities[pinEntity]}
                        query={this.getRecordQuery(pinEntity)}
                        value={pinRecord}
                        onChange={this.onChangeRecord}
                      />
                    }
                  />
                ) : undefined}
              </div>
              {isFeatureEnabled ? (
                <div className="x-color-template">
                  <VerticalField
                    label={_("Select color template")}
                    input={
                      <InputWidget
                        allowClear={true}
                        column={getColumn(entity, "templateId")}
                        context={context}
                        buffer={false}
                        validate={false}
                        disabled={false}
                        formValidation={undefined}
                        onFormValidationChange={undefined}
                        value={properties.templateId}
                        onChange={this.onChangeTemplate}
                      />
                    }
                  />
                </div>
              ) : undefined}
              <div className="x-pin-buttons">
                <div>
                  <ColorLegendButton
                    colorLegendGroups={this.createColorLegendGroups()}
                  />
                </div>
                <div>
                  <DeleteButtonLarge
                    onClick={this.onDelete}
                    disabled={!selectedPin}
                  >
                    {_("Remove Pin")}
                  </DeleteButtonLarge>
                  <ActionButtonLarge
                    onClick={this.onAdd}
                    disabled={!pinEntity || !pinRecord?.id}
                  >
                    {_("Add Pin")}
                  </ActionButtonLarge>
                </div>
              </div>
            </>
          ) : undefined}
          {map}
          <LabelsTable<
            InteractiveMapProperties,
            LabelsFor<InteractiveMapProperties>
          >
            allCultures={false}
            context={context}
            keys={getTranslationKeys(properties)}
            value={{ page: labelsPage, item: properties }}
            translateKey={translateKey(properties)}
            onChange={this.onLabelsChange}
          />
        </div>
      </div>
    );
  }
}
