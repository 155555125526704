import { omitBlacklistedColumns } from "common/record/edit/functions";
import { defaultFor } from "common";
import { FormSelector } from "common/form/form-selector";
import { filterFormsByEntity } from "common/functions/forms";
import { Action } from "common/record/actions/action";
import { redirect } from "common/record/actions/functions/url";
import {
  ActionPropTypes as ActionProps,
  DismissAction,
  PropTypes,
} from "common/record/actions/types";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { withValue } from "common/with-value";

export interface CreateValue {
  formId: number;
}

export const Create = withValue<CreateValue, PropTypes>(
  ({
    context,
    dismiss,
    entity,
    sourceEntity,
    records = [],
    extraProperties = defaultFor<Properties>(),
    actionProps = defaultFor<ActionProps>(),
    onChangeMergeValue,
    onPerform,
    allowFullscreen,
    goTo,
    value = defaultFor<CreateValue>(),
  }) => {
    const { name } = entity;
    const entityForms = filterFormsByEntity(context.forms, name);

    const onOk = (_: ApiCall, dismiss: DismissAction) => {
      onPerform?.();
      redirect(
        context,
        omitBlacklistedColumns(sourceEntity, entity, records[0]?.properties),
        value.formId,
        entity,
        extraProperties,
        goTo,
      );
      return CancellablePromise.resolve(dismiss);
    };

    return (
      <Action
        requiresAuthentication={false}
        allowFullscreen={allowFullscreen}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Create")}
        btnLabel={_("Create")}
        onOk={onOk}
        {...actionProps}
      >
        {entityForms.length > 1 ? (
          <VerticalField
            label={_("Form")}
            input={
              <FormSelector
                forms={entityForms}
                preselectFirstByDefault={true}
                onChange={onChangeMergeValue("formId")}
                value={value.formId}
              />
            }
          />
        ) : undefined}
      </Action>
    );
  },
  "Create",
);
