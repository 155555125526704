import { Component } from "react";
import { recordsApi } from "common/api/records";
import { Entity } from "common/entities/types";
import { RequestOptions } from "common/types/api";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { LoadingButton } from "common/widgets/loading-button";
import { Modal } from "common/widgets/modal";
import { VerticalField } from "common/ui/field";
import { FormSelector } from "common/form/form-selector";
import { Required } from "common/widgets/required";
import { Form } from "common/types/forms";
import { filterFormsByEntity } from "common/functions/forms";
import { CreatePoPayload } from "common/record/actions/ui/requisitioning/types";
import { getEntityByBehavior } from "common/entities";
import { LoadingIcon } from "common/widgets/loading-icon";

interface PropTypes {
  context: Context;
  entity: Entity;
  record: Record;
  isValid: boolean;
  save: (
    record: Record,
    confirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => CancellablePromise<any>;
  onError: (error: ApiErrorResponse) => void;
  onActionExecuting: (isExecuting: boolean) => void;
  onCreated: (recordId: string) => void;
}

interface StateType {
  loading: boolean;
  isModalOpened: boolean;
  formId: number;
  purchaseOrderForms: Form[];
}

export class CreatePurchaseOrderButton extends Component<PropTypes, StateType> {
  static readonly displayName = "CreatePOButton";
  state: StateType = {
    loading: false,
    isModalOpened: false,
    formId: undefined,
    purchaseOrderForms: [],
  };

  componentDidMount() {
    const { context } = this.props;
    const purchaseOrderEntity = getEntityByBehavior(
      "PurchaseOrder",
      context.entities,
    );
    const purchaseOrderForms = filterFormsByEntity(
      context.forms,
      purchaseOrderEntity.name,
    );
    const formId =
      purchaseOrderForms?.length === 1 ? purchaseOrderForms[0].id : undefined;
    this.setState({ purchaseOrderForms, formId });
  }

  onCreatePurchaseOrder = () => {
    const {
      context,
      entity,
      record,
      save,
      onCreated,
      onError,
      onActionExecuting,
    } = this.props;
    const { apiCall } = context;
    const { formId } = this.state;
    const createPoPayload: CreatePoPayload = {
      formId,
      items: [],
    };

    this.setState({ loading: true });
    onActionExecuting(true);

    save(record, false, {
      hideNotifications: true,
      preventReloadAfterSave: true,
    })
      .then((recordId) =>
        recordsApi(apiCall)
          .createPurchaseOrder(entity.name, recordId, createPoPayload)
          .then(() => {
            onActionExecuting(false);
            onCreated(recordId);
          }),
      )
      .catch((error) => {
        this.setState({ loading: false, isModalOpened: false });
        onActionExecuting(false);
        onError(error);
      });
  };

  onOpenModal = () => {
    this.setState({ isModalOpened: true });
  };

  onCloseModal = () => {
    this.setState({ isModalOpened: false });
  };

  onFormSelectorChange = (formId: number) => this.setState({ formId });

  onClick = () => {
    const { purchaseOrderForms } = this.state;
    purchaseOrderForms.length >= 2
      ? this.onOpenModal()
      : this.onCreatePurchaseOrder();
  };

  getContent = () => {
    const { purchaseOrderForms, formId, loading } = this.state;

    return (
      <>
        <div className="x-form-selector-container">
          <VerticalField
            className="x-form-selector qa-form-selector-label"
            label={_("Purchase Order Form")}
            input={
              <Required value={formId}>
                <FormSelector
                  className="qa-form-selector"
                  forms={purchaseOrderForms}
                  value={formId}
                  onChange={this.onFormSelectorChange}
                />
              </Required>
            }
          />
        </div>
        {loading && <LoadingIcon />}
      </>
    );
  };

  render() {
    const { loading, isModalOpened, formId, purchaseOrderForms } = this.state;
    const { isValid } = this.props;
    const isFormValid = purchaseOrderForms?.length === 0 || formId;
    const isEnabled = !loading && isFormValid;

    return (
      <>
        <LoadingButton
          className="qa-create-purchase-order"
          loadingText={_("Creating Purchase Order")}
          idleText={_("Create Purchase Order")}
          disabled={!isValid}
          loading={loading}
          onClick={this.onClick}
          type="modify"
          size="large"
        />
        {isModalOpened ? (
          <Modal
            content={this.getContent()}
            className="x-create-purchase-order-modal"
            size="large"
            title={_("Create Purchase Order")}
            okLabel={_("Create")}
            allowFullscreen={false}
            onOk={isEnabled ? this.onCreatePurchaseOrder : undefined}
            onCancel={this.onCloseModal}
          />
        ) : undefined}
      </>
    );
  }
}
