import * as React from "react";
import { Required } from "./required";

interface PropTypes {
  value: any;
  isRequired: boolean;
}

export const ConditionalRequired = ({
  isRequired,
  value,
  children,
}: React.PropsWithChildren<PropTypes>) =>
  isRequired ? <Required value={value}>{children}</Required> : <>{children}</>;
