import { Component } from "react";
import { printableLabelsListApi } from "common/api/printable-labels";
import { recordsApi } from "common/api/records";
import { showInfoNotification } from "common/ui/notification";
import { Culture } from "common/culture/supported-cultures";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { ApiCall } from "common/types/api";
import { PrintableLabelOption } from "common/types/printable-label";
import { VerticalField } from "common/ui/field";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";
import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { genericCatch } from "../function-handlers";

export interface PrintableLabelValue {
  printableLabel: PrintableLabelOption;
}

interface StateType {
  options: PrintableLabelOption[];
  error: ApiErrorResponse;
  isLoading: boolean;
}

type Props = PropTypes & ValueProps<PrintableLabelValue>;

export const translateLabelName =
  (culture: Culture) => (label: PrintableLabelOption) =>
    label.labels?.[culture]?.name ?? label.name;

export class PrintableLabelModal extends Component<Props, StateType> {
  static readonly displayName = "PrintableLabelModal";
  state: StateType = { options: [], error: undefined, isLoading: false };

  componentDidMount() {
    this.fetchLabels();
  }

  fetchLabels = () => {
    const { context, entity } = this.props;

    this.setState({ isLoading: true });
    printableLabelsListApi(context.apiCall)
      .list(entity.name)
      .then((options: PrintableLabelOption[]) => {
        this.setState({ options, isLoading: false });
        this.onChangeLabel(options[0]);
      })
      .catch((error: ApiErrorResponse) =>
        this.setState({ error, isLoading: false }),
      );
  };

  onChangeLabel = (selectedLabel: PrintableLabelOption) => {
    const { onChange, value } = this.props;
    const { options } = this.state;

    onChange({
      ...value,
      printableLabel: options.find((option) => selectedLabel.id === option.id),
    });
  };

  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { entity, records, value } = this.props;
    const { id, name } = value?.printableLabel ?? {};

    dismiss(false);
    showInfoNotification(_("Your download will start shortly"));

    return records.length > 1
      ? recordsApi(apiCall)
          .exportPrintableLabels(
            entity,
            records.length,
            records.map((r) => r.properties.id),
            id,
            name,
          )
          .catch(genericCatch)
      : recordsApi(apiCall)
          .exportPrintableLabel(
            entity,
            records[0].properties.number,
            records[0].properties.id,
            id,
            name,
          )
          .catch(genericCatch);
  };

  render() {
    const { records, context, dismiss, entity, value } = this.props;
    const { options, error, isLoading } = this.state;
    const title =
      records.length > 1 ? _("Export labels for") : _("Export label for");
    const isValid = !!value?.printableLabel;

    return (
      <Action
        requiresAuthentication={false}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={title}
        btnLabel={_("Export")}
        onOk={isValid ? this.onOk : undefined}
      >
        {isLoading ? <LoadingIcon /> : undefined}
        {error ? <ApiError error={error} /> : undefined}

        <VerticalField
          label={_("Printable Labels")}
          input={
            <Selector<PrintableLabelOption>
              getOptionLabel={translateLabelName(context.uiFormat.culture)}
              placeholder={`${_("Select a label to export")}...`}
              options={options}
              value={value?.printableLabel}
              onChange={this.onChangeLabel}
            />
          }
        />
      </Action>
    );
  }
}
