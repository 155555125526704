import {
  isBulkDelete,
  isBulkRestore,
  isBulkUpdate,
  isCalendar,
  isCsvCreate,
  isCsvUpdate,
  isDocumentAdd,
  isDocumentRemove,
  isFailedProcedureOnWorkOrderClose,
  isFrequency,
  isFromFluke,
  isLAPComponentReassigned,
  isManual,
  isMeter,
  isMeterReadingException,
  isPM,
  isRejected,
  isRimeRanking,
  isRunning,
  isShadow,
  isTotals,
  isTotalsEstimation,
  isUrgentRepair,
} from "common/functions/reasons";
import {
  CalendarReason,
  DocumentAddReason,
  DocumentRemoveReason,
  ManualReason,
  ManualRescheduleReason,
  PMUpdateReason,
  Reason,
  RejectReason,
  RescheduleReason,
  RunningReason,
  ShadowReason,
  TotalsEstimationReason,
  TotalsReason,
} from "common/types/reasons";
import { showOffsetDescription } from "common/culture";
import { formatLongDate } from "common/date-time/formatters";
import { cronToString } from "common/utils/cron";

interface ReasonInfo {
  text: string;
  exception: string;
  calendar: string;
}

const getManualText = ({ userName }: ManualReason) =>
  _("The PM was manually triggered by {USERNAME}").replace(
    "{USERNAME}",
    userName,
  );

const getCalendarText = ({ cron, offset, userName }: CalendarReason) => {
  const reason = _("Work Order created by static calendar {CRONLABEL}").replace(
    "{CRONLABEL}",
    cronToString(cron),
  );
  if (!offset) return reason;

  const days = Math.floor(offset / 24);
  const hours = offset % 24;
  const offsetDescription = showOffsetDescription(days, hours);

  const by = userName
    ? " " + _("set by {USERNAME}").replace("{USERNAME}", userName)
    : "";

  return _("{REASON} with an offset of {OFFSETDESCRIPTION}{BY}")
    .replace("{REASON}", reason)
    .replace("{OFFSETDESCRIPTION}", offsetDescription)
    .replace("{BY}", by);
};

const getTotalsText = ({ paramName, quantity, frequency }: TotalsReason) =>
  _(
    "A reading for {PARAMNAME} with value {QUANTITY} exceeded frequency threshold {FREQUENCY}",
  )
    .replace("{PARAMNAME}", paramName)
    .replace("{QUANTITY}", `${quantity}`)
    .replace("{FREQUENCY}", `${frequency}`);

const getTotalsEstimationText = ({
  paramName,
  frequency,
  averagePerDay,
}: TotalsEstimationReason) =>
  _(
    "Estimated Work Order generated for {PARAMNAME} based on an average reading of {AVERAGERPERDAY}/day and a frequency threshold of {FREQUENCY}",
  )
    .replace("{PARAMNAME}", paramName)
    .replace("{AVERAGERPERDAY}", `${averagePerDay}`)
    .replace("{FREQUENCY}", `${frequency}`);

const getRunningText = ({ paramName, quantity, max, min }: RunningReason) =>
  _(
    "A reading for {PARAMNAME} with value {QUANTITY} is outside the acceptable interval [{MIN}, {MAX}]",
  )
    .replace("{PARAMNAME}", paramName)
    .replace("{QUANTITY}", `${quantity}`)
    .replace("{MIN}", `${min}`)
    .replace("{MAX}", `${max}`);

const getShadowText = ({ start, every }: ShadowReason) =>
  _(
    "Estimated Work Order for frequency trigger every {NUMBER} days starting on {SHOWDATE}",
  )
    .replace("{NUMBER}", `${every}`)
    .replace("{SHOWDATE}", formatLongDate(start));

const getFlukeText = () => _("Generated by Fluke CM");
const getCsvCreateText = () => _("Record created from Csv");
const getCsvUpdateText = () => _("Record updated from Csv");
const getMeterUpdateText = () => _("Meter Triggered");
const getFrequencyUpdateText = () => _("Frequency Triggered");
const getBulkUpdateText = () => _("Mass Updated");
const getBulkDeleteText = () => _("Mass Archived");
const getBulkRestoreText = () => _("Mass Restored");
const getMeterReadingExceptionText = () => _("Meter Reading Exception");
const getFailedProcedureOnWorkOrderCloseText = () =>
  _("Failed Procedure on Work Order close");
const getUrgentRepairText = () => _("Urgent Repair");
const getRimeRankingText = () => _("Rime Ranking Updated");
const getLAPComponentReassignedText = () =>
  _("LIVE-Asset component reassigned");

const getPMText = (reason: PMUpdateReason) => {
  switch (reason.updateReason) {
    case "ProjectionOpened":
      return _("Work Order Opened");
    case "WorkOrderClosed":
      return _("Work Order Closed");
    case "WorkOrderArchived":
      return _("Work Order Archived");
    case "MeterReadingCreated":
      return _("Meter reading created above the threshold");
    default:
      return _("Unknown reason");
  }
};

const getRejectText = (reason: RejectReason) => reason.rejectionReason;
const getDocAddedText = (reason: DocumentAddReason) => reason.fileName;
const getDocRemovedText = (reason: DocumentRemoveReason) => reason.fileName;
const getDocVersion = (reason: DocumentAddReason | DocumentRemoveReason) =>
  reason.versionId;

export const getText = (reason: Reason) => {
  if (isPM(reason)) return getPMText(reason);
  if (isMeterReadingException(reason)) return getMeterReadingExceptionText();
  if (isRejected(reason)) return getRejectText(reason);
  if (isManual(reason)) return getManualText(reason);
  if (isCalendar(reason)) return getCalendarText(reason);
  if (isTotals(reason)) return getTotalsText(reason);
  if (isTotalsEstimation(reason)) return getTotalsEstimationText(reason);
  if (isRunning(reason)) return getRunningText(reason);
  if (isShadow(reason)) return getShadowText(reason);
  if (isFromFluke(reason)) return getFlukeText();
  if (isCsvCreate(reason)) return getCsvCreateText();
  if (isCsvUpdate(reason)) return getCsvUpdateText();
  if (isMeter(reason)) return getMeterUpdateText();
  if (isFrequency(reason)) return getFrequencyUpdateText();
  if (isBulkUpdate(reason)) return getBulkUpdateText();
  if (isBulkDelete(reason)) return getBulkDeleteText();
  if (isBulkRestore(reason)) return getBulkRestoreText();
  if (isDocumentAdd(reason)) return getDocAddedText(reason);
  if (isDocumentRemove(reason)) return getDocRemovedText(reason);
  if (isUrgentRepair(reason)) return getUrgentRepairText();
  if (isFailedProcedureOnWorkOrderClose(reason))
    return getFailedProcedureOnWorkOrderCloseText();
  if (isRimeRanking(reason)) return getRimeRankingText();
  if (isLAPComponentReassigned(reason)) return getLAPComponentReassignedText();
  return undefined;
};

export const getReason = (reason: Reason): ReasonInfo => {
  const text = getText(reason);
  const {
    userName,
    exceptionShiftFrom,
    exceptionShiftTo,
    calendarShiftFrom,
    calendarShiftTo,
  } = reason as ManualRescheduleReason & RescheduleReason;

  const reasonUserName = userName
    ? " " + _("by {USERNAME}").replace("{USERNAME}", userName)
    : "";
  const exception =
    exceptionShiftFrom && exceptionShiftTo
      ? _(
          "Manually rescheduled from {SHOWDATESHIFTFROM} to {SHOWDATESHIFTTO}{REASONUSERNAME}",
        )
          .replace("{SHOWDATESHIFTFROM}", formatLongDate(exceptionShiftFrom))
          .replace("{SHOWDATESHIFTTO}", formatLongDate(exceptionShiftTo))
          .replace("{REASONUSERNAME}", reasonUserName)
      : undefined;

  const calendar =
    calendarShiftFrom && calendarShiftTo
      ? _(
          "Automatically rescheduled from {SHOWDATESHIFTFROM} to {SHOWDATESHIFTTO} because the original date was a non-working date",
        )
          .replace("{SHOWDATESHIFTFROM}", formatLongDate(calendarShiftFrom))
          .replace("{SHOWDATESHIFTTO}", formatLongDate(calendarShiftTo))
      : undefined;

  return { text, exception, calendar };
};

export const getRescheduleText = (reason: Reason): string => {
  const { exception, calendar } = getReason(reason);
  return exception || calendar;
};

export const getLabel = (reason: Reason) => {
  if (isManual(reason)) return _("Manual reschedule");
  if (isCalendar(reason)) return _("Calendar");
  if (isTotals(reason)) return reason.paramName || _("Meter reading");
  if (isTotalsEstimation(reason))
    return reason.paramName || _("Meter reading estimation");
  if (isRunning(reason)) return _("Running meter reading");
  if (isShadow(reason)) return _("Frequency");
  return undefined;
};

export const getDocumentVersion = (reason: Reason) =>
  isDocumentAdd(reason) || isDocumentRemove(reason)
    ? getDocVersion(reason)
    : undefined;
