import { Node } from "./types";

export const flattenNodesFrom = (node: Node): Node[] => {
  const { children } = node;

  if (!children || !children.length) return [node];

  const childrenIds = children.reduce(
    (acc, child) => [...acc, ...flattenNodesFrom(child)],
    [],
  );

  return [node, ...childrenIds];
};
